import actionTypes from "../actionTypes";

export const requestAPI = () => ({
  type: actionTypes.API_REQ_STARTED,
});
export const getGeneralQueriesAction = (data) => ({
  type: actionTypes.connectTypes.GET_GENERAL_QUERIES,
  payload: data,
});
export const getForumTopicsDone = data => ({
  type: actionTypes.connectTypes.CONNECT_FORUM_TOPICS,
  payload: data,
});
export const putForumFollowUnfollowDone = data => ({
  type:  actionTypes.connectTypes.CONNECT_FORUM_TOPICSFOLLOWUNFOLLOW,
  payload: data,
});
export const getForumTopicsDetailsDone = data => ({
  type:  actionTypes.connectTypes.CONNECT_FORUM_TOPICSDETAILS,
  payload: data,
});
export const postForumThreadDone = data => ({
  type:  actionTypes.connectTypes.CONNECT_FORUM_ADDTHREADS,
  payload: data,
});
export const getForumTopicCommentsDone = data => ({
  type: actionTypes.connectTypes.CONNECT_FORUM_ADDTHREADSCOMMENTS,
  payload: data,
});
export const deleteThreadDone = data => ({
  type:  actionTypes.connectTypes.CONNECT_FORUM_DELETETHREAD,
  payload: data,
});
export const getPollsDone = data => ({
  type:  actionTypes.connectTypes.CONNECT_POLL_GETPOLLS,
  payload: data,
});
export const getResponseDone = data => ({
  type:  actionTypes.connectTypes.CONNECT_POLL_GETRESPONSE,
  payload: data,
});
export const postUserPollResoponseDone = data => ({
  type:  actionTypes.connectTypes.CONNECT_POLL_POSTUSERPOLLRESPONSE,
  payload: data,
});
export const isPollAvailableDone = data => ({
  type:  actionTypes.connectTypes.CONNECT_POLL_ISPOLLAVAILABLE,
  payload: data,
});
export const getPollHistoryDone = data => ({
  type:  actionTypes.connectTypes.CONNECT_POLL_GETPOLLHISTORY,
  payload: data,
});
export const catchError = data => ({
  type: actionTypes.errorTypes.API_ERROR,
  payload: data,
});
