import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import "./Modal.scss";

const Modal = (props) => {
  let element = document.createElement("div");
  const modalRoot = document.getElementById("modal-container");
  useEffect(() => {
    modalRoot.appendChild(element);
    return () => {
      modalRoot.removeChild(element);
    };
  }, []);
  return createPortal(
    <div
      className={`modal_wrapper ${props.className}`}
      onClick={props.closeModal}
    >
      <div
        className="modal_dialog"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal_content">{props.children}</div>
      </div>
    </div>,
    element
  );
};
export default Modal;
