import React from "react";

const HomeSidebarContribute = ({ active }) => {
  return (
    <svg
      id="pts_ic"
      xmlns="http://www.w3.org/2000/svg"
      width="17.2"
      height="21.556"
      viewBox="0 0 17.2 21.556"
    >
      <path
        id="Path_292"
        data-name="Path 292"
        d="M17.8,50.462,15.5,47.83a.7.7,0,0,0-.408-.2.6.6,0,0,0-.567.862l.068.136a11.71,11.71,0,0,0,1.225,1.951l.386.522a.344.344,0,0,1,.068.295.327.327,0,0,1-.159.25.344.344,0,0,1-.295.068.327.327,0,0,1-.25-.159l-.408-.522a14.983,14.983,0,0,1-1.293-2.065l-.068-.136a1.352,1.352,0,0,1,.318-1.656c.045-.023.068-.068.113-.091l-.794-2.065a.7.7,0,0,0-.59-.431.623.623,0,0,0-.522.182.739.739,0,0,0-.227.522l.091,5.423a1.944,1.944,0,0,0,.567,1.361L14.8,54.161h3.472L18.2,51.506A1.627,1.627,0,0,0,17.8,50.462Z"
        transform="translate(-12.1 -35.033)"
        fill={active ? "#fff" : "#404040"}
      />
      <path
        id="Path_293"
        data-name="Path 293"
        d="M24.2,91.361a.349.349,0,0,0,.34.34h2.768a.349.349,0,0,0,.34-.34V90H24.2Z"
        transform="translate(-21.454 -70.146)"
        fill={active ? "#fff" : "#404040"}
      />
      <path
        id="Path_294"
        data-name="Path 294"
        d="M66.668,44.767a.648.648,0,0,0-.522-.182.737.737,0,0,0-.59.431l-.84,2.11a.4.4,0,0,1,.113.091,1.382,1.382,0,0,1,.318,1.656l-.091.113a13.088,13.088,0,0,1-1.293,2.065l-.408.522a.431.431,0,0,1-.25.159.441.441,0,0,1-.295-.068.431.431,0,0,1-.159-.25.344.344,0,0,1,.068-.295l.408-.522a15.674,15.674,0,0,0,1.225-1.951l.068-.136a.588.588,0,0,0-.136-.7.6.6,0,0,0-.431-.159.7.7,0,0,0-.408.2l-2.269,2.609a1.627,1.627,0,0,0-.408,1.044L60.7,54.138h3.472l2.042-2.088a1.9,1.9,0,0,0,.567-1.361l.091-5.423A.7.7,0,0,0,66.668,44.767Z"
        transform="translate(-49.672 -35.033)"
        fill={active ? "#fff" : "#404040"}
      />
      <path
        id="Path_295"
        data-name="Path 295"
        d="M60.7,91.361a.349.349,0,0,0,.34.34h2.768a.349.349,0,0,0,.34-.34V90H60.7Z"
        transform="translate(-49.672 -70.146)"
        fill={active ? "#fff" : "#404040"}
      />
      <path
        id="Path_296"
        data-name="Path 296"
        d="M38.257,26.479a4.879,4.879,0,1,0-4.879,4.879A4.871,4.871,0,0,0,38.257,26.479ZM36.033,26l-1,.908a.205.205,0,0,0-.068.227l.272,1.339a.384.384,0,0,1-.567.408l-1.18-.681a.206.206,0,0,0-.227,0l-1.18.681a.384.384,0,0,1-.567-.408l.272-1.339a.183.183,0,0,0-.068-.2l-1-.908a.377.377,0,0,1,.2-.658l1.361-.159a.2.2,0,0,0,.182-.136l.567-1.248a.39.39,0,0,1,.7,0l.567,1.248c.023.068.113.113.182.136l1.361.159A.359.359,0,0,1,36.033,26Z"
        transform="translate(-24.779 -17.266)"
        fill={active ? "#fff" : "#404040"}
      />
      <path
        id="Path_297"
        data-name="Path 297"
        d="M47.681,5.518a.683.683,0,0,0,.681-.681V3.181a.683.683,0,0,0-.681-.681A.7.7,0,0,0,47,3.181V4.814A.688.688,0,0,0,47.681,5.518Z"
        transform="translate(-39.081 -2.5)"
        fill={active ? "#fff" : "#404040"}
      />
      <path
        id="Path_298"
        data-name="Path 298"
        d="M61.554,9.68a.841.841,0,0,0,.318.091.715.715,0,0,0,.613-.363l.771-1.452a.69.69,0,1,0-1.225-.635l-.771,1.452A.684.684,0,0,0,61.554,9.68Z"
        transform="translate(-50.05 -5.936)"
        fill={active ? "#fff" : "#404040"}
      />
      <path
        id="Path_299"
        data-name="Path 299"
        d="M30.153,9.342a.688.688,0,0,0,.613.363.521.521,0,0,0,.318-.091.7.7,0,0,0,.295-.93l-.794-1.43a.69.69,0,0,0-1.225.635Z"
        transform="translate(-25.388 -5.893)"
        fill={active ? "#fff" : "#404040"}
      />
    </svg>
  );
};

export default HomeSidebarContribute;
