import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../CommonComponents/Button/Button";
import champ from "../../../assets/images/champ.png";
import classes from "./LeaderboardModalCarousel.module.scss";
import { useNavigate } from "react-router";
import useWindowWidthLessThan from "../../../hooks/useWindowWidthLessThan";
import { FormattedMessage } from "react-intl";
const LeaderboardModalCarousel = () => {
  const homeState = useSelector((state) => state.homeReducer);
  const { leaderboardData } = homeState;
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (leaderboardData?.length > 0) {
  //     setCurrentPage(1);
  //   }
  // }, [leaderboardData]);
  const handleNext = () => {
    if (currentPage === lastPage) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevious = () => {
    if (currentPage === 1) {
      setCurrentPage(lastPage);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };
  const isWidthLessThan = useWindowWidthLessThan(600);
  const lastPage = isWidthLessThan
    ? leaderboardData?.length
    : Math.ceil(leaderboardData?.length / 2);

  return (
    <div>
      <div className={classes.LeaderboardModal}>
        {isWidthLessThan
          ? leaderboardData
              // ?.slice(currentPage - 1, currentPage)
              .map((data, index) => {
                return (
                  <div
                    key={data?.Id}
                    className={
                      index % 2 === 0
                        ? classes.champion
                        : classes.champion + " " + classes.champion1
                    }
                  >
                    <img src={champ} alt="badge" className="champion" />
                    <div className={classes.title}>{data?.GameName}</div>
                    <div className={classes.flex2}>
                      <div className={classes.items}>
                        <div className={classes.row}>{data?.Rank}</div>
                        <div>
                          <FormattedMessage id="credits.rank" />
                        </div>
                      </div>
                      <div className={classes.items}>
                        <div className={classes.row}>
                          {data?.UserLeaderBoardScores?.find(
                            (val) => val.ScoreType === "Points"
                          )?.ScoreValueMap || 0}
                          {/* {data?.UserLeaderBoardScores[0]?.ScoreValueMap} */}
                        </div>
                        <div>
                          <FormattedMessage id="class.points" />
                        </div>
                      </div>
                      <div className={classes.items}>
                        <div className={classes.row}>
                          {data?.UserLeaderBoardScores?.find(
                            (val) => val.ScoreType === "Level"
                          )?.ScoreValueMap || "-"}
                          {/* {data?.UserLeaderBoardScores[1]?.ScoreValueMap} */}
                        </div>
                        <div>
                          <FormattedMessage id="credits.level" />
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={() =>
                        navigate("/credit", {
                          state: { GameCode: data?.GameCode },
                        })
                      }
                      className={classes.list__button}
                    >
                      <Button
                        type="submit"
                        label={<FormattedMessage id="credits.goToList" />}
                        className={classes.primaryThemeButton}
                      />
                    </div>
                  </div>
                );
              })
          : leaderboardData
              ?.slice(currentPage * 2 - 2, currentPage * 2)
              .map((data, index) => {
                return (
                  <div
                    key={data.Id}
                    className={
                      index === 0
                        ? classes.champion
                        : classes.champion + " " + classes.champion1
                    }
                  >
                    <img src={champ} alt="badge" className="champion" />
                    <div className={classes.title}>{data?.GameName}</div>
                    <div className={classes.flex2}>
                      <div className={classes.items}>
                        <div className={classes.row}>{data?.Rank}</div>
                        <div>
                          <FormattedMessage id="credits.rank" />
                        </div>
                      </div>
                      <div className={classes.items}>
                        <div className={classes.row}>
                          {data?.UserLeaderBoardScores?.find(
                            (val) => val.ScoreType === "Points"
                          )?.ScoreValueMap || 0}
                          {/* {data?.UserLeaderBoardScores[0]?.ScoreValueMap} */}
                        </div>
                        <div>
                          <FormattedMessage id="class.points" />
                        </div>
                      </div>
                      <div className={classes.items}>
                        <div className={classes.row}>
                          {data?.UserLeaderBoardScores?.find(
                            (val) => val.ScoreType === "Level"
                          )?.ScoreValueMap || "-"}
                          {/* {data?.UserLeaderBoardScores[1]?.ScoreValueMap} */}
                        </div>
                        <div>
                          <FormattedMessage id="credits.level" />
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={() =>
                        navigate("/credit", {
                          state: { GameCode: data?.GameCode },
                        })
                      }
                      className={classes.list__button}
                    >
                      <Button
                        type="submit"
                        label={<FormattedMessage id="credits.goToList" />}
                        className={classes.primaryThemeButton}
                      />
                    </div>
                  </div>
                );
              })}
      </div>
      {leaderboardData?.length > 0 && (
        <>
          {currentPage !== 1 && currentPage !== 0 && (
            <div className={classes.prev} onClick={handlePrevious}>
              ❮
            </div>
          )}
          {currentPage !== lastPage && (
            <div className={classes.next} onClick={handleNext}>
              ❯
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LeaderboardModalCarousel;
