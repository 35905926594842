import actionTypes from "../actions/actionTypes";

const initialState = {
  errorType: null,
  error: null,
  isError: false,
  loading: false,
  closeTimer: 3000,
};

const errorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { errorType, error } = payload || {};
  switch (type) {
    case actionTypes.errorTypes.SET_ERROR:
      return {
        ...state,
        isError: true,
        errorType: errorType,
        error: error,
        closeTimer: payload?.closeTimer ? payload?.closeTimer : 3000,
      };
    case actionTypes.errorTypes.UNSET_ERROR:
      return {
        ...state,
        isError: false,
        errorType: null,
        error: null,
        closeTimer: 3000,
      };
    case actionTypes.errorTypes.SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.errorTypes.UNSET_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default errorReducer;
