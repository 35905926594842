import React from "react";

const HamburgerIcon = ({ stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.847"
      height="15.5"
      viewBox="0 0 19.847 15.5"
    >
      <g id="hamburger_icon" transform="translate(-15.5 -18.75)">
        <line
          id="Line_169"
          data-name="Line 169"
          x2="19.847"
          transform="translate(15.5 19.5)"
          fill="none"
          stroke={stroke ? stroke : "#404040"}
          strokeWidth="1.5"
        />
        <line
          id="Line_170"
          data-name="Line 170"
          x2="19.847"
          transform="translate(15.5 26.5)"
          fill="none"
          stroke={stroke ? stroke : "#404040"}
          strokeWidth="1.5"
        />
        <line
          id="Line_171"
          data-name="Line 171"
          x2="19.847"
          transform="translate(15.5 33.5)"
          fill="none"
          stroke={stroke ? stroke : "#404040"}
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default HamburgerIcon;
