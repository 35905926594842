import React, { useRef } from "react";
import HomeHeader from "../HomeHeader/HomeHeader";
import HomeNavbar from "../HomeNavbar/HomeNavbar";
import classes from "./HomeHeaderContainer.module.scss";
const HomeHeaderContainer = props => {
  const ref = useRef();
  return (
    <header className={classes.homeHeaderContainer}>
      <HomeHeader resethandler={props.resethandler} ref={ref} />
      <HomeNavbar
        advanceSearchData={props.advanceSearchData}
        searchData={props.searchData}
        checkboxcallBack={props.checkboxcallBack}
        changeLanguageHandler={props.changeLanguageHandler}
        languagearray={props.languagearray}
        changeLanguagebox={props.changeLanguagebox}
        languagesearchbox={props.languagesearchbox}
        resethandler={props.resethandler}
        sortby={props.sortby}
        onSortByChange={props.onSortByChange}
        statuschangeHandler={props.statuschangeHandler}
        status={props.status}
        trainingtypearr={props.trainingtypearr}
        clickType={props.clickType}
        trainingtype={props.trainingtype}
        changetrainingtype={props.changetrainingtype}
        trainingtypesearchbox={props.trainingtypesearchbox}
      />
    </header>
  );
};

export default HomeHeaderContainer;
