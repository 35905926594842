import fetchIntercept from "fetch-intercept";
import { resetTimerAction } from "../store/actions/actionCreators/homeActionCreator";

export const fetchInterceptor = (dispatch) => {
  fetchIntercept.register({
    request: function (url, config) {
      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      dispatch(resetTimerAction());
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      return Promise.reject(error);
    },
  });
};
