import actionTypes from "../actionTypes";

export const getAllLanguagesAction = (data) => ({
  type: actionTypes.homeTypes.GET_ALL_LANGUAGES,
  payload: data,
});
export const setSelectedLanguageAction = (data) => ({
  type: actionTypes.homeTypes.SET_LANGUAGE,
  payload: data,
});
export const getNotificationsAction = (data) => ({
  type: actionTypes.homeTypes.GET_NOTIFICATIONS,
  payload: data,
});
export const setThemeStylesAction = (data) => ({
  type: actionTypes.homeTypes.SET_THEME_STYLES,
  payload: data,
});
export const resetTimerAction = () => ({
  type: actionTypes.homeTypes.RESET_TIMER,
});
export const isRedirectedToTopicsAction = () => ({
  type: actionTypes.homeTypes.IS_REDIRECTED_TO_TOPICS,
});

export const getAssignedClassesAction = (data) => ({
  type: actionTypes.homeTypes.GET_ASSIGNED_CLASSES,
  payload: data,
});
export const setLoadingAssignedClassesAction = (data) => ({
  type: actionTypes.homeTypes.SET_LOADING_ASSIGNED_CLASSES,
  payload: data,
});
export const getAssignedClassesCountAction = (data) => ({
  type: actionTypes.homeTypes.GET_ASSIGNED_CLASSES_COUNT,
  payload: data,
});

export const getCompletedClassesAction = (data) => ({
  type: actionTypes.homeTypes.GET_COMPLETED_CLASSES,
  payload: data,
});
export const setLoadingCompletedClassesAction = (data) => ({
  type: actionTypes.homeTypes.SET_LOADING_COMPLETED_CLASSES,
  payload: data,
});
export const getCompletedClassesCountAction = (data) => ({
  type: actionTypes.homeTypes.GET_COMPLETED_CLASSES_COUNT,
  payload: data,
});

export const getTrendingClassesAction = (data) => ({
  type: actionTypes.homeTypes.GET_TRENDING_CLASSES,
  payload: data,
});
export const setLoadingTrendingClassesAction = (data) => ({
  type: actionTypes.homeTypes.SET_LOADING_TRENDING_CLASSES,
  payload: data,
});
export const getTrendingClassesCountAction = (data) => ({
  type: actionTypes.homeTypes.GET_TRENDING_CLASSES_COUNT,
  payload: data,
});

export const getInProgressClassesAction = (data) => ({
  type: actionTypes.homeTypes.GET_INPROGRESS_CLASSES,
  payload: data,
});
export const setLoadingInProgressClassesAction = (data) => ({
  type: actionTypes.homeTypes.SET_LOADING_INPROGRESS_CLASSES,
  payload: data,
});
export const getInProgressClassesCountAction = (data) => ({
  type: actionTypes.homeTypes.GET_INPROGRESS_CLASSES_COUNT,
  payload: data,
});

export const getNotInProgressClassesAction = (data) => ({
  type: actionTypes.homeTypes.GET_NOTINPROGRESS_CLASSES,
  payload: data,
});
export const setLoadingNotInProgressClassesAction = (data) => ({
  type: actionTypes.homeTypes.SET_LOADING_NOTINPROGRESS_CLASSES,
  payload: data,
});
export const getNotInProgressClassesCountAction = (data) => ({
  type: actionTypes.homeTypes.GET_NOTINPROGRESS_CLASSES_COUNT,
  payload: data,
});

export const getAssignedSeriesAction = (data) => ({
  type: actionTypes.homeTypes.GET_ASSIGNED_SERIES,
  payload: data,
});
export const setLoadingAssignedSeriesAction = (data) => ({
  type: actionTypes.homeTypes.SET_LOADING_ASSIGNED_SERIES,
  payload: data,
});
export const getAssignedSeriesCountAction = (data) => ({
  type: actionTypes.homeTypes.GET_ASSIGNED_SERIES_COUNT,
  payload: data,
});

export const getCompletedSeriesAction = (data) => ({
  type: actionTypes.homeTypes.GET_COMPLETED_SERIES,
  payload: data,
});
export const setLoadingCompletedSeriesAction = (data) => ({
  type: actionTypes.homeTypes.SET_LOADING_COMPLETED_SERIES,
  payload: data,
});
export const getCompletedSeriesCountAction = (data) => ({
  type: actionTypes.homeTypes.GET_COMPLETED_SERIES_COUNT,
  payload: data,
});

export const getTrendingSeriesAction = (data) => ({
  type: actionTypes.homeTypes.GET_TRENDING_SERIES,
  payload: data,
});
export const setLoadingTrendingSeriesAction = (data) => ({
  type: actionTypes.homeTypes.SET_LOADING_TRENDING_SERIES,
  payload: data,
});
export const getTrendingSeriesCountAction = (data) => ({
  type: actionTypes.homeTypes.GET_TRENDING_SERIES_COUNT,
  payload: data,
});

export const getInProgressSeriesAction = (data) => ({
  type: actionTypes.homeTypes.GET_INPROGRESS_SERIES,
  payload: data,
});
export const setLoadingInProgressSeriesAction = (data) => ({
  type: actionTypes.homeTypes.SET_LOADING_INPROGRESS_SERIES,
  payload: data,
});
export const getInProgressSeriesCountAction = (data) => ({
  type: actionTypes.homeTypes.GET_INPROGRESS_SERIES_COUNT,
  payload: data,
});
export const getNotInProgressSeriesAction = (data) => ({
  type: actionTypes.homeTypes.GET_NOTINPROGRESS_SERIES,
  payload: data,
});
export const setLoadingNotInProgressSeriesAction = (data) => ({
  type: actionTypes.homeTypes.SET_LOADING_NOTINPROGRESS_SERIES,
  payload: data,
});
export const getNotInProgressSeriesCountAction = (data) => ({
  type: actionTypes.homeTypes.GET_NOTINPROGRESS_SERIES_COUNT,
  payload: data,
});
export const getLeaderboardAction = (data) => ({
  type: actionTypes.homeTypes.GET_LEADERBOARD,
  payload: data,
});
export const getTrainingTypesDone = (data) => ({
  type: actionTypes.homeTypes.HOME_GET_TRAININGTYPE,
  payload: data,
});

export const setHomeFilterAction = (data) => ({
  type: actionTypes.homeTypes.SET_HOME_FILTER,
  payload: data,
});
export const setHomeFilterSelectedAction = (data) => ({
  type: actionTypes.homeTypes.SET_HOME_FILTER_SELECTED,
  payload: data,
});

export const setHomeBannerIterationAction = (data) => ({
  type: actionTypes.homeTypes.SET_HOME_BANNER_ITERATION,
  payload: data,
});

export const setInitialClassesSeriesStateAction = () => ({
  type: actionTypes.homeTypes.SET_INITIAL_CLASSES_SERIES_STATE,
});
