import actionTypes from "../actionTypes";

export const requestAPI = () => ({
  type: actionTypes.API_REQ_STARTED,
});
export const removeGlobalSearchResult = () => ({
  type: actionTypes.globalSearchTypes.REMOVE_GLOBAL_RESULT,
});
export const getSearchResultDone = data => ({
  type: actionTypes.globalSearchTypes.GLOBAL_SEARCH,
  payload: data
});
export const updateSearchText = (data) => ({
  type: actionTypes.globalSearchTypes.UPDATE_SEARCH_TEXT,
  payload: data
})
export const updateDidYouMeanSuggestions = (data) => ({
  type: actionTypes.globalSearchTypes.UPDATE_DID_YOU_MEAN_SUGGESTIONS,
  payload: data
})
export const catchError = data => ({
  type: actionTypes.errorTypes.API_ERROR,
  payload: data,
});
