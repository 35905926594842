import actionTypes from "../actions/actionTypes";

const initialState = {
  globalSearch:[],
  didYouMeanSuggestions:[],
  searchText:''
};

const globalSearchReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.API_REQ_STARTED:
    return { ...state,loading:true};
    case actionTypes.errorTypes.API_ERROR:
    return { ...state,loading:false,error:payload};
    case actionTypes.globalSearchTypes.GLOBAL_SEARCH:
      return { ...state,loading: false, globalSearch:payload};
    case actionTypes.globalSearchTypes.REMOVE_GLOBAL_RESULT:
      return { ...state,loading: false, globalSearch:[]};
    case actionTypes.globalSearchTypes.UPDATE_DID_YOU_MEAN_SUGGESTIONS:
      return { ...state, didYouMeanSuggestions:payload};
    case actionTypes.globalSearchTypes.UPDATE_SEARCH_TEXT:
      return { ...state, searchText:payload};
    default:
      return state;
  }
};

export default globalSearchReducer;
