import actionTypes from "../actions/actionTypes";

const initialState = {
  classesBySeriesId: null,
};

const seriesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.seriesTypes.GET_CLASSES_BY_SERIESID:
      return {
        ...state,
        classesBySeriesId: payload,
      };

    default:
      return state;
  }
};

export default seriesReducer;
