import actionTypes from "../actions/actionTypes";

const initialState = {
  isAuthenticated: false,
  user: null,
  preLogin: null,
  login: null,
  lastLoginTime: null,
};

const loginReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.loginTypes.PRE_LOGIN:
      return { ...state, preLogin: payload };
    case actionTypes.loginTypes.GET_TENANT_DETAILS:
      return { ...state, preLogin: payload };
    case actionTypes.loginTypes.LOGIN:
      return { ...state, login: payload, isAuthenticated: true };
    case actionTypes.loginTypes.LAST_LOGIN_TIME:
      return { ...state, lastLoginTime: payload };
    default:
      return state;
  }
};

export default loginReducer;
