import React, { Fragment, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import messages from "../../utils/messages";

const LanguageProvider = ({ children }) => {
  const rtlLangArr = ["ar"];
  const chineseLangArr = ["zh"];
  const franceLangArr = ["fr"];
  const germanLangArr = ["de"];
  const koreanLangArr = ["ko"];
  const japanLangArr = ["ja"];
  const spanishLangArr = ["es"];
  const portuguesLangArr = ["pt"];
  const homeState = useSelector((state) => state.homeReducer);
  const { selectedLanguage } = homeState;

  useEffect(() => {
    if (rtlLangArr.includes(selectedLanguage)) {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("html")[0].removeAttribute("data-language");
    }
    else if (chineseLangArr.includes(selectedLanguage)) {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.getElementsByTagName("html")[0].setAttribute("data-language", "zh");
    }
    else if (franceLangArr.includes(selectedLanguage)) {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.getElementsByTagName("html")[0].setAttribute("data-language", "fr");
    }
    else if (germanLangArr.includes(selectedLanguage)) {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.getElementsByTagName("html")[0].setAttribute("data-language", "de");
    }
    else if (koreanLangArr.includes(selectedLanguage)) {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.getElementsByTagName("html")[0].setAttribute("data-language", "ko");
    }
    else if (japanLangArr.includes(selectedLanguage)) {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.getElementsByTagName("html")[0].setAttribute("data-language", "ja");
    } 
    else if (spanishLangArr.includes(selectedLanguage)) {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.getElementsByTagName("html")[0].setAttribute("data-language", "es");
    }
    else if (portuguesLangArr.includes(selectedLanguage)) {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.getElementsByTagName("html")[0].setAttribute("data-language", "pt");
    }  else {
      document.getElementsByTagName("html")[0].removeAttribute("dir");
      document.getElementsByTagName("html")[0].removeAttribute("data-language");
    }

    // other solution
    // const dir = rtlLanguages.includes(language) ? "rtl" : "ltr"
    // document.documentElement.dir = dir
  }, [selectedLanguage, rtlLangArr]);

  return (
    <IntlProvider
      textComponent={Fragment}
      locale={selectedLanguage}
      messages={messages[selectedLanguage]}
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
