import actionTypes from "../actions/actionTypes";

const initialState = {
  generalQueries:[],
  forumTopics:[],
  forumFollowUnfollow: '',
  forumTopicsDetails: [],
  forumAddThread: [],
  ThreadComments: [],
  pollresponse:{}
};

const connectReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.API_REQ_STARTED:
    return { ...state};
    case actionTypes.errorTypes.API_ERROR:
      return { ...state,error: payload};
    case actionTypes.connectTypes.GET_GENERAL_QUERIES:
      return { ...state,generalQueries: payload };
    case actionTypes.connectTypes.CONNECT_FORUM_TOPICS:
      return { ...state,forumTopics: payload };
    case actionTypes.connectTypes.CONNECT_FORUM_TOPICSFOLLOWUNFOLLOW:
      return { ...state,forumFollowUnfollow: payload };
    case actionTypes.connectTypes.CONNECT_FORUM_TOPICSDETAILS:
      return { ...state,forumTopicsDetails: payload };
    case actionTypes.connectTypes.CONNECT_FORUM_ADDTHREADS:
      return { ...state,forumAddThread: payload };
    case actionTypes.connectTypes.CONNECT_FORUM_ADDTHREADSCOMMENTS:
      return { ...state,ThreadComments: payload };
    case actionTypes.connectTypes.CONNECT_POLL_GETPOLLS:
      return { ...state,polls: payload };
    case actionTypes.connectTypes.CONNECT_POLL_GETRESPONSE:
      return { ...state,pollresponse: payload };
    case actionTypes.connectTypes.CONNECT_POLL_POSTUSERPOLLRESPONSE:
      return { ...state,postUserPollResponse: payload };
    case actionTypes.connectTypes.CONNECT_POLL_ISPOLLAVAILABLE:
      return { ...state,PollAvailable: payload };
    case actionTypes.connectTypes.CONNECT_POLL_GETPOLLHISTORY:
      return { ...state,pollhistory: payload };
    default:
      return state;
  }
};

export default connectReducer;
