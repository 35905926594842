import actionTypes from "../actionTypes";

export const getCreditDetailsAction = (data) => ({
  type: actionTypes.creditTypes.GET_CREDIT_DETAILS,
  payload: data,
});

export const setCreditStateTypeAction = (data) => ({
  type: actionTypes.creditTypes.SET_CREDIT_STATE_TYPE,
  payload: data,
});

export const setCreditFilterTypeAction = (data) => ({
  type: actionTypes.creditTypes.SET_CREDIT_FILTER_TYPE,
  payload: data,
});
