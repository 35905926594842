import React from "react";

const HomeSidebarHome = ({ active }) => {
  return (
    <svg
      id="ft-1"
      xmlns="http://www.w3.org/2000/svg"
      width="25.024"
      height="23"
      viewBox="0 0 25.024 23"
    >
      <path
        id="Path_38"
        data-name="Path 38"
        d="M5.6,20.391,7.484,22.5l10.628-9.532L28.74,22.5l1.884-2.109L18.112,9.2Z"
        transform="translate(-5.6 -9.2)"
        fill={active ? "#fff" : "#404040"}
      />
      <path
        id="Path_39"
        data-name="Path 39"
        d="M36.139,46.433V36.845L27.169,29,18.2,36.845v9.588h6.832v-7.2H29.25v7.2Z"
        transform="translate(-14.657 -23.433)"
        fill={active ? "#fff" : "#404040"}
      />
    </svg>
  );
};

export default HomeSidebarHome;
