import React from "react";
import resetIcon from "../../assets/images/reset_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { setCreditFilterTypeAction } from "../../store/actions/actionCreators/creditActionCreator";
import classes from "./FilterCredit.module.scss";
import { FormattedMessage } from "react-intl";
const FilterCredit = ({ reference }) => {
  const dispatch = useDispatch();
  const creditState = useSelector((state) => state.creditReducer);
  const { creditFilterType } = creditState;
  const handleSetTime = (value) => {
    const val = parseInt(value);
    dispatch(setCreditFilterTypeAction(val));
  };
  const handleResetTime = () => {
    if (creditFilterType !== 1) {
      dispatch(setCreditFilterTypeAction(1));
    }
  };

  return (
    <div ref={reference} className={classes.filter}>
      <div className={classes.filter__header}>
        <div className={classes.filter__header__heading}>
          <FormattedMessage id="credits.filterByTime" />
        </div>
        <div
          onClick={handleResetTime}
          className={classes.filter__header__reset}
        >
          <img
            src={resetIcon}
            className={classes.filter__header__img}
            alt="reset by time"
          />
          <FormattedMessage id="GlobalSearch.Reset" />
        </div>
      </div>
      <div className={classes.filter__body}>
        <div className={classes.filter__body__heading}>
          <FormattedMessage id="GlobalSearch.SortBy" />:
        </div>
        <ul className={classes.filter__body__list}>
          <li onClick={() => handleSetTime(1)}>
            <input
              type="radio"
              name="time"
              checked={creditFilterType === 1}
              value={1}
              onChange={(e) => handleSetTime(e.target.value)}
            />
            <FormattedMessage id="credits.allTime" />
          </li>
          <li onClick={() => handleSetTime(2)}>
            <input
              type="radio"
              name="time"
              checked={creditFilterType === 2}
              value={2}
              onChange={(e) => handleSetTime(e.target.value)}
            />
            <FormattedMessage id="credits.today" />
          </li>
          <br />
          <li onClick={() => handleSetTime(3)}>
            <input
              type="radio"
              name="time"
              checked={creditFilterType === 3}
              value={3}
              onChange={(e) => handleSetTime(e.target.value)}
            />
            <FormattedMessage id="credits.thisWeek" />
          </li>
          <li onClick={() => handleSetTime(4)}>
            <input
              type="radio"
              name="time"
              checked={creditFilterType === 4}
              value={4}
              onChange={(e) => handleSetTime(e.target.value)}
            />
            <FormattedMessage id="credits.lastMonth" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FilterCredit;
