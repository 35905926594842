import actionTypes from "../actions/actionTypes";

const initialState = {
  isFavoriteAvailable: false,
  otherFavoriteClasses: null,
  otherFavoriteClassesDone: false,
  otherFavoriteClassesCount: 0,
  otherFavoriteSeries: null,
  otherFavoriteSeriesDone: false,
  otherFavoriteSeriesCount: 0,
};

const favoriteReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.favoriteTypes.IS_FAV_AVAILABLE:
      return {
        ...state,
        isFavoriteAvailable: payload,
      };
    case actionTypes.favoriteTypes.GET_OTHER_FAV_CLASSES:
      return {
        ...state,
        otherFavoriteClasses: payload,
      };
    case actionTypes.favoriteTypes.SET_LOADING_OTHER_FAV_CLASSES:
      return {
        ...state,
        otherFavoriteClassesDone: payload,
      };
    case actionTypes.favoriteTypes.GET_OTHER_FAV_CLASSES_COUNT:
      return {
        ...state,
        otherFavoriteClassesCount: payload,
      };

    case actionTypes.favoriteTypes.GET_OTHER_FAV_SERIES:
      return {
        ...state,
        otherFavoriteSeries: payload,
      };
    case actionTypes.favoriteTypes.SET_LOADING_OTHER_FAV_SERIES:
      return {
        ...state,
        otherFavoriteSeriesDone: payload,
      };
    case actionTypes.favoriteTypes.GET_OTHER_FAV_SERIES_COUNT:
      return {
        ...state,
        otherFavoriteSeriesCount: payload,
      };
    case actionTypes.favoriteTypes.SET_INITIAL_FAVORITE_CLASSES_SERIES_STATE:
      return {
        ...state,
        otherFavoriteClasses: null,
        otherFavoriteClassesDone: false,
        otherFavoriteClassesCount: 0,
        otherFavoriteSeries: null,
        otherFavoriteSeriesDone: false,
        otherFavoriteSeriesCount: 0,
      };

    default:
      return state;
  }
};

export default favoriteReducer;
