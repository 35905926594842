import React from "react";
import loader from "../../../assets/images/loader-6.gif";
import classes from "./PageLoader.module.scss";
const PageLoader = ({ message }) => {
  return (
    <div className={classes.loader_container}>
      {/* <div className={classes.loader}></div> */}
      <img src={loader} className={classes.loader__gif} alt="loading" />
      {message && <div className={classes.loader__message}>{message}</div>}
    </div>
  );
};

export default PageLoader;
