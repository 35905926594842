import actionTypes from "../actions/actionTypes";

const initialState = {
    profile: null,
    isUserExists: false,
    isUserActive: false,
    isMailSentForForgotPassword: false,
    isPasswordResetRequired: false,
    isPasswordResetDone: false
};

const userReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.userTypes.IS_USER_EXISTS:
            return { ...state, isUserExists: payload };
        case actionTypes.userTypes.IS_USER_ACTIVE:
            return { ...state, isUserActive: payload };
        case actionTypes.userTypes.IS_MAIL_SENT_FORGOT_PASSWORD:
            return { ...state, isMailSentForForgotPassword: payload };
        case actionTypes.userTypes.IS_PASSWORD_RESET_REQUIRED:
            return { ...state, isPasswordResetRequired: payload };
        case actionTypes.userTypes.GET_USER_PROFILE:
            return { ...state, profile: payload };
        case actionTypes.userTypes.IS_PASSWORD_RESET_DONE:
            return { ...state, isPasswordResetDone: payload };
        case actionTypes.userTypes.PUT_EDIT_PROFILE:
            return { ...state, profile: payload };
        default:
            return state;
    }
};

export default userReducer;
