import React from "react";

const HomeSidebarQuiz = ({ active }) => {
  return (
    <svg
      id="Group_930"
      data-name="Group 930"
      xmlns="http://www.w3.org/2000/svg"
      width="15.626"
      height="18.886"
      viewBox="0 0 15.626 18.886"
    >
      <path
        id="Path_1095"
        data-name="Path 1095"
        d="M25.908,17.847a.733.733,0,0,0,.417-.656V7.649a1.018,1.018,0,0,0-1.014-1.014H22.171a5.866,5.866,0,1,0-7.2,7.376v4.93h0a2.443,2.443,0,0,0,2.445,2.445H25.59a.7.7,0,0,0,.7-.557.734.734,0,0,0-.378-.815,1.214,1.214,0,0,1-.676-1.093A1.328,1.328,0,0,1,25.908,17.847ZM11.893,8.365a4.672,4.672,0,1,1,4.672,4.672A4.679,4.679,0,0,1,11.893,8.365ZM24.02,19.955h-6.6a1.014,1.014,0,0,1,0-2.028h6.6a2.685,2.685,0,0,0,0,2.028Z"
        transform="translate(-10.7 -2.5)"
        fill={active ? "#fff" : "#404040"}
      />
      <path
        id="Path_1096"
        data-name="Path 1096"
        d="M32.541,16.95a.706.706,0,0,1-.04.716,1.621,1.621,0,0,1-.477.437,1.775,1.775,0,0,0-.855,1.491.372.372,0,0,0,.1.278.321.321,0,0,0,.258.119h.517a.366.366,0,0,0,.358-.338c.02-.358.1-.4.338-.557a3.233,3.233,0,0,0,.755-.7,1.935,1.935,0,0,0,.159-1.988,2.189,2.189,0,0,0-4.075.775.356.356,0,0,0,.08.278.393.393,0,0,0,.278.119h.5a.362.362,0,0,0,.358-.3.868.868,0,0,1,.954-.775A.772.772,0,0,1,32.541,16.95Z"
        transform="translate(-25.822 -12.755)"
        fill={active ? "#fff" : "#404040"}
      />
      <circle
        id="Ellipse_273"
        data-name="Ellipse 273"
        cx="0.736"
        cy="0.736"
        r="0.736"
        transform="translate(5.228 7.733)"
        fill={active ? "#fff" : "#404040"}
      />
    </svg>
  );
};

export default HomeSidebarQuiz;
