import actionTypes from "../actions/actionTypes";

const initialState = {
  quizData: null,
  virtualQuizData: null,
  accessScoreData: null,
  vqLeaderboardData: null,
  vqQnaData: null,
  vqCorrectAnswerReport: null,
  loading: false,
  vQuizStarted: false,
  vQuizEnded: false,
  endQCounter: 0,
  startCounter: 0,
  timerdialog: false,
  sessiondialog: false,
  timerreset: new Date(),
  signalRconnection: null,
  beginEndLiveQuizQues: null,
  liveQuizUserChoiceCount: null,
  liveQuizscreenView: null,
  unAttemptedQuestionNumber: 1,
  answeredCurrentQuestion: false,
  liveQuizUserLeaderBoard: null,
  normalExit: false,
};

const quizReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.quizTypes.VALIDATE_PASSCODE:
      return {
        ...state,
        quizData: payload,
      };
    case actionTypes.quizTypes.virtualQuiz.FETCH_QUIZ_DETAILS:
      return {
        ...state,
        virtualQuizData: payload,
      };
    case actionTypes.quizTypes.virtualQuiz.POSTVQASSESSCORE:
      return {
        ...state,
        accessScoreData: payload,
      };
    case actionTypes.quizTypes.virtualQuiz.FETCH_QUIZ_LEADERBOARD:
      return {
        ...state,
        vqLeaderboardData: payload,
      };
    case actionTypes.quizTypes.virtualQuiz.FETCH_QUIZ_QNA:
      return {
        ...state,
        vqQnaData: payload,
      };
    case actionTypes.quizTypes.virtualQuiz.FETCH_QUIZ_CORRECT_ANSWER_REPORT:
      return {
        ...state,
        vqCorrectAnswerReport: payload,
      };
    case actionTypes.quizTypes.virtualQuiz.timer.VQUIZ_TIMER_STARTED:
      return {
        ...state,
        loading: true,
        vQuizStarted: true,
        vQuizEnded: false,
        startQCounter: 0,
      };
    case actionTypes.quizTypes.virtualQuiz.timer.VQUIZ_TIMER_ENDED:
      return {
        ...state,
        loading: true,
        vQuizStarted: false,
        vQuizEnded: true,
        endQCounter: 0,
        endVQInprogress: false,
      };
    case actionTypes.quizTypes.virtualQuiz.timer.VQUIZ_TIMER_ENDEDCOUNT:
      return {
        ...state,
        loading: true,
        endQCounter: 1,
      };
    case actionTypes.quizTypes.virtualQuiz.timer.VQUIZ_TIMER_STARTEDCOUNT:
      return {
        ...state,
        loading: true,
        startQCounter: 1,
      };
    case actionTypes.quizTypes.virtualQuiz.timer.SET_TIMER_DIALOG:
      return {
        ...state,
        timerdialog: payload,
      };
    case actionTypes.quizTypes.virtualQuiz.timer.SET_SESSION_DIALOG:
      return {
        ...state,
        sessiondialog: payload,
      };
    case actionTypes.quizTypes.liveQuiz.SET_SIGNALR_NAGOTIATE:
      return {
        ...state,
        signalRconnection: payload,
      };
    case actionTypes.quizTypes.liveQuiz.FETCH_BEGIN_END_LIVE_QUIZ_QUESTION:
      return {
        ...state,
        beginEndLiveQuizQues: payload,
      };
    case actionTypes.quizTypes.liveQuiz.FETCH_USER_CHOICE_COUNT_LIVE_QUIZ:
      return {
        ...state,
        liveQuizUserChoiceCount: payload,
      };
    case actionTypes.quizTypes.liveQuiz.SET_LIVE_QUIZ_SCREEN_VIEW:
      return {
        ...state,
        liveQuizscreenView: payload,
      };
    case actionTypes.quizTypes.virtualQuiz.UNATTEMPTED_QUESTION_NUMBER:
      return {
        ...state,
        unAttemptedQuestionNumber: payload,
      };
    case actionTypes.quizTypes.liveQuiz.POST_LIVE_QUIZ_ASSESSMENT_SCORE:
      return {
        ...state,
        answeredCurrentQuestion: payload,
      };
    case actionTypes.quizTypes.liveQuiz.GET_LIVE_QUIZ_USER_LEADERBOARD:
      return {
        ...state,
        liveQuizUserLeaderBoard: payload,
      };
    case actionTypes.quizTypes.liveQuiz.SET_LIVE_QUIZ_NORMAL_EXIT:
      return {
        ...state,
        normalExit: payload,
      };
    default:
      return state;
  }
};

export default quizReducer;
