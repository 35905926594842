import actionTypes from "../actionTypes";

export const isFavoriteAvailableAction = (data) => ({
  type: actionTypes.favoriteTypes.IS_FAV_AVAILABLE,
  payload: data,
});
export const getOtherFavoriteClassesAction = (data) => ({
  type: actionTypes.favoriteTypes.GET_OTHER_FAV_CLASSES,
  payload: data,
});
export const setLoadingOtherFavoriteClassesAction = (data) => ({
  type: actionTypes.favoriteTypes.SET_LOADING_OTHER_FAV_CLASSES,
  payload: data,
});
export const getOtherFavoriteClassesCountAction = (data) => ({
  type: actionTypes.favoriteTypes.GET_OTHER_FAV_CLASSES_COUNT,
  payload: data,
});
export const getOtherFavoriteSeriesAction = (data) => ({
  type: actionTypes.favoriteTypes.GET_OTHER_FAV_SERIES,
  payload: data,
});
export const setLoadingOtherFavoriteSeriesAction = (data) => ({
  type: actionTypes.favoriteTypes.SET_LOADING_OTHER_FAV_SERIES,
  payload: data,
});
export const getOtherFavoriteSeriesCountAction = (data) => ({
  type: actionTypes.favoriteTypes.GET_OTHER_FAV_SERIES_COUNT,
  payload: data,
});
export const setInitialFavoriteClassesSeriesStateAction = () => ({
  type: actionTypes.favoriteTypes.SET_INITIAL_FAVORITE_CLASSES_SERIES_STATE,
});
