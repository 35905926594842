import {
  ENV_Name,
  FETCH_VIRTUAL_QUIZ_API_URL,
  GET_LIVE_QUIZ_BEGIN_END_LIVE_QUIZ_QUESTION,
  GET_LIVE_QUIZ_USER_CHOICE_COUNT,
  GET_LIVE_QUIZ_USER_LEADER_BOARD,
  GET_VIRTUAL_QUIZ_CORRECT_ANSWER_REPORT,
  GET_VIRTUAL_QUIZ_LEADERBOARD,
  GET_VIRTUAL_QUIZ_QNA,
  POST_ASSESSMENT_SCORE_API_URL,
  POST_LIVE_QUIZ_ASSESSMENT_SCORE_API_URL,
  SIGNALR_NEGOTIATE_API_URL,
  VALIDATE_PASSCODE_API_URL,
} from "../../../utils/devApiContants";
import {
  setErrorHandler,
  setLoadingHandler,
  unSetLoadingHandler,
} from "./errorActionHandler";
import * as quizActionCreator from "../actionCreators/quizActionCreator";
import { errorTypes } from "../../../utils/errorTypes";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import actionTypes from "../actionTypes";
const { FAIL } = errorTypes;

export const ValidateLiveEventPasscode =
  (passCode, navigate) => async (dispatch) => {
    dispatch(setLoadingHandler());
    const data = {
      Passcode: passCode,
    };
    const header = JSON.parse(localStorage.getItem("headers"));
    const body = JSON.stringify(data);
    await fetch(VALIDATE_PASSCODE_API_URL, {
      method: "POST",
      headers: header,
      body: body,
    })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(setErrorHandler(FAIL, "Error while validating quiz"));
        } else if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        if (result !== null && result.ErrorMessage === "") {
          dispatch(quizActionCreator.validateLiveEventPasscode(result));
          if (result.TrainingMode === "virtual") {
            navigate("/quiz/virtualquiz");
          } else {
            navigate("/quiz/livequiz");
          }
        } else {
          dispatch(
            setErrorHandler(
              FAIL,
              "Invalid passcode. Please try again with valid Passcode"
            )
          );
        }
      })
      .catch((error) => {
        console.log("Fetch error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };

export const onStartQuizCLick =
  (id, VirtualOrLiveQuizEventId) => async (dispatch) => {
    dispatch(setLoadingHandler());
    const url =
      FETCH_VIRTUAL_QUIZ_API_URL +
      "?id=" +
      id +
      "&VirtualOrLiveQuizEventId=" +
      VirtualOrLiveQuizEventId;
    const header = JSON.parse(localStorage.getItem("headers"));

    await fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(setErrorHandler(FAIL, "Error While Fetching quiz Data"));
        } else if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        if (result !== null) {
          dispatch(quizActionCreator.fetchQuizDetails(result));
        }
      })
      .catch((error) => {
        console.log("Fetch error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };
export const setSelectedVirtualQuizOptionActionHandler =
  (questionId, answerId) => (dispatch, getState) => {
    const virtualQuizDetails = getState().quizReducer?.virtualQuizData;
    console.log(virtualQuizDetails);
    const updatedvirtualQuizDetails =
      virtualQuizDetails?.AssessmentSet?.QuestionList.map((question) => {
        if (question.ID !== questionId) {
          return { ...question };
        } else if (question.ID === questionId) {
          question.isAnswered = true;
          question.ChoiceList.map((answer) => {
            if (answer.ID !== answerId) {
              answer.Isselected = false;
              answer.IsselectedByUserInResonse = false;
            } else if (answer.ID === answerId) {
              answer.Isselected = true;
              if (answer.Isselected === true && answer.IsAnswer === true) {
                question.correctlyAnswered = true;
              } else {
                question.correctlyAnswered = false;
              }
            }
            return { ...answer };
          });
        }
        return { ...question };
      });
    console.log(updatedvirtualQuizDetails);
    let dataAfterUpdate = {
      ...virtualQuizDetails,
      AssessmentSet: {
        ...virtualQuizDetails.AssessmentSet,
        QuestionList: updatedvirtualQuizDetails,
      },
    };
    dispatch(quizActionCreator.fetchQuizDetails(dataAfterUpdate));
  };
export const setIsAnsweredtrueIfIsselectedByUserInResonseTrue =
  () => (dispatch, getState) => {
    const virtualQuizDetails = getState().quizReducer?.virtualQuizData;
    const updatedvirtualQuizDetails =
      virtualQuizDetails?.AssessmentSet?.QuestionList.map((question) => {
        question?.ChoiceList.map((answer) => {
          if (answer?.IsselectedByUserInResonse === true) {
            question.isAnswered = true;
            if (answer.IsAnswer === true) {
              question.correctlyAnswered = true;
            } else {
              question.correctlyAnswered = false;
            }
          }
          return { ...answer };
        });
        return { ...question };
      });
    console.log(updatedvirtualQuizDetails);
    for (let i = 0; i < updatedvirtualQuizDetails.length; i++) {
      if (!updatedvirtualQuizDetails[i].isAnswered === true) {
        dispatch({
          type: actionTypes.quizTypes.virtualQuiz.UNATTEMPTED_QUESTION_NUMBER,
          payload: i + 1,
        });
        break;
      }
      if (i + 1 === updatedvirtualQuizDetails.length) {
        dispatch({
          type: actionTypes.quizTypes.virtualQuiz.UNATTEMPTED_QUESTION_NUMBER,
          payload: i + 1,
        });
      }
    }
    let dataAfterUpdate = {
      ...virtualQuizDetails,
      AssessmentSet: {
        ...virtualQuizDetails.AssessmentSet,
        QuestionList: updatedvirtualQuizDetails,
      },
    };
    dispatch(quizActionCreator.fetchQuizDetails(dataAfterUpdate));
  };
export const postVQAssessScore =
  (obj, classID) => async (dispatch, getState) => {
    const vQuizStarted = getState().quizReducer?.vQuizStarted;
    dispatch(setLoadingHandler());
    const header = JSON.parse(localStorage.getItem("headers"));
    const body = JSON.stringify(obj);
    const url = POST_ASSESSMENT_SCORE_API_URL + classID + "/assessScores";

    await fetch(url, {
      method: "POST",
      headers: header,
      body: body,
    })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(setErrorHandler(FAIL, "Error while submitting quiz data"));
        } else if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        if (result !== null && vQuizStarted) {
          dispatch(quizActionCreator.postVQAssesScoreDone(result));
        }
      })
      .catch((error) => {
        console.log("Fetch error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };
export const getVQLeaderboard = (passCode) => async (dispatch) => {
  dispatch(setLoadingHandler());
  const header = JSON.parse(localStorage.getItem("headers"));
  const url = GET_VIRTUAL_QUIZ_LEADERBOARD + "?passCode=" + passCode;

  await fetch(url, {
    method: "GET",
    headers: header,
  })
    .then((response) => {
      if (response.status !== 200) {
        dispatch(
          setErrorHandler(FAIL, "ErrorF while fetching leaderboard details")
        );
      } else if (response.status === 200) {
        return response.json();
      }
    })
    .then((result) => {
      if (result !== null) {
        dispatch(quizActionCreator.getVQLeaderboardDetails(result));
      }
    })
    .catch((error) => {
      console.log("Fetch error " + error);
    })
    .finally(() => {
      dispatch(unSetLoadingHandler());
    });
};
export const getVQQNA = (id, VirtualOrLiveQuizEventId) => async (dispatch) => {
  dispatch(setLoadingHandler());
  const header = JSON.parse(localStorage.getItem("headers"));
  const url =
    GET_VIRTUAL_QUIZ_QNA +
    "?id=" +
    id +
    "&VirtualOrLiveQuizEventId=" +
    VirtualOrLiveQuizEventId;
  await fetch(url, {
    method: "GET",
    headers: header,
  })
    .then((response) => {
      if (response.status !== 200) {
        dispatch(
          setErrorHandler(FAIL, "Error while fetching virtual quiz QNA details")
        );
      } else if (response.status === 200) {
        return response.json();
      }
    })
    .then((result) => {
      if (result !== null) {
        dispatch(quizActionCreator.getVQQNADetails(result));
      }
    })
    .catch((error) => {
      console.log("Fetch error " + error);
    })
    .finally(() => {
      dispatch(unSetLoadingHandler());
    });
};
export const getVQCorrectAnswerReport = (passCode) => async (dispatch) => {
  dispatch(setLoadingHandler());
  const url = GET_VIRTUAL_QUIZ_CORRECT_ANSWER_REPORT + "?passCode=" + passCode;
  const header = JSON.parse(localStorage.getItem("headers"));
  await fetch(url, {
    method: "GET",
    headers: header,
  })
    .then((response) => {
      if (response.status !== 200) {
        // dispatch(
        //   setErrorHandler(
        //     FAIL,
        //     "Error while Fetching Virtual quiz correct answer report"
        //   )
        // );
        console.log("Error while Fetching Virtual quiz correct answer report");
      } else if (response.status === 200) {
        return response.json();
      }
    })
    .then((result) => {
      if (result !== null) {
        dispatch(quizActionCreator.getVQCorrectAnswerReport(result));
      }
    })
    .catch((error) => {
      console.log("Fetch error " + error);
    })
    .finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const setSignalRNegotiateActionHandler = () => async (dispatch) => {
  dispatch(setLoadingHandler());
  const signalRNegotiateUrl =
    (JSON.parse(localStorage.getItem("tenantData"))?.SignalRNegotiateUrl ||
      SIGNALR_NEGOTIATE_API_URL) + "/api/negotiate";
  console.log(signalRNegotiateUrl);
  const header = {
    "x-ms-signalr-userid":
      ENV_Name.toUpperCase() +
      "+" +
      localStorage.getItem("tenantSlugName").toUpperCase() +
      "+" +
      localStorage.getItem("userId"),
    AuthenticationToken: localStorage.getItem("token"),
  };

  await fetch(signalRNegotiateUrl, {
    method: "POST",
    headers: header,
  })
    .then((response) => response.json())
    .then((result) => {
      const options = {
        accessTokenFactory: () => result.accessToken,
      };

      let connection = new HubConnectionBuilder()
        .withUrl(result.url, options)
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .configureLogging(LogLevel.Error)
        .configureLogging(LogLevel.Debug)
        .build();

      connection.onclose(() => console.log("disconnected signalr"));
      console.log("connecting...");
      connection
        .start()
        .then(() => {
          console.log("start called");
          console.log(JSON.stringify(connection));
          dispatch(quizActionCreator.setSignalRNegotiateAction(connection));
        })
        .catch(() => {
          console.log("cant connect to signalr");
        });
      dispatch(quizActionCreator.setSignalRNegotiateAction(connection));
    })

    .catch((error) => {
      console.log("Fetch error " + error);
    })
    .finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const getBeginEndLiveQuizQuestion =
  (payload, currentView) => async (dispatch) => {
    // dispatch(setLoadingHandler());
    const url = GET_LIVE_QUIZ_BEGIN_END_LIVE_QUIZ_QUESTION;
    const header = JSON.parse(localStorage.getItem("headers"));
    const body = JSON.stringify(payload);
    await fetch(url, {
      method: "POST",
      headers: header,
      body: body,
    })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(
            setErrorHandler(
              FAIL,
              "Error While checking begin or  end Live Quiz Question"
            )
          );
        } else if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        if (result !== null) {
          dispatch(quizActionCreator.getBeginEndLiveQuizQuestion(result));
          if (currentView.toString()) {
            dispatch({
              type: actionTypes.quizTypes.liveQuiz.SET_LIVE_QUIZ_SCREEN_VIEW,
              payload: currentView,
            });
          }
        }
      })
      .catch((error) => {
        console.log("Fetch error " + error);
      })
      .finally(() => {
        // dispatch(unSetLoadingHandler());
      });
  };

export const getUserChoiceCountLiveQuiz =
  (passCode, questionId) => async (dispatch) => {
    //dispatch(setLoadingHandler());
    const url =
      GET_LIVE_QUIZ_USER_CHOICE_COUNT +
      "?passCode=" +
      passCode +
      "&questionId=" +
      questionId;
    const header = JSON.parse(localStorage.getItem("headers"));

    await fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(
            setErrorHandler(
              FAIL,
              "Error While fetching live quiz user choice count"
            )
          );
        } else if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        if (result !== null) {
          dispatch(quizActionCreator.getUserChoiceCountLiveQuiz(result));
          dispatch({
            type: actionTypes.quizTypes.liveQuiz.SET_LIVE_QUIZ_SCREEN_VIEW,
            payload: 2,
          });
        }
      })
      .catch((error) => {
        console.log("Fetch error " + error);
      })
      .finally(() => {
        //dispatch(unSetLoadingHandler());
      });
  };

export const postLiveQuizAssessmentScoreActionHandler =
  (quizData, questionId, isRightAnswer, choiceId, timeSpentMilli) =>
  async (dispatch) => {
    await fetch(POST_LIVE_QUIZ_ASSESSMENT_SCORE_API_URL, {
      method: "POST",
      headers: JSON.parse(localStorage.getItem("headers")),
      body: JSON.stringify({
        classId: quizData.ShotClassId,
        questionId: questionId,
        choiceId: choiceId,
        isRightAnswer: isRightAnswer,
        courseCompletionDuration: timeSpentMilli * 10,
        isQuizSubmitForLiveOrVirtualEvent: false,
        virtualOrLiveQuizEventId: quizData.VirtualOrLiveQuizEventId,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        dispatch(quizActionCreator.postLiveQuizAssessmentScoreAction(true));
      })
      .catch((error) => {
        console.log("Fetch error " + error);
      });
  };
export const getLiveQuizUserLeaderBoardActionHandler =
  (passcode) => async (dispatch) => {
    await fetch(GET_LIVE_QUIZ_USER_LEADER_BOARD + passcode, {
      method: "GET",
      headers: JSON.parse(localStorage.getItem("headers")),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        dispatch(quizActionCreator.getLiveQuizUserLeaderBoardAction(result));
      })
      .catch((error) => {
        console.log("Fetch error " + error);
      });
  };
