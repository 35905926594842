import React, { useState } from "react";
import "./Tooltip.scss";
import styled from "@emotion/styled";

const Tooltip = (props) => {
    let timeout;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, props.delay || 400);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    const TooltipDiv = styled("div")`
        background: ${props.backgroundColor ? props.backgroundColor : "black"};
        fontsize: 20px;
        &.Tooltip-Tip.${props.direction || "top"}::before {
            border-${props.direction || "top"}-color: ${
        props.backgroundColor ? props.backgroundColor : "black"
    };
        }
    `;

    return (
        <div
            className="Tooltip-Wrapper"
            // When to show the tooltip
            onMouseEnter={showTip}
            onMouseLeave={hideTip}
        >
            {/* Wrapping */}
            {props.children}
            {active && (
                <TooltipDiv
                    className={`Tooltip-Tip ${props.direction || "top"}`}
                    style={{ display: props.disable ? "none" : "block" }}
                >
                    {/* Content */}
                    {props.content}
                </TooltipDiv>
            )}
        </div>
    );
};

export default Tooltip;
