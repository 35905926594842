import React from "react";

const HomeSidebarTopics = ({ active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.272"
      height="18.653"
      viewBox="0 0 15.272 18.653"
    >
      <g id="ft-2" transform="translate(-125 -96)">
        <path
          id="Path_40"
          data-name="Path 40"
          d="M221.618,96h-9.035a.585.585,0,0,0-.583.583V109a.585.585,0,0,0,.583.583h9.035A.585.585,0,0,0,222.2,109V96.583A.585.585,0,0,0,221.618,96Zm-7.111,4.955h3.031v1.166h-3.031Zm5.479,4.255h-5.479v-1.166h5.479Z"
          transform="translate(-81.929 0)"
          fill={active ? "#fff" : "#404040"}
        />
        <path
          id="Path_41"
          data-name="Path 41"
          d="M126.341,201.979V192h-.775a.565.565,0,0,0-.565.577v11.9a.57.57,0,0,0,.565.583h8.551a.565.565,0,0,0,.56-.583v-.758h-6.61A1.724,1.724,0,0,1,126.341,201.979Z"
          transform="translate(0 -90.404)"
          fill={active ? "#fff" : "#404040"}
        />
        <path
          id="Path_42"
          data-name="Path 42"
          d="M169.4,158.886V149h-.839a.567.567,0,0,0-.56.589v11.9a.552.552,0,0,0,.56.571h8.551a.565.565,0,0,0,.571-.571v-.828h-6.523A1.784,1.784,0,0,1,169.4,158.886Z"
          transform="translate(-40.493 -49.911)"
          fill={active ? "#fff" : "#404040"}
        />
      </g>
    </svg>
  );
};

export default HomeSidebarTopics;
