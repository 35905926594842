import React, { useEffect, useRef } from "react";
import HomeHeaderLinks from "../../../components/HomeComponents/HomeHeaderLinks/HomeHeaderLinks";
import Logo from "../../../components/CommonComponents/Logo/Logo";
import { useDispatch, useSelector } from "react-redux";
import { getLastLoginTimeHandler } from "../../../store/actions/actionHandlers/loginActionHandler";
import {
  getAllLanguagesHandler,
  getNotificationsHandler,
  setSelectedLanguageHandler,
} from "../../../store/actions/actionHandlers/homeActionHandler";
import addLangToLocalStorage from "../../../utils/addLangToLocalStorage";
import { useNavigate } from "react-router";
import { getUserProfileHandler } from "../../../store/actions/actionHandlers/userActionHandler";
import { getLeaderboardHandler } from "../../../store/actions/actionHandlers/homeActionHandler";
import classes from "./HomeHeader.module.scss";
import HomeSidebar from "../HomeSidebar/HomeSidebar";
import useComponentVisible from "../../../hooks/useComponentVisible";
import HamburgerIcon from "../../../components/CustomSvgIcons/HamburgerIcon";
import generalLogo from "../../../assets/images/logo/LTIMindtree-White.png";

const HomeHeader = ({ resethandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userState = useSelector((state) => state.userReducer);
  const loginState = useSelector((state) => state.loginReducer);
  const classState = useSelector((state) => state.classReducer);
  const homeState = useSelector((state) => state.homeReducer);

  const { notifications, allLanguages } = homeState;
  const { lastLoginTime } = loginState;
  const { postAssessmentScore } = classState;
  const { profile } = userState;

  const [sidebarRef, isSidebarVisible, setIsSidebar] = useComponentVisible();

  const tenantSlugLogoImage = JSON.parse(localStorage.getItem("themeData"))
    ?.CustomData?.LogoImage;
  const isDarkTheme = JSON.parse(
    localStorage.getItem("themeData")
  )?.IsDarkHeaderRequired;
  // const isDarkTheme = true
  const tenantSlugName = localStorage.getItem("tenantSlugName");

  const handleSidebar = () => {
    setIsSidebar(!isSidebarVisible);
  };

  useEffect(() => {
    dispatch(getLeaderboardHandler(navigate));
  }, [dispatch, postAssessmentScore, navigate]);

  useEffect(() => {
    if (profile === null) {
      dispatch(getUserProfileHandler());
    }
  }, [dispatch, profile]);
  useEffect(() => {
    if (lastLoginTime === null) {
      dispatch(getLastLoginTimeHandler(navigate));
    }
  }, [dispatch, lastLoginTime, navigate]);
  useEffect(() => {
    // if (notifications === null) {
    dispatch(getNotificationsHandler(navigate));
    // }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!!allLanguages === false) {
      dispatch(getAllLanguagesHandler());
    }
    if (
      localStorage.getItem("languageTerm") == null ||
      localStorage.getItem("language") == null
    ) {
      addLangToLocalStorage("en-GB", "English", dispatch);
    } else {
      dispatch(
        setSelectedLanguageHandler(
          localStorage.getItem("languageTerm")?.split("-")[0]
        )
      );
    }
  }, [dispatch, allLanguages]);

  const handleNavigate = () => {
    if (
      tenantSlugName?.toLowerCase() === "search" ||
      tenantSlugName?.toLowerCase() === "qaaadsep26" ||
      tenantSlugName?.toLowerCase() === "ltimindtree" ||
      tenantSlugName?.toLowerCase() === "cybersec"
    ) {
      window.open("https://ultimaworks.ltimindtree.com/");
    } else {
      navigate("/home");
      // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };
  const ref = useRef();
  return (
    <div
      className={
        isDarkTheme
          ? classes.homeHeader + " " + classes.homeHeaderDark
          : classes.homeHeader
      }
    >
      <div className={classes.homeHeader__logo}>
        <div
          className={classes.homeHeader__logo__hamburgurMenuHeader}
          // src={hamburgurMenuHeader}
          onClick={handleSidebar}
          // alt="menu"
        >
          <HamburgerIcon stroke={isDarkTheme && "#fff"} />
        </div>
        <div
          className={classes.homeHeader__logo__mainLogo}
          onClick={handleNavigate}
        >
          <Logo
            logoImage={tenantSlugLogoImage}
            className={classes.homeHeader__logo__mainLogo__image}
          />
        </div>
        {tenantSlugName?.toLowerCase() === "search" ||
        tenantSlugName?.toLowerCase() === "qaaadsep26" ||
        tenantSlugName?.toLowerCase() === "ltimindtree" ? (
          <div className={classes.homeHeader__logo__lightHouseLinkContainer}>
            <div className={classes.homeHeader__logo__pipe}>|</div>
            <div
              // href="https://ultimaworks.ltimindtree.com/"
              // target="__blank"
              onClick={() => navigate("/home")}
              className={classes.homeHeader__logo__link}
            >
              {/* ULTIMA WORKS */}
              <div className={classes.homeHeader__logo__link__primary}>
                Lighthouse
              </div>
              <div className={classes.homeHeader__logo__link__secondary}>
                Powered by
                <div
                  className={classes.homeHeader__logo__link__secondary__main}
                >
                  Shotclasses
                </div>
              </div>
              {/* <OpenInNewIcon /> */}
            </div>
          </div>
        ) : tenantSlugName?.toLowerCase() === "cybersec" ? (
          <div className={classes.homeHeader__logo__lightHouseLinkContainer}>
            <div className={classes.homeHeader__logo__pipe}>|</div>
            <div
              // href="https://ultimaworks.ltimindtree.com/"
              // target="__blank"
              onClick={() => navigate("/home")}
              className={classes.homeHeader__logo__link}
            >
              {/* ULTIMA WORKS */}
              <div className={classes.homeHeader__logo__link__primary}>
                Cyber Security
              </div>
              <div className={classes.homeHeader__logo__link__secondary}>
                Powered by
                <div
                  className={classes.homeHeader__logo__link__secondary__main}
                >
                  Shotclasses
                </div>
              </div>
              {/* <OpenInNewIcon /> */}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <HomeHeaderLinks
        resethandler={resethandler}
        setIsSidebar={setIsSidebar}
        isDarkTheme={isDarkTheme}
        ref={ref}
      />
      <HomeSidebar
        reference={sidebarRef}
        isSidebarVisible={isSidebarVisible}
        handleSidebar={handleSidebar}
      />
    </div>
  );
};

export default HomeHeader;
