import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import resetIcon from "../../../assets/images/reset_icon.png";
import smallMagnifyingGlass from "../../../assets/images/smallMagnifyingGlass.png";
import {
  setHomeFilterAction,
  setHomeFilterSelectedAction,
} from "../../../store/actions/actionCreators/homeActionCreator";
import classes from "./HomeNavbarFilter.module.scss";
import { FormattedMessage, injectIntl } from "react-intl";

const HomeNavbarFilter = ({ reference, intl }) => {
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state.homeReducer);
  const { homeFilter, homeFilterSelected } = homeState;

  const [searchTrainingType, setSearchTrainingType] = useState("");
  const [searchTrainingTypesList, setSearchTrainingTypesList] = useState([]);
  const [trainingTypesList, setTrainingTypesList] = useState([]);
  const [sortBySeriesSelected, setSortBySeriesSelected] = useState(false);
  useEffect(() => {
    // homeFilter?.sortBy?.forEach((sort) => {
    if (homeFilterSelected?.sortBy === "Series") {
      setSortBySeriesSelected(true);
    } else if (homeFilterSelected?.sortBy !== "Series") {
      setSortBySeriesSelected(false);
    }
    // });
  }, [homeFilterSelected?.sortBy]);

  useEffect(() => {
    setTrainingTypesList(homeFilter?.filterByType);
  }, [homeFilter?.filterByType]);
  useEffect(() => {
    const filteredTrainingTypeArray = homeFilter?.filterByType?.filter((type) =>
      type.Name.toLowerCase().includes(searchTrainingType.toLowerCase())
    );
    if (searchTrainingType.length > 0) {
      setSearchTrainingTypesList(filteredTrainingTypeArray);
    } else {
      setSearchTrainingTypesList(homeFilter?.filterByType);
    }
  }, [searchTrainingType, homeFilter?.filterByType]);

  useEffect(() => {
    let count = 0;
    homeFilter?.sortByStatus?.forEach((status) => {
      if (status.isSelected === false) {
        count++;
      }
    });
    if (count === 4) {
      const updatedSortByStatusArr = homeFilter?.sortByStatus?.map(
        (type, index) => {
          if (index === 0) {
            return { ...type, isSelected: true };
          } else {
            return { ...type, isSelected: false };
          }
        }
      );
      dispatch(
        setHomeFilterAction({
          ...homeFilter,
          sortByStatus: updatedSortByStatusArr,
        })
      );
      dispatch(
        setHomeFilterSelectedAction({
          ...homeFilterSelected,
          sortByStatus: "All Courses",
        })
      );
    }
  }, [dispatch, homeFilter, homeFilterSelected]);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      setSearchTrainingType(value);
    } else {
      setSearchTrainingType("");
    }
  };

  const handleSortBy = (name) => {
    // const updatedSortByArr = homeFilter?.sortBy?.map((sort) => {
    //   if (sort?.id === id) {
    //     return { ...sort, isSelected: true };
    //   } else {
    //     return { ...sort, isSelected: false };
    //   }
    // });
    const updatedSortByStatusArr = homeFilter?.sortByStatus?.map(
      (type, index) => {
        if (index === 0) {
          return { ...type, isSelected: true };
        } else {
          return { ...type, isSelected: false };
        }
      }
    );
    if (name === "Series") {
      dispatch(
        setHomeFilterSelectedAction({
          sortBy: name,
          sortByStatus: "All Courses",
          filterByType: "All",
        })
      );
      dispatch(
        setHomeFilterAction({
          ...homeFilter,
          sortByStatus: updatedSortByStatusArr,
        })
      );
    } else {
      dispatch(
        setHomeFilterSelectedAction({ ...homeFilterSelected, sortBy: name })
      );
    }
  };

  const handleSortByStatus = (name) => {
    const updatedSortByStatusArr = homeFilter?.sortByStatus?.map((sort) => {
      if (name === "All Courses") {
        if (sort?.name === name) {
          return { ...sort, isSelected: true };
        } else {
          return { ...sort, isSelected: false };
        }
      } else if (sort?.name === "All Courses" && sort?.isSelected) {
        return { ...sort, isSelected: false };
      } else if (sort?.name === name) {
        if (sort?.isSelected) {
          return { ...sort, isSelected: false };
        } else {
          return { ...sort, isSelected: true };
        }
      } else {
        return sort;
      }
    });
    dispatch(
      setHomeFilterAction({
        ...homeFilter,
        sortByStatus: updatedSortByStatusArr,
      })
    );
    dispatch(
      setHomeFilterSelectedAction({ ...homeFilterSelected, sortByStatus: name })
    );
  };
  const handleFilterByType = (name) => {
    // const updatedFilterByTypeArr = homeFilter?.filterByType?.map((type) => {
    //   if (type?.TypeId === id) {
    //     return { ...type, isSelected: true };
    //   } else {
    //     return { ...type, isSelected: false };
    //   }
    // });
    // dispatch(
    //   setHomeFilterAction({
    //     ...homeFilter,
    //     filterByType: updatedFilterByTypeArr,
    //   })
    // );
    dispatch(
      setHomeFilterSelectedAction({ ...homeFilterSelected, filterByType: name })
    );
  };

  const handleReset = () => {
    setSearchTrainingType("");
    const updatedSortByArr = homeFilter?.sortBy?.map((type, index) => {
      if (index === 0) {
        return { ...type, isSelected: true };
      } else {
        return { ...type, isSelected: false };
      }
    });
    const updatedSortByStatusArr = homeFilter?.sortByStatus?.map(
      (type, index) => {
        if (index === 0) {
          return { ...type, isSelected: true };
        } else {
          return { ...type, isSelected: false };
        }
      }
    );
    const updatedFilterByTypeArr = homeFilter?.filterByType?.map(
      (type, index) => {
        if (index === 0) {
          return { ...type, isSelected: true };
        } else {
          return { ...type, isSelected: false };
        }
      }
    );

    dispatch(
      setHomeFilterAction({
        sortBy: updatedSortByArr,
        sortByStatus: updatedSortByStatusArr,

        filterByType: updatedFilterByTypeArr,
      })
    );
    dispatch(
      setHomeFilterSelectedAction({
        sortBy: "All",
        sortByStatus: "All Courses",
        filterByType: "All",
      })
    );
  };
  return (
    <>
      <div ref={reference} className={classes.homeNavbarFilter}>
        <div className={classes.homeNavbarFilter__header}>
          <h3 className={classes.homeNavbarFilter__header__heading}>
            <FormattedMessage id="filter.Filters" />
          </h3>
          <div
            className={classes.homeNavbarFilter__header__reset}
            onClick={handleReset}
          >
            <img src={resetIcon} alt="Reset" />
            <FormattedMessage id="filter.reset" />
          </div>
        </div>
        <div className={classes.homeNavbarFilter__sortBy}>
          <div className={classes.homeNavbarFilter__sortBy__heading}>
            <FormattedMessage id="filter.SortBy" />:
          </div>
          <ul className={classes.homeNavbarFilter__sortBy__sortList}>
            {homeFilter?.sortBy?.map((sort) => {
              return (
                <li
                  className={classes.homeNavbarFilter__sortBy__sortList__item}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSortBy(sort?.name);
                  }}
                  key={sort?.id}
                >
                  <label className="custom-radio">
                    {sort?.name == "All" ? (
                      <FormattedMessage id="home.all" />
                    ) : sort?.name == "Name" ? (
                      <FormattedMessage id="home.name" />
                    ) : sort?.name == "Classes" ? (
                      <FormattedMessage id="topics.classes" />
                    ) : sort?.name == "Series" ? (
                      <FormattedMessage id="home.series" />
                    ) : (
                      ""
                    )}
                    <input
                      type="radio"
                      checked={sort?.name === homeFilterSelected?.sortBy}
                      onChange={(e) => {
                        e.preventDefault();
                        handleSortBy(sort?.name);
                      }}
                    />
                    <span className="checkmark"></span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
        {sortBySeriesSelected === false && (
          <>
            <div className={classes.homeNavbarFilter__sortByStatus}>
              <div className={classes.homeNavbarFilter__sortByStatus__heading}>
                <FormattedMessage id="filter.sortByStatus" />:
              </div>
              <ul className={classes.homeNavbarFilter__sortByStatus__sortList}>
                {homeFilter?.sortByStatus?.map((sort) => {
                  return (
                    <li
                      key={sort?.id}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSortByStatus(sort?.name);
                      }}
                      className={
                        classes.homeNavbarFilter__sortByStatus__sortList__item
                      }
                    >
                      <label className="custom-checkbox">
                        {sort?.name == "All Courses" ? (
                          <FormattedMessage id="filter.AllCourses" />
                        ) : sort?.name == "In-Progress" ? (
                          <FormattedMessage id="filter.InProgress" />
                        ) : sort?.name == "Not Started" ? (
                          <FormattedMessage id="filter.NotStarted" />
                        ) : sort?.name == "Completed" ? (
                          <FormattedMessage id="filter.completed" />
                        ) : (
                          ""
                        )}
                        <input
                          type="checkbox"
                          checked={sort?.isSelected}
                          onChange={(e) => {
                            e.preventDefault();
                            handleSortByStatus(sort?.name);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
            {trainingTypesList.length <= 2 ? (
              <></>
            ) : (
              <div className={classes.homeNavbarFilter__filterByType}>
                <div className={classes.homeNavbarFilter__filterByType__header}>
                  <h3
                    className={
                      classes.homeNavbarFilter__filterByType__header__heading
                    }
                  >
                    <FormattedMessage id="filter.FilterByType" />:
                  </h3>

                  <div
                    className={
                      classes.homeNavbarFilter__filterByType__header__search
                    }
                  >
                    <img src={smallMagnifyingGlass} alt="Category" />
                    <input
                      type="text"
                      value={searchTrainingType}
                      placeholder={intl.formatMessage({
                        id: `contact.category`,
                      })}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                {searchTrainingType.length > 0 ? (
                  <ul
                    className={
                      classes.homeNavbarFilter__filterByType__filterList
                    }
                  >
                    {searchTrainingTypesList?.map((type) => {
                      return (
                        <li
                          key={type?.TypeId}
                          className={
                            classes.homeNavbarFilter__filterByType__filterList__item
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            handleFilterByType(type?.Name);
                          }}
                        >
                          <label className="custom-radio">
                            {type?.Name === "All" ? (
                              <FormattedMessage id="home.all" />
                            ) : (
                              type?.Name
                            )}
                            <input
                              type="radio"
                              checked={
                                type?.Name === homeFilterSelected?.filterByType
                              }
                              onChange={(e) => {
                                e.preventDefault();
                                handleFilterByType(type?.Name);
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <ul
                    className={
                      classes.homeNavbarFilter__filterByType__filterList
                    }
                  >
                    {trainingTypesList?.map((type) => {
                      return (
                        <li
                          key={type?.TypeId}
                          className={
                            classes.homeNavbarFilter__filterByType__filterList__item
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            handleFilterByType(type?.Name);
                          }}
                        >
                          <label className="custom-radio">
                            {type?.Name === "All" ? (
                              <FormattedMessage id="home.all" />
                            ) : (
                              type?.Name
                            )}
                            <input
                              type="radio"
                              checked={
                                type?.Name === homeFilterSelected?.filterByType
                              }
                              onChange={(e) => {
                                e.preventDefault();
                                handleFilterByType(type?.Name);
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <div className={classes.homeNavbarOpaqueLayer}></div>
    </>
  );
};

export default injectIntl(HomeNavbarFilter);
