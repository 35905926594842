import classes from "./QuizTimer.module.scss";
import timerIcon from "../../../assets/images/QuizImageAndIcons/timer_icon.svg";
import React from "react";
import { connect } from "react-redux";
import {
    setTimerDialog,
    setSessionDialog,
    startQuizTimer,
    endQuizTimer,
    endQuizCounter,
    startQuizCounter,
} from "../../../store/actions/actionCreators/quizActionCreator";
let startcount = 0,
    endcount = 0;
class QuizTimer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            running: false,
            currentTimeHr: 0,
            currentTimeMs: 0,
            currentTimeSec: 0,
            currentTimeMin: 0,
            totalQDuration: 0,
            watch: 0,
        };
    }
    componentDidMount() {
        endcount = 0;
        startcount = 0;
    }

    formatTime = (val, ...rest) => {
        let value = val.toString();
        if (value.length < 2) {
            value = "0" + value;
        }
        if (rest[0] === "ms" && value.length < 3) {
            value = "0" + value;
        }
        return value;
    };

    start = () => {
        if (
            localStorage.getItem("resumeTimeMin") !== null &&
            this.state.currentTimeMs === 0
        ) {
            this.setState({
                currentTimeMin: localStorage.getItem("resumeTimeMin"),
            });
            localStorage.setItem(
                "currentTimeMin",
                localStorage.getItem("resumeTimeMin")
            );
        }
        if (
            localStorage.getItem("resumeTimeHr") !== null &&
            this.state.currentTimeMs === 0
        ) {
            this.setState({
                currentTimeHr: localStorage.getItem("resumeTimeHr"),
            });
            localStorage.setItem(
                "currentTimeHr",
                localStorage.getItem("resumeTimeHr")
            );
        }
        if (
            localStorage.getItem("resumeTimeSec") !== null &&
            this.state.currentTimeMs === 0
        ) {
            this.setState({
                currentTimeSec: localStorage.getItem("resumeTimeSec"),
            });
            localStorage.setItem(
                "currentTimeSec",
                localStorage.getItem("resumeTimeSec")
            );
        }
        if (
            localStorage.getItem("resumeTimeMs") !== null &&
            this.state.currentTimeMs === 0
        ) {
            this.setState({
                currentTimeMs: localStorage.getItem("resumeTimeMs"),
            });
            localStorage.setItem(
                "currentTimeMs",
                localStorage.getItem("resumeTimeMs")
            );
        }

        if (!this.state.running) {
            this.setState({ running: true });
            this.watch = setInterval(() => this.pace(), 10);
        }
    };

    stop = () => {
        this.setState({ running: false });
        clearInterval(this.watch);
    };

    pace = () => {
        if (this.state.running) {
            this.setState({
                currentTimeMs: Number(this.state.currentTimeMs) + 10,
            });
            localStorage.setItem("currentTimeMs", this.state.currentTimeMs);

            if (this.state.currentTimeMs >= 1000) {
                this.setState({
                    currentTimeSec: Number(this.state.currentTimeSec) + 1,
                });
                this.setState({ currentTimeMs: 0 });
                localStorage.setItem(
                    "currentTimeSec",
                    this.state.currentTimeSec
                );
            }
            if (this.state.currentTimeSec >= 60) {
                this.setState({
                    currentTimeMin: Number(this.state.currentTimeMin) + 1,
                });
                this.setState({ currentTimeSec: 0 });
                localStorage.setItem(
                    "currentTimeMin",
                    this.state.currentTimeMin
                );
            }
            if (this.state.currentTimeMin >= 60) {
                this.setState({
                    currentTimeHr: Number(this.state.currentTimeHr) + 1,
                });
                this.setState({ currentTimeMin: 0 });
                localStorage.setItem("currentTimeHr", this.state.currentTimeHr);
            }
        }
    };
    reset = () => {
        this.setState({
            currentTimeHr: 0,
            currentTimeMs: 0,
            currentTimeSec: 0,
            currentTimeMin: 0,
        });
    };

    componentDidUpdate() {
        if (this.props.startTimer && this.props.startQCounter === 0) {
            startcount++;
            this.props.startQuizCounter();
            this.start();
        }
        if (this.props.endTimer && this.props.endQCounter === 0) {
            endcount++;
            this.props.endQuizCounter();
            this.stop();
        }
        if (this.props.isReset) {
            this.reset();
        }
    }

    render() {
        return (
            <div className={classes.quizTimer}>
                <img src={timerIcon} alt="timerIcon" />
                <p className={classes.quizTimerText}>
                    <span className={classes.timerText}>Quiz Timer</span>
                    <span className={classes.timer}>
                        {this.formatTime(this.state.currentTimeHr)}:
                        {this.formatTime(this.state.currentTimeMin)}:
                        {this.formatTime(this.state.currentTimeSec)}
                    </span>
                </p>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    timereset: state.quizReducer.timerreset,
    startTimer: state.quizReducer.vQuizStarted,
    endTimer: state.quizReducer.vQuizEnded,
    startQCounter: state.quizReducer.startQCounter,
    endQCounter: state.quizReducer.endQCounter,
});

const mapDispatchToProps = (dispatch) => ({
    setTimerDialog: (value) => {
        dispatch(setTimerDialog(value));
    },
    setSessionDialog: (value) => {
        dispatch(setSessionDialog(value));
    },
    startQuizTimer: () => {
        dispatch(startQuizTimer());
    },
    endQuizTimer: () => {
        dispatch(endQuizTimer());
    },
    endQuizCounter: () => {
        dispatch(endQuizCounter());
    },
    startQuizCounter: () => {
        dispatch(startQuizCounter());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizTimer);

// import React, { useEffect, useState } from "react";
// import classes from "./QuizTimer.module.scss";
// import timerIcon from "../../../assets/images/QuizImageAndIcons/timer_icon.svg";
// import { useDispatch, useSelector } from "react-redux";
// import {
//     endQuizCounter,
//     startQuizCounter,
// } from "../../../store/actions/actionCreators/quizActionCreator";

// const QuizTimer = () => {
//     const quizState = useSelector((state) => state.quizReducer);
//     const { vQuizStarted, vQuizEnded, startQCounter, endQCounter } = quizState;
//     const dispatch = useDispatch();
//     const [running, setrunning] = useState(false);
//     const [currentTimeHr, setcurrentTimeHr] = useState(0);
//     const [currentTimeMin, setcurrentTimeMin] = useState(0);
//     const [currentTimeSec, setcurrentTimeSec] = useState(0);
//     const [currentTimeMs, setcurrentTimeMs] = useState(0);
//     const [watch, setwatch] = useState(0);
//     let startcount = 0;
//     let endcount = 0;

//     useEffect(() => {
//         console.log("started");
//         if (vQuizStarted && startQCounter === 0) {
//             startcount++;
//             dispatch(startQuizCounter());
//             start();
//         }
//         if (vQuizEnded && endQCounter === 0) {
//             endcount++;
//             dispatch(endQuizCounter());
//             stop();
//         }
//     });
//     const formatTime = (val, ...rest) => {
//         let value = val.toString();
//         if (value.length < 2) {
//             value = "0" + value;
//         }
//         if (rest[0] === "ms" && value.length < 3) {
//             value = "0" + value;
//         }
//         return value;
//     };
//     const start = () => {
//         if (
//             localStorage.getItem("resumeTimeMin") !== null &&
//             currentTimeMs === 0
//         ) {
//             setcurrentTimeMin(localStorage.getItem("resumeTimeMin"));
//             localStorage.setItem(
//                 "currentTimeMin",
//                 localStorage.getItem("resumeTimeMin")
//             );
//         }
//         if (
//             localStorage.getItem("resumeTimeHr") !== null &&
//             currentTimeMs === 0
//         ) {
//             setcurrentTimeHr(localStorage.getItem("resumeTimeHr"));
//             localStorage.setItem(
//                 "currentTimeHr",
//                 localStorage.getItem("resumeTimeHr")
//             );
//         }
//         if (
//             localStorage.getItem("resumeTimeSec") !== null &&
//             currentTimeMs === 0
//         ) {
//             setcurrentTimeSec(localStorage.getItem("resumeTimeSec"));
//             localStorage.setItem(
//                 "currentTimeSec",
//                 localStorage.getItem("resumeTimeSec")
//             );
//         }
//         if (
//             localStorage.getItem("resumeTimeMs") !== null &&
//             currentTimeMs === 0
//         ) {
//             setcurrentTimeMs(localStorage.getItem("resumeTimeMs"));
//             localStorage.setItem(
//                 "currentTimeMs",
//                 localStorage.getItem("resumeTimeMs")
//             );
//         }

//         if (!running) {
//             console.log("in start");
//             setrunning(true);
//             setwatch(() => setInterval(pace, 10));
//         }
//     };
//     const stop = () => {
//         setrunning(false);
//         clearInterval(watch);
//     };
//     const pace = () => {
//         if (running) {
//             console.log("running");
//             setcurrentTimeMs(Number(currentTimeMs) + 10);
//             localStorage.setItem("currentTimeMs", currentTimeMs);

//             if (currentTimeMs >= 1000) {
//                 console.log("currentTimeMs", currentTimeMs);
//                 setcurrentTimeSec(Number(currentTimeSec) + 1);
//                 setcurrentTimeMs(0);
//                 localStorage.setItem("currentTimeSec", currentTimeSec);
//             }
//             if (currentTimeSec >= 60) {
//                 console.log("currentTimeSec", currentTimeSec);
//                 setcurrentTimeMin(Number(currentTimeMin) + 1);
//                 setcurrentTimeSec(0);
//                 localStorage.setItem("currentTimeMin", currentTimeMin);
//             }
//             if (currentTimeMin >= 60) {
//                 console.log("currentTimeMin", currentTimeSec);
//                 setcurrentTimeHr(Number(currentTimeHr) + 1);
//                 setcurrentTimeMin(0);
//                 localStorage.setItem("currentTimeHr", currentTimeHr);
//             }
//         }
//     };
//     const reset = () => {
//         setcurrentTimeHr(0);
//         setcurrentTimeMin(0);
//         setcurrentTimeSec(0);
//         setcurrentTimeMs(0);
//     };

//     return (
//         <div className={classes.quizTimer}>
//             <img src={timerIcon} alt="timerIcon" />
//             <p className={classes.quizTimerText}>
//                 <span className={classes.timerText}>Quiz Timer</span>
//                 <span className={classes.timer}>
//                     {formatTime(currentTimeHr)}:{formatTime(currentTimeMin)}:
//                     {formatTime(currentTimeSec)}
//                 </span>
//             </p>
//         </div>
//     );
// };

// export default QuizTimer;
