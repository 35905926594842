import messages_de from "./translations/de.json";
import messages_en from "./translations/en.json";
import messages_ar from "./translations/ar.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";
import messages_es from "./translations/es.json";
import messages_ko from "./translations/ko.json";
import messages_ja from "./translations/ja.json";
import messages_zh from "./translations/zh.json";
import messages_zht from "./translations/zht.json";

const messages = {
  de: messages_de,
  en: messages_en,
  ar: messages_ar,
  pt: messages_pt,
  fr: messages_fr,
  es: messages_es,
  ko: messages_ko,
  ja: messages_ja,
  zh: messages_zh,
  "zh-Hant-HK": messages_zht,
};
export default messages;
