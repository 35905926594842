import React from 'react';
import classes from './ToggleSwitch.module.scss';
import {FormattedMessage} from "react-intl";
const ToggleSwitch = (props) => {
    function handleOnSwitchChange(event) {
        props.onSwitchChange(event);
    }

    return(
        <label className={`${classes.switch} ${props.className}`}>
            <input type="checkbox" id="togBtn" onChange={handleOnSwitchChange} checked={props.checked}/>
                <div className={`${classes.slider} ${classes.round}`}>
                <span className={classes.on}><FormattedMessage id="account.on"/></span>
                <span className={classes.off}><FormattedMessage id="account.off"/></span>
            </div>
        </label>
        )
}
export default ToggleSwitch;