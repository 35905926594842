import React from "react";
import Button from "../Button/Button";
import classes from "./SessionExpiredModal.module.scss";
const SessionExpiredModal = ({ closeModal }) => {
  return (
    <div onClick={closeModal} className="modal_sessionExpired modal_wrapper">
      <div
        className="modal_dialog"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal_content">
          <div className="modal_body">
            <div className={classes.sessionExpiredModal}>
              <div className={classes.sessionExpiredModal__subHeader}>
                Your session has expired.
              </div>
              <div className={classes.sessionExpiredModal__buttonContainer}>
                <Button
                  onClick={closeModal}
                  type="submit"
                  label="OK"
                  className={
                    classes.sessionExpiredModal__buttonContainer__primaryThemeButton
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionExpiredModal;
