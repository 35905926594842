import { SHOTCLASS_API_URL } from "../../../utils/devApiContants";
import * as globalSearchActionCreator from "../actionCreators/globalSearchActionCreator";
import * as commonActionCreator from "../actionCreators/commonActionCreator";
import { setLoadingHandler, unSetLoadingHandler } from "./errorActionHandler";

export const getSearchResult = (searchText, isEnabled, isLoad) => async (dispatch,getState) => {
    let isAdvanceSearch = getState().commonReducer.isAdvanceSearch;
    let isDropdownText = getState().commonReducer.isDropdownText;
    dispatch(globalSearchActionCreator.updateDidYouMeanSuggestions([]));
    if (isLoad){
        dispatch(setLoadingHandler());
        dispatch(globalSearchActionCreator.requestAPI());
    } 
    let url;
    if (isAdvanceSearch) {
        url = SHOTCLASS_API_URL + '/shotclasses/globalsearchSections?searchText=' + searchText + '&isTopicEnabled=' + isEnabled + '&IsAdvanceSearch=' + isAdvanceSearch + '&isDropdownText=' + isDropdownText + '';
    } else {
        url = SHOTCLASS_API_URL + '/shotclasses/globalsearchSections?searchText=' + searchText + '&isTopicEnabled=' + isEnabled + '';
    }
    await fetch(url, {
        method: "GET",
        headers: JSON.parse(localStorage.getItem("headers")),
    })
    .then(response => response.json())
    .then((json) => {
        if (json.SemanticGlobalSearchResults.length > 0) {
            json.SemanticGlobalSearchResults = json.SemanticGlobalSearchResults.filter(x => x.GlobalSearch !== null)
        }
        dispatch(globalSearchActionCreator.getSearchResultDone(json.SemanticGlobalSearchResults));
        dispatch(commonActionCreator.toggleDropdownText(false));
        dispatch(globalSearchActionCreator.updateSearchText(json.queryString));
        // console.log('current stateG:', getState());

        if (json.isDidyouMeanSectionRequired) {
            dispatch(globalSearchActionCreator.updateDidYouMeanSuggestions(json.suggestions));
            console.log('current stateG:', getState());
        } else {
            dispatch(globalSearchActionCreator.updateDidYouMeanSuggestions([]));
        }
    })
    .catch(error => {
        dispatch(globalSearchActionCreator.catchError(error))
        dispatch(commonActionCreator.toggleDropdownText(false));
        console.log('Global Search Error ' + error);
    }).finally(() => {
        dispatch(unSetLoadingHandler());
    });
}