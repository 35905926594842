import actionTypes from "../actions/actionTypes";

const initialState = {
  profile: null,
  generalQueries: [],
  loading: false,
  notifications: null,
  allNotification: [],
  notificationSettings: [],
  notify: true,
  getConsolidatedReportReducer: [],
  getSkilledClassesReducer: [],
  getMySkillsReducer: [],
  getMyCertificatesReducer: [],
  getUserBadgesReducer: [],
};

const accountReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.errorTypes.API_ERROR:
      return { ...state, loading: false, error: payload };
    case actionTypes.notificationTypes.GET_BELL_NOTIFICATIONS:
      return { ...state, loading: false, notifications: payload };
    case actionTypes.notificationTypes.GET_ALL_BELL_NOTIFICATIONS:
      return { ...state, loading: false, allNotification: payload };
    case actionTypes.notificationTypes.GET_NOTIFICATION_SETTINGS:
      return { ...state, notificationSettings: payload };
    case actionTypes.notificationTypes.POST_NOTIFICATION_SETTINGS:
      return { ...state, notify: payload };
    case actionTypes.reports.REPORT_GET_CONSOLIDATEDREPORT:
      return { ...state, getConsolidatedReportReducer: payload };
    case actionTypes.reports.REPORT_GET_SKILLEDCLASSES:
      return { ...state, getSkilledClassesReducer: payload };
    case actionTypes.reports.REPORT_GET_MYSKILLS:
      return { ...state, getMySkillsReducer: payload };
    case actionTypes.reports.REPORT_GET_MYCERTIFICATES:
      return { ...state, getMyCertificatesReducer: payload };
    case actionTypes.reports.REPORT_GET_USERBADGES:
      return { ...state, getUserBadgesReducer: payload };
    default:
      return state;
  }
};

export default accountReducer;
