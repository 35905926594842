import React from "react";
import generalLogo from "../../../assets/images/Shotclasses_General_Logo.png";
import classes from "./Logo.module.scss";
const Logo = ({ className, logoImage, width, height }) => {
  return (
    <>
      {!!logoImage ? (
        <img
          src={logoImage}
          width={width}
          height={height}
          className={`logoImage ${className}`}
          alt="Logo"
        />
      ) : (
        <img src={generalLogo} className="logoImage" alt="Header Logo" />
      )}
    </>
  );
};

export default Logo;
