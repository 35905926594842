import React, { useEffect, useState } from "react";
import smallMagnifyingGlass from "../../../assets/images/smallMagnifyingGlass.png";
import classes from "./HomeHeaderLanguageChange.module.scss";
import addLangToLocalStorage from "../../../utils/addLangToLocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage ,injectIntl } from "react-intl";

const HomeHeaderLanguageChange = ({ reference, handleClickLanguagePopUp,intl }) => {
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state.homeReducer);
  const { allLanguages } = homeState;
  const [lang, setLang] = useState(allLanguages);
  const [selectedLang, setSelectedLang] = useState(null);

  useEffect(() => {
    if (selectedLang === null) {
      allLanguages?.forEach((language) => {
        if (language.Value.includes(localStorage.getItem("language"))) {
          setSelectedLang(language);
        }
      });
    }
  }, [allLanguages, selectedLang]);

  const handleChange = (e) => {
    const value = e.target.value;
    const filteredLangArray = allLanguages?.filter((lang) => {
      if (lang.Value.toLowerCase().includes(value.toLowerCase())) {
        return lang;
      } else {
        return "";
      }
    });
    if (value.length > 0) {
      setLang(filteredLangArray);
    } else {
      setLang(allLanguages);
    }
  };

  const handleClick = (lang) => {
    setSelectedLang(lang);
  };

  const handleSubmit = (lang) => {
    addLangToLocalStorage(lang.Locale, lang.Value, dispatch);
    handleClickLanguagePopUp();
  };

  return (
    <div ref={reference} className={classes.homeHeaderLanguage}>
      <div className={classes.homeHeaderLanguage__heading}>
        <FormattedMessage id="credits.global" />{" "}
        <FormattedMessage id="account.language" />{" "}
      </div>
      <div className={classes.homeHeaderLanguage__searchBox}>
        <img
          className={classes.homeHeaderLanguage__searchBox__image}
          src={smallMagnifyingGlass}
          alt="language search box"
        />
        <input
          className={classes.homeHeaderLanguage__searchBox__inputField}
          type="text"
          placeholder={
          intl.formatMessage({
            id:`lang.searchLanguage`,
          })}
          onChange={handleChange}
        />
      </div>
      <ul className={classes.homeHeaderLanguage__list}>
        {lang?.map((language) => {
          if (
            language?.Value !== "Elliniká" &&
            language?.Value !== "Polski" &&
            language?.Value !== "Português-Brasil" &&
            language?.Value !== "Italiano" &&
            language?.Value !== "Nederlands" &&
            language?.Value !== "Româna"
          ) {
            return (
              <li
                key={language?.Id}
                onClick={() => handleClick(language)}
                className={classes.homeHeaderLanguage__list__item}
              >
                <input
                  type="radio"
                  onChange={() => handleClick(language)}
                  checked={selectedLang?.Locale === language?.Locale}
                  className={classes.homeHeaderLanguage__list__check}
                />
                <p className={classes.homeHeaderLanguage__list__language}>
                  {language?.Value}
                </p>
              </li>
            );
          }
        })}
      </ul>
      <div className={classes.homeHeaderLanguage__buttonArea}>
        <button
          className={classes.homeHeaderLanguage__button}
          onClick={() => handleSubmit(selectedLang)}
        >
          <FormattedMessage id="home.apply" />
        </button>
      </div>
    </div>
  );
};

export default injectIntl(HomeHeaderLanguageChange);
