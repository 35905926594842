import React, { useState, useEffect } from "react";
import HomeNavbarLink from "../../../components/HomeComponents/HomeNavbarLink/HomeNavbarLink";
import filterLogo from "../../../assets/images/homeIcons/filterIcon.svg";
import backLogo from "../../../assets/images/homeIcons/backIcon.svg";
import homeNavLogo from "../../../assets/images/homeIcons/homeIcon.svg";
import langIcon from "../../../assets/images/homeIcons/langIcon.svg";
import Logo from "../../../components/CommonComponents/Logo/Logo";
import resetIcon from "../../../assets/images/reset_icon.png";
import smallMagnifyingGlass from "../../../assets/images/smallMagnifyingGlass.png";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import FavouriteIconBig from "../../../components/CustomSvgIcons/FavouriteIconBig";
import ArrowNextIcon from "../../../components/CustomSvgIcons/ArrowNextIcon";
import { FormattedMessage, injectIntl } from "react-intl";
import classes from "./HomeNavbar.module.scss";
import { setCreditStateTypeAction } from "../../../store/actions/actionCreators/creditActionCreator";
import useComponentVisible from "../../../hooks/useComponentVisible";
import FilterCredit from "../../../components/FilterCreditComponents/FilterCredit";
import useWindowWidthLessThan from "../../../hooks/useWindowWidthLessThan";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QuizTimer from "../../../components/VirtualOrLiveQuizComponents/QuizTimer/QuizTimer";
import HomeNavbarFilter from "../../../components/HomeComponents/HomeNavbarFilter/HomeNavbarFilter";
import actionTypes from "../../../store/actions/actionTypes";
import { userScoreObjCreation } from "../../../utils/commonQuizFunction";
import { postVQAssessScore } from "../../../store/actions/actionHandlers/quizActionHandler";
import { endQuizTimer } from "../../../store/actions/actionCreators/quizActionCreator";
import * as globalSearchActionCreator from "../../../store/actions/actionCreators/globalSearchActionCreator";

const HomeNavbar = (props, intl) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const homeState = useSelector((state) => state.homeReducer);
  const classState = useSelector((state) => state.classReducer);
  const creditState = useSelector((state) => state.creditReducer);
  const commonState = useSelector((state) => state.commonReducer);
  const globalSearchState = useSelector((state) => state.globalSearchReducer);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const topicsState = useSelector((state) => state.topicsReducer);
  const favoriteState = useSelector((state) => state.favoriteReducer);
  const { isFavoriteAvailable } = favoriteState;
  const { subTopics, topicDetails } = topicsState;
  const {
    themeStyles,
    assignedClasses,
    inProgressClasses,
    trendingClasses,
    completedClasses,
    notInProgressClasses,
    assignedSeries,
    trendingSeries,
    completedSeries,
    inProgressSeries,
    notInProgressSeries,
  } = homeState;
  const { classDetails, currentView } = classState;
  const { creditStateType } = creditState;

  const [filterRef, isFilterVisible, setIsFilterVisible] =
    useComponentVisible();

  const [globalFilterRef, isGlobalFilterVisible, setIsGlobalFilterVisible] =
    useComponentVisible();

  const [customCheckBoxRef, show, setShow] = useComponentVisible();

  const customCheckBoxHandleClick = () => {
    setShow(!show);
  };

  const quizState = useSelector((state) => state.quizReducer);
  const { quizData, virtualQuizData } = quizState;

  const handleClickGlobalFilterPopUp = () => {
    setIsGlobalFilterVisible(!isGlobalFilterVisible);
  };

  useEffect(() => {
    return () => {
      dispatch(globalSearchActionCreator.updateSearchText("")); // did you means set as []
    };
  }, []);

  const handleClickLanguagePopUp = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const [contentLangRef, isContentLangVisible, setIsContentLangVisible] =
    useComponentVisible();
  const [homeFilterRef, isHomeFilterVisible, setIsHomeFilterVisible] =
    useComponentVisible();

  const handleClickHomeFilterPopUp = () => {
    setIsHomeFilterVisible(!isHomeFilterVisible);
  };

  const handleClickContentLangPopUp = () => {
    setIsContentLangVisible(!isContentLangVisible);
  };

  const tenantData = JSON.parse(localStorage.getItem("tenantData"));
  const ToggleClass = () => {
    if (creditStateType === 1) {
      dispatch(setCreditStateTypeAction(2));
    } else {
      dispatch(setCreditStateTypeAction(1));
    }
  };
  const isWidthLessThan = useWindowWidthLessThan(600);
  const accessScoreCall = () => {
    const userScoreObj = userScoreObjCreation(
      false,
      false,
      quizData,
      virtualQuizData
    );
    dispatch(postVQAssessScore(userScoreObj, userScoreObj.ClassId));
  };
  const onHomeClick = () => {
    if (localStorage.getItem("quizSubmit") === "false") {
      accessScoreCall();
    }
    dispatch(endQuizTimer());
    dispatch({
      type: actionTypes.quizTypes.VALIDATE_PASSCODE,
      payload: null,
    });
    dispatch({
      type: actionTypes.quizTypes.virtualQuiz.FETCH_QUIZ_DETAILS,
      payload: null,
    });
    dispatch({
      type: actionTypes.quizTypes.liveQuiz.SET_LIVE_QUIZ_SCREEN_VIEW,
      payload: null,
    });
  };

  const accountReports = () => {
    navigate("/account/myaccount", {
      state: {
        assignedType: 2,
      },
    });
  };
  return isWidthLessThan && location.pathname === "/home" ? (
    (assignedClasses?.length > 0 ||
      trendingClasses?.length > 0 ||
      completedClasses?.length > 0 ||
      assignedSeries?.length > 0 ||
      trendingSeries?.length > 0 ||
      completedSeries?.length > 0) && (
      <div className={classes.homeNavbar__filter}>
        <div className={classes.tooltip} onClick={handleClickHomeFilterPopUp}>
          <Logo logoImage={filterLogo} />
          {!isHomeFilterVisible && (
            <span className={classes.tooltiptext}>
              <FormattedMessage id="home.openFilters" />
            </span>
          )}
        </div>
        {isHomeFilterVisible && <HomeNavbarFilter reference={homeFilterRef} />}
      </div>
    )
  ) : (
    <>
      <div className={classes.homeNavbar}>
        {location.pathname === "/home" ? (
          <div className={classes.homeNavbar__homeLinks}>
            <div className={classes.homeNavbar__links}>
              <HomeNavbarLink to="/home">
                <FormattedMessage id="home.home" />
              </HomeNavbarLink>
              {tenantData?.ActiveFeatures.includes("Topic") && (
                <HomeNavbarLink to="/topics">
                  <FormattedMessage id="home.topics" />
                </HomeNavbarLink>
              )}
              {(tenantData?.ActiveFeatures.includes("Learner Query") ||
                tenantData?.ActiveFeatures.includes("Forum") ||
                tenantData?.ActiveFeatures.includes("Poll")) && (
                <HomeNavbarLink to="/connect">
                  <FormattedMessage id="home.connect" />
                </HomeNavbarLink>
              )}
              {tenantData?.ActiveFeatures.includes(
                "ShotclassesCrowdSourcing"
              ) && (
                <HomeNavbarLink to="/contribute">
                  <FormattedMessage id="home.contribute" />
                </HomeNavbarLink>
              )}
              {(tenantData?.ActiveFeatures.includes("LiveQuizzing") ||
                tenantData?.ActiveFeatures.includes("VirtualQuizzing")) && (
                <HomeNavbarLink to="/quiz">
                  <FormattedMessage id="home.quiz" />
                </HomeNavbarLink>
              )}
            </div>
            <div className={classes.homeNavbar__links}>
              <div className={classes.homeNavbar__filterAndFavourite}>
                {(assignedClasses?.length > 0 ||
                  trendingClasses?.length > 0 ||
                  completedClasses?.length > 0 ||
                  assignedSeries?.length > 0 ||
                  trendingSeries?.length > 0 ||
                  completedSeries?.length > 0) && (
                  <div
                    className={classes.homeNavbar__filterAndFavourite__link}
                    onClick={handleClickHomeFilterPopUp}
                  >
                    <div className={classes.tooltip}>
                      <Logo logoImage={filterLogo} />
                      {!isHomeFilterVisible && (
                        <span className={classes.tooltiptext}>
                          <FormattedMessage id="home.openFilters" />
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {isHomeFilterVisible && (
                  <HomeNavbarFilter reference={homeFilterRef} />
                )}
                <HomeNavbarLink to="/myfavorites">
                  <div className={classes.tooltip}>
                    <FavouriteIconBig />
                    <span className={classes.tooltiptext}>
                      <FormattedMessage id="favorite.showFavorite" />
                    </span>
                  </div>
                </HomeNavbarLink>
              </div>
            </div>
          </div>
        ) : location.pathname === "/topics" ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to="/topics">
              <FormattedMessage id="home.topics" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname === "/topics/" + param?.topicId ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to={`/topics`}>
              <FormattedMessage id="home.topics" />
            </HomeNavbarLink>
            <ArrowNextIcon />
            <HomeNavbarLink to={`/topics/${param?.topicId}`}>
              {subTopics?.Name}
            </HomeNavbarLink>
          </div>
        ) : location.pathname ===
          "/topics/" + param?.topicId + "/topicDetails/" + param?.subTopicId ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to={`/topics`}>
              <FormattedMessage id="home.topics" />
            </HomeNavbarLink>
            <ArrowNextIcon />
            {param?.topicId != "search" ? (
              subTopics ? (
                <>
                  <HomeNavbarLink to={`/topics/${param?.topicId}`}>
                    {subTopics?.Name}
                  </HomeNavbarLink>
                  <ArrowNextIcon />
                </>
              ) : (
                <></>
              )
            ) : (
              <>
                <HomeNavbarLink to={`/globalSearch`}>
                  {"Global Search"}
                </HomeNavbarLink>
                <ArrowNextIcon />
              </>
            )}

            <HomeNavbarLink
              to={`/topics/${param?.topicId}/topicDetails/${param?.subTopicId}`}
            >
              <FormattedMessage id="topics.listOfCourses" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname === "/connect/generalqueries" ||
          location.pathname === "/connect/forum" ||
          location.pathname === "/connect/poll" ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            {tenantData?.ActiveFeatures.includes("Learner Query") && (
              <HomeNavbarLink to="/connect/generalqueries">
                <FormattedMessage id="query.generalQueries" />
              </HomeNavbarLink>
            )}
            {tenantData?.ActiveFeatures.includes("Forum") && (
              <HomeNavbarLink to="/connect/forum">
                <FormattedMessage id="forum.forum" />
              </HomeNavbarLink>
            )}
            {tenantData?.ActiveFeatures.includes("Poll") && (
              <HomeNavbarLink to="/connect/poll">
                <FormattedMessage id="poll.poll" />
              </HomeNavbarLink>
            )}
          </div>
        ) : location.pathname === "/contribute" ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to="/contribute">
              <FormattedMessage id="home.contribute" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname === "/contribute/" + param?.id ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to="/contribute">
              <FormattedMessage id="home.contribute" />
            </HomeNavbarLink>
            <ArrowNextIcon />
            <HomeNavbarLink to={`/contribute/${param?.id}`}>
              <FormattedMessage id="contribute.overview" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname === "/quiz" ||
          location.pathname === "/quiz/virtualquiz" ||
          location.pathname === "/quiz/livequiz" ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink
              to="/home"
              multiLogo
              // onClick={onHomeClick}
            >
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to="/quiz" active={true}>
              <FormattedMessage id="home.quiz" />
            </HomeNavbarLink>
            {quizData &&
              quizData?.UserRole !== "instructor" &&
              localStorage.getItem("quizSubmit") === "false" &&
              virtualQuizData && <QuizTimer />}
          </div>
        ) : location.pathname === "/credit" ? (
          <div className={classes.homeNavbar__homeLinks}>
            <div className={classes.homeNavbar__links}>
              {/* <div className={classes.homeNavbar__links__leftContainer}> */}
              <HomeNavbarLink to="/home" multiLogo>
                <Logo logoImage={backLogo} />
                <Logo logoImage={homeNavLogo} />
              </HomeNavbarLink>
              <HomeNavbarLink to="/credit">
                <FormattedMessage id="home.credits" />
              </HomeNavbarLink>
              {/* </div> */}
            </div>
            <div className={classes.homeNavbar__links}>
              <div className={classes.homeNavbar__links__creditStateType}>
                <div
                  className={
                    creditStateType === 1
                      ? classes.homeNavbar__links__creditStateType__stateTypeActive
                      : classes.homeNavbar__links__creditStateType__stateType
                  }
                >
                  {" "}
                  <FormattedMessage id="credits.global" />
                </div>
                <div
                  className={
                    classes.homeNavbar__links__creditStateType__toggleBtn
                  }
                  onClick={ToggleClass}
                >
                  <div
                    className={
                      creditStateType === 1
                        ? classes.homeNavbar__links__creditStateType__toggleBtn__innerCircle
                        : classes.homeNavbar__links__creditStateType__toggleBtn__innerCircleRight
                    }
                  >
                    {" "}
                  </div>
                </div>
                <div
                  className={
                    creditStateType === 2
                      ? classes.homeNavbar__links__creditStateType__stateTypeActive
                      : classes.homeNavbar__links__creditStateType__stateType
                  }
                >
                  {" "}
                  <FormattedMessage id="credits.myRegion" />
                </div>
              </div>
              <div className={classes.homeNavbar__filterAndFavourite}>
                <div
                  onClick={handleClickLanguagePopUp}
                  className={classes.homeNavbar__filterAndFavourite__link}
                >
                  <div className={classes.tooltip}>
                    <Logo logoImage={filterLogo} />
                    <span className={classes.tooltiptext}>
                      <FormattedMessage id="credits.openFilter" />
                    </span>
                  </div>
                </div>
                {isFilterVisible ? (
                  <FilterCredit
                    reference={filterRef}
                    handleClickLanguagePopUp={handleClickLanguagePopUp}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : location.pathname === "/myfavorites" ? (
          <div className={classes.homeNavbar__homeLinks}>
            <div className={classes.homeNavbar__links}>
              <HomeNavbarLink to="/home" multiLogo>
                <Logo logoImage={backLogo} />
                <Logo logoImage={homeNavLogo} />
              </HomeNavbarLink>
              <HomeNavbarLink to="/myfavorites">
                <FormattedMessage id="favorite.myFavorites" />
              </HomeNavbarLink>
            </div>
            <div
              className={
                classes.homeNavbar__links + " " + classes.homenavbar__mobile
              }
            >
              <div className={classes.homeNavbar__filterAndFavourite}>
                {isFavoriteAvailable && (
                  <div
                    className={classes.homeNavbar__filterAndFavourite__link}
                    onClick={handleClickHomeFilterPopUp}
                  >
                    <div className={classes.tooltip}>
                      <Logo logoImage={filterLogo} />
                      {!isHomeFilterVisible && (
                        <span className={classes.tooltiptext}>
                          <FormattedMessage id="home.openFilters" />
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {isHomeFilterVisible && (
                  <HomeNavbarFilter reference={homeFilterRef} />
                )}
                <HomeNavbarLink to="/home">
                  <FavouriteIconBig
                    fill={themeStyles?.CustomData?.PrimaryColor1}
                  />
                </HomeNavbarLink>
              </div>
            </div>
          </div>
        ) : location.pathname === "/privacyPolicy" ? (
          <div className={classes.homeNavbar__homeLinks}>
            <div className={classes.homeNavbar__links}>
              <HomeNavbarLink to="/home" multiLogo>
                <Logo logoImage={backLogo} />
                <Logo logoImage={homeNavLogo} />
              </HomeNavbarLink>
              <HomeNavbarLink to="/privacyPolicy">
                <FormattedMessage id="privacy.privacyPolicy" />
              </HomeNavbarLink>
            </div>
          </div>
        ) : location.pathname === "/contactUs" ? (
          <div className={classes.homeNavbar__homeLinks}>
            <div className={classes.homeNavbar__links}>
              <HomeNavbarLink to="/home" multiLogo>
                <Logo logoImage={backLogo} />
                <Logo logoImage={homeNavLogo} />
              </HomeNavbarLink>
              <HomeNavbarLink to="/contactUs">
                <FormattedMessage id="contact.contactUs" />
              </HomeNavbarLink>
            </div>
          </div>
        ) : location.pathname === "/account/notifications" ||
          location.pathname === "/account/myaccount" ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to="/account/notifications">
              <FormattedMessage id="notifications.notifications" />
            </HomeNavbarLink>
            <HomeNavbarLink to="/account/myaccount">
              <FormattedMessage id="home.myAccount" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname === "/skillDetail/" + param?.skillsId ||
          location.pathname === "/account/myaccount" ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to={`/account/myaccount`}>
              <FormattedMessage id="home.myAccount" />
            </HomeNavbarLink>
            <ArrowNextIcon />
            <div onClick={accountReports} className={classes.backToReport}>
              <FormattedMessage id="report.reports" />
            </div>
            <ArrowNextIcon />
            <HomeNavbarLink to={`/skillDetail/${param?.skillsId}`} multiLogo>
              <FormattedMessage id="report.skills" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname ===
            `/skillDetail/${param?.skillsId}/classDetail/${param?.id}` ||
          location.pathname === "/account/myaccount" ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to={`/skillDetail/${param?.skillsId}`} multiLogo>
              <Logo logoImage={backLogo} />
              <FormattedMessage id="report.skills" />
            </HomeNavbarLink>
            {currentView !== 4 ? (
              <>
                <HomeNavbarLink
                  active={currentView === 0}
                  inActive={currentView !== 0}
                  to={`/skillDetail/${param?.skillsId}/classDetail/${param?.id}/shotclass/${param?.id}`}
                >
                  <FormattedMessage id="home.takeClass" />
                </HomeNavbarLink>
                {(currentView === 1 || currentView === 2) &&
                  (classDetails?.ShotClassType === 2 ||
                    classDetails?.ShotClassType === 3 ||
                    classDetails?.ShotClassType === 5 ||
                    classDetails?.ShotClassType === 6) && (
                    <>
                      <ArrowNextIcon />
                      <HomeNavbarLink
                        active={currentView === 1}
                        inActive={currentView !== 1}
                        to={`/skillDetail/${param?.skillsId}/classDetail/${param?.id}/shotclass/${param?.id}`}
                      >
                        <FormattedMessage id="home.quiz" />
                      </HomeNavbarLink>
                    </>
                  )}

                {currentView === 2 && (
                  <>
                    <ArrowNextIcon />
                    <HomeNavbarLink
                      active={currentView === 2}
                      inActive={currentView !== 2}
                      to={`/skillDetail/${param?.skillsId}/classDetail/${param?.id}/shotclass/${param?.id}`}
                    >
                      <FormattedMessage id="class.survey" />
                    </HomeNavbarLink>
                  </>
                )}
              </>
            ) : (
              <HomeNavbarLink
                to={`/skillDetail/${param?.skillsId}/classDetail/${param?.id}/shotclass/${param?.id}`}
              >
                <FormattedMessage id="class.related" />
              </HomeNavbarLink>
            )}
          </div>
        ) : location.pathname ===
          "/account/notifications/classDetails/" + param?.id ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to={"/account/notifications"}>
              <FormattedMessage id="notifications.notifications" />
            </HomeNavbarLink>
            {currentView !== 4 ? (
              <>
                <HomeNavbarLink
                  active={currentView === 0}
                  inActive={currentView !== 0}
                  to={`/shotclass/${param?.id}`}
                >
                  <FormattedMessage id="home.takeClass" />
                </HomeNavbarLink>
                {(currentView === 1 || currentView === 2) &&
                  (classDetails?.ShotClassType === 2 ||
                    classDetails?.ShotClassType === 3 ||
                    classDetails?.ShotClassType === 5 ||
                    classDetails?.ShotClassType === 6) && (
                    <>
                      <ArrowNextIcon />
                      <HomeNavbarLink
                        active={currentView === 1}
                        inActive={currentView !== 1}
                        to={`/shotclass/${param?.id}`}
                      >
                        <FormattedMessage id="home.quiz" />
                      </HomeNavbarLink>
                    </>
                  )}
                {currentView === 2 && (
                  <>
                    <ArrowNextIcon />
                    <HomeNavbarLink
                      active={currentView === 2}
                      inActive={currentView !== 2}
                      to={`/shotclass/${param?.id}`}
                    >
                      <FormattedMessage id="class.survey" />
                    </HomeNavbarLink>
                  </>
                )}
              </>
            ) : (
              <HomeNavbarLink to={`/shotclass/${param?.id}`}>
                <FormattedMessage id="class.related" />
              </HomeNavbarLink>
            )}
          </div>
        ) : location.pathname === "/shotclass/" + param?.id ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>

            {currentView !== 4 ? (
              <>
                <HomeNavbarLink
                  active={currentView === 0}
                  inActive={currentView !== 0}
                  to={`/shotclass/${param?.id}`}
                >
                  <FormattedMessage id="home.takeClass" />
                </HomeNavbarLink>
                {(currentView === 1 || currentView === 2) &&
                  (classDetails?.ShotClassType === 2 ||
                    classDetails?.ShotClassType === 3 ||
                    classDetails?.ShotClassType === 5 ||
                    classDetails?.ShotClassType === 6) && (
                    <>
                      <ArrowNextIcon />
                      <HomeNavbarLink
                        active={currentView === 1}
                        inActive={currentView !== 1}
                        to={`/shotclass/${param?.id}`}
                      >
                        <FormattedMessage id="home.quiz" />
                      </HomeNavbarLink>
                    </>
                  )}
                {currentView === 2 && (
                  <>
                    <ArrowNextIcon />
                    <HomeNavbarLink
                      active={currentView === 2}
                      inActive={currentView !== 2}
                      to={`/shotclass/${param?.id}`}
                    >
                      <FormattedMessage id="class.survey" />
                    </HomeNavbarLink>
                  </>
                )}
              </>
            ) : (
              <HomeNavbarLink to={`/shotclass/${param?.id}`}>
                <FormattedMessage id="class.related" />
              </HomeNavbarLink>
            )}
          </div>
        ) : location.pathname === "/shotclass/" + param?.id + "/search" ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>

            <HomeNavbarLink to={`/globalSearch`}>
              <FormattedMessage id="search.backToSearch" />
            </HomeNavbarLink>
            <ArrowNextIcon />

            {currentView !== 4 ? (
              <>
                <HomeNavbarLink
                  active={currentView === 0}
                  inActive={currentView !== 0}
                  to={`/shotclass/${param?.id}`}
                >
                  <FormattedMessage id="home.takeClass" />
                </HomeNavbarLink>
                {(currentView === 1 || currentView === 2) &&
                  (classDetails?.ShotClassType === 2 ||
                    classDetails?.ShotClassType === 3 ||
                    classDetails?.ShotClassType === 5 ||
                    classDetails?.ShotClassType === 6) && (
                    <>
                      <ArrowNextIcon />
                      <HomeNavbarLink
                        active={currentView === 1}
                        inActive={currentView !== 1}
                        to={`/shotclass/${param?.id}`}
                      >
                        <FormattedMessage id="home.quiz" />
                      </HomeNavbarLink>
                    </>
                  )}
                {currentView === 2 && (
                  <>
                    <ArrowNextIcon />
                    <HomeNavbarLink
                      active={currentView === 2}
                      inActive={currentView !== 2}
                      to={`/shotclass/${param?.id}`}
                    >
                      <FormattedMessage id="class.survey" />
                    </HomeNavbarLink>
                  </>
                )}
              </>
            ) : (
              <HomeNavbarLink to={`/shotclass/${param?.id}`}>
                <FormattedMessage id="class.related" />
              </HomeNavbarLink>
            )}
          </div>
        ) : location.pathname ===
          `/topics/${param?.topicId}/topicDetails/${param?.subTopicId}/shotclass/${param?.id}` ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink
              to={`/topics/${param?.topicId}/topicDetails/${param?.subTopicId}`}
              multiLogo
            >
              <Logo logoImage={backLogo} />
              <FormattedMessage id="home.topics" />
            </HomeNavbarLink>
            {currentView !== 4 ? (
              <>
                <HomeNavbarLink
                  active={currentView === 0}
                  inActive={currentView !== 0}
                  to={`/topics/${param?.topicId}/topicDetails/${param?.subTopicId}/shotclass/${param?.id}`}
                >
                  <FormattedMessage id="home.takeClass" />
                </HomeNavbarLink>
                {(currentView === 1 || currentView === 2) &&
                  (classDetails?.ShotClassType === 2 ||
                    classDetails?.ShotClassType === 3 ||
                    classDetails?.ShotClassType === 5 ||
                    classDetails?.ShotClassType === 6) && (
                    <>
                      <ArrowNextIcon />
                      <HomeNavbarLink
                        active={currentView === 1}
                        inActive={currentView !== 1}
                        to={`/topics/${param?.topicId}/topicDetails/${param?.subTopicId}/shotclass/${param?.id}`}
                      >
                        <FormattedMessage id="home.quiz" />
                      </HomeNavbarLink>
                    </>
                  )}
                {currentView === 2 && (
                  <>
                    <ArrowNextIcon />
                    <HomeNavbarLink
                      active={currentView === 2}
                      inActive={currentView !== 2}
                      to={`/topics/${param?.topicId}/topicDetails/${param?.subTopicId}/shotclass/${param?.id}`}
                    >
                      <FormattedMessage id="class.survey" />
                    </HomeNavbarLink>
                  </>
                )}
              </>
            ) : (
              <HomeNavbarLink
                to={`/topics/${param?.topicId}/topicDetails/${param?.subTopicId}/shotclass/${param?.id}`}
              >
                <FormattedMessage id="class.related" />
              </HomeNavbarLink>
            )}
          </div>
        ) : location.pathname === "/series/" + param?.id ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>

            <HomeNavbarLink to={`/series/${param?.id}`}>
              <FormattedMessage id="home.series" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname.includes("account/notifications/pollDetail") ||
          location.pathname.includes("account/notifications/pollResult") ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to="/account/notifications">
              <FormattedMessage id="notifications.notifications" />
            </HomeNavbarLink>
            <HomeNavbarLink
              to={"account/notifications/pollDetail"}
              active={true}
            >
              <FormattedMessage id="poll.poll" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname === "/series/" + param?.id + "/search" ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>

            <HomeNavbarLink to={`/globalSearch`}>
              <FormattedMessage id="search.backToSearch" />
            </HomeNavbarLink>
            <ArrowNextIcon />

            <HomeNavbarLink to={`/series/${param?.id}/search`}>
              <FormattedMessage id="home.series" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname === "/series/" + param?.id + "/related" ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>

            <HomeNavbarLink to={`/series/${param?.id}`}>
              <FormattedMessage id="home.series" />
            </HomeNavbarLink>
            <ArrowNextIcon />
            <HomeNavbarLink to={`/series/${param?.id}/related`}>
              <FormattedMessage id="class.related" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname ===
          `/topics/${param?.topicId}/topicDetails/${param?.subTopicId}/series/${param?.id}` ? (
          <div className={classes.homeNavbar__links}>
            {/* <HomeNavbarLink
            to={`/topics/${param?.topicId}/topicDetails/${param?.subTopicId}`}
            multiLogo
          >
            <Logo logoImage={backLogo} />
            Topics
          </HomeNavbarLink> */}
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to={`/topics`}>Topics</HomeNavbarLink>
            <ArrowNextIcon />
            {param?.topicId != "search" ? (
              subTopics ? (
                <>
                  <HomeNavbarLink to={`/topics/${param?.topicId}`}>
                    {subTopics?.Name}
                  </HomeNavbarLink>
                  <ArrowNextIcon />
                </>
              ) : (
                <></>
              )
            ) : (
              <>
                <HomeNavbarLink to={`/globalSearch`}>
                  {"Global Search"}
                </HomeNavbarLink>
                <ArrowNextIcon />
              </>
            )}
            <HomeNavbarLink
              to={`/topics/${param?.topicId}/topicDetails/${param?.subTopicId}`}
            >
              <FormattedMessage id="topics.listOfCourses" />
            </HomeNavbarLink>
            <ArrowNextIcon />
            <HomeNavbarLink
              to={`/topics/${param?.topicId}/topicDetails/${param?.subTopicId}/series/${param?.id}`}
            >
              <FormattedMessage id="home.series" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname === "/connect/forum/" + param?.id ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home" multiLogo>
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to={`/connect/forum`}>
              <FormattedMessage id="forum.forum" />
            </HomeNavbarLink>
            <ArrowNextIcon />
            <HomeNavbarLink to={`/connect/forum/${param?.id}`}>
              <FormattedMessage id="home.topics" />
            </HomeNavbarLink>
          </div>
        ) : location.pathname === "/globalSearch" ? (
          <div
            className={`${classes.homeNavbar__homeLinks} ${classes.globalSearchLinks}`}
          >
            <div className={classes.homeNavbar__links}>
              {globalSearchState.globalSearch.length > 0 &&
                commonState.globalSearchText !== "" && (
                  <div
                    className={classes.checkbox_section}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {isWidthLessThan && (
                      <div
                        onClick={handleClickGlobalFilterPopUp}
                        className={classes.homeNavbar__filterAndFavourite__link}
                      >
                        <div className={classes.tooltip}>
                          <Logo logoImage={filterLogo} />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              {globalSearchState.globalSearch.length > 0 &&
                commonState.globalSearchText !== "" &&
                props.sortby !== "series" &&
                props.sortby !== "topics" && (
                  <>
                    <div
                      className={classes.checkbox_section}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <label className="custom-checkbox">
                        <FormattedMessage id="home.all" />
                        <input
                          type="checkbox"
                          id="all"
                          checked={commonState.globalCheckboxes.length <= 0}
                          onChange={(e) => props.checkboxcallBack(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        Videos
                        <input
                          type="checkbox"
                          id="video"
                          checked={commonState.globalCheckboxes?.includes(
                            "video"
                          )}
                          onChange={(e) => props.checkboxcallBack(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        Documents
                        <input
                          type="checkbox"
                          id="document"
                          checked={commonState.globalCheckboxes?.includes(
                            "document"
                          )}
                          onChange={(e) => props.checkboxcallBack(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="custom-checkbox">
                        Others
                        <input
                          type="checkbox"
                          id="others"
                          checked={commonState.globalCheckboxes?.includes(
                            "others"
                          )}
                          onChange={(e) => props.checkboxcallBack(e)}
                        />
                        <span className="checkmark"></span>
                      </label>
                      {/* <label className="custom-checkbox">
                      <FormattedMessage id="GlobalSearch.PDF" />
                      <input
                        type="checkbox"
                        id="PDF"
                        checked={commonState.globalCheckboxes?.includes("PDF")}
                        onChange={(e) => props.checkboxcallBack(e)}
                      />
                      <span className="checkmark"></span>
                    </label> */}
                      {/* <label className="custom-checkbox">
                      <FormattedMessage id="home.ppt" />
                      <input
                        type="checkbox"
                        id="PPT"
                        checked={commonState.globalCheckboxes?.includes("PPT")}
                        onChange={(e) => props.checkboxcallBack(e)}
                      />
                      <span className="checkmark"></span>
                    </label> */}

                      {/* {!commonState.isAdvanceSearch && ( */}
                      {/* <label className="custom-checkbox">
                      <FormattedMessage id="home.scorm" />
                      <input
                        type="checkbox"
                        id="scorm"
                        checked={commonState.globalCheckboxes?.includes(
                          "scorm"
                        )}
                        onChange={(e) => props.checkboxcallBack(e)}
                      />
                      <span className="checkmark"></span>
                    </label> */}
                      {/* )} */}

                      {/* <label className="custom-checkbox">
                      <FormattedMessage id="home.youtube" />
                      <input
                        type="checkbox"
                        id="youtube"
                        checked={commonState.globalCheckboxes?.includes(
                          "youtube"
                        )}
                        onChange={(e) => props.checkboxcallBack(e)}
                      />
                      <span className="checkmark"></span>
                    </label> */}

                      {/* <div className={classes.moreDropdownContainer}>
                      <ul
                        className={classes.threeDots}
                        onClick={customCheckBoxHandleClick}
                      >
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                      {show ? (
                        <div
                          className={classes.threeDotsDropDownContainer}
                          ref={customCheckBoxRef}
                        >
                          <ul>
                            <li>
                              <label className="custom-checkbox">
                                <FormattedMessage id="search.Online" />
                                <input
                                  type="checkbox"
                                  id="online"
                                  checked={commonState.globalCheckboxes?.includes(
                                    "online"
                                  )}
                                  onChange={(e) => props.checkboxcallBack(e)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </li>
                            <li>
                              <label className="custom-checkbox">
                                <FormattedMessage id="home.slideshare" />
                                <input
                                  type="checkbox"
                                  id="slideshare"
                                  checked={commonState.globalCheckboxes?.includes(
                                    "slideshare"
                                  )}
                                  onChange={(e) => props.checkboxcallBack(e)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </li> */}

                      {/* {!commonState.isAdvanceSearch && ( */}
                      {/* <li>
                              <label className="custom-checkbox">
                                <FormattedMessage id="home.html5" />
                                <input
                                  type="checkbox"
                                  id="html5"
                                  checked={commonState.globalCheckboxes?.includes(
                                    "html5"
                                  )}
                                  onChange={(e) => props.checkboxcallBack(e)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </li> */}
                      {/* )} */}
                      {/* </ul>
                        </div>
                      ) : (
                        ""
                      )}
                    </div> */}

                      {/* <div className={classes.moreDropdownContainer}>
                      <IconButton
                        // aria-label="more"
                        // id="long-button"
                        // aria-controls={open ? 'long-menu' : undefined}
                        // aria-expanded={open ? 'true' : undefined}
                        // aria-haspopup="true"
                        onClick={handleClick}

                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        // MenuListProps={{
                        //   'aria-labelledby': 'long-button',
                        // }}
                        
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                
                      >
                        <MenuItem>
                          <label className="custom-checkbox">
                            Online
                            <input
                              type="checkbox"
                              id="online"
                              checked={commonState.globalCheckboxes?.includes(
                                "online"
                              )}
                              onChange={(e) => props.checkboxcallBack(e)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </MenuItem>
                        <MenuItem>
                          <label className="custom-checkbox">
                            SlideShare
                            <input
                              type="checkbox"
                              id="slideshare"
                              checked={commonState.globalCheckboxes?.includes(
                                "slideshare"
                              )}
                              onChange={(e) => props.checkboxcallBack(e)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </MenuItem>
                        <MenuItem>
                          <label className="custom-checkbox">
                            HTML5
                            <input
                              type="checkbox"
                              id="html5"
                              checked={commonState.globalCheckboxes?.includes(
                                "html5"
                              )}
                              onChange={(e) => props.checkboxcallBack(e)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </MenuItem>
                      </Menu>
                    </div> */}
                    </div>
                  </>
                )}
              {globalSearchState.globalSearch.length > 0 && (
                <div className={classes.result_count}>
                  <b>
                    {commonState.isAdvanceSearch
                      ? globalSearchState.searchText
                      : commonState.globalSearchText}
                    <em>
                      (
                      {props.advanceSearchData.length > 0
                        ? props.advanceSearchData.length
                        : props.searchData.length}{" "}
                      <FormattedMessage id="GlobalSearch.Matches" />)
                    </em>
                  </b>
                </div>
              )}
            </div>
            <div
              className={
                classes.homeNavbar__links + " " + classes.homenavbar__mobile
              }
            >
              <div className={classes.homeNavbar__filterAndFavourite}>
                <div
                  onClick={handleClickContentLangPopUp}
                  className={classes.homeNavbar__filterAndFavourite__link}
                >
                  <div className={classes.tooltip}>
                    <Logo logoImage={langIcon} />
                    {!isContentLangVisible ? (
                      <span className={classes.tooltiptext}>
                        Content Language
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {isContentLangVisible ? (
                  // {true ? (
                  <div
                    className={classes.contentLangDropdown}
                    ref={contentLangRef}
                  >
                    <div className={classes.dropdown_heading}>
                      Select Content Language
                    </div>
                    <div className={classes.language_searchbox}>
                      <img src={smallMagnifyingGlass} alt="Search Language" />
                      <input
                        type="text"
                        onChange={props.changeLanguagebox}
                        placeholder={"Search Language"}
                        value={props.languagesearchbox}
                      />
                    </div>
                    <ul className={classes.language_list}>
                      {props.languagearray.length > 0 ? (
                        props.languagearray.map((item) => (
                          <li key={item} className={classes.language_list_item}>
                            <label className="custom-radio">
                              {item}
                              <input
                                type="radio"
                                id={item}
                                onChange={props.changeLanguageHandler}
                                checked={commonState.resultLanguage == item}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        ))
                      ) : (
                        <li className={classes.language_list_item}>
                          <h5
                            style={{
                              textAlign: "center",
                            }}
                          >
                            Languguage Not Available
                          </h5>
                        </li>
                      )}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {globalSearchState.globalSearch.length > 0 ? ( //888 bug
                <div className={classes.homeNavbar__filterAndFavourite}>
                  <div
                    onClick={handleClickGlobalFilterPopUp}
                    className={classes.homeNavbar__filterAndFavourite__link}
                  >
                    <div className={classes.tooltip}>
                      {!isWidthLessThan && <Logo logoImage={filterLogo} />}

                      {!isGlobalFilterVisible ? (
                        <span className={classes.tooltiptext}>
                          <FormattedMessage id="home.openFilters" />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {isGlobalFilterVisible ? (
                    <div
                      className={classes.filterDropdown}
                      ref={globalFilterRef}
                    >
                      <div className={classes.dropdown_header}>
                        <h3 className={classes.heading_text}>
                          <FormattedMessage id="filter.Filters" />
                        </h3>
                        <div className={classes.action_box}>
                          <button
                            className={classes.btn_reset}
                            onClick={props.resethandler}
                          >
                            <img src={resetIcon} alt="Reset" />
                            <FormattedMessage id="filter.reset" />
                          </button>
                        </div>
                      </div>
                      <div className={classes.filterBody}>
                        <div className={classes.filterBody_heading}>
                          <FormattedMessage id="filter.SortBy" />:
                        </div>
                        <ul className={classes.filterBody_list}>
                          <li className={classes.filterBody_list_item}>
                            <label className="custom-radio">
                              <FormattedMessage id="filter.all" />
                              <input
                                type="radio"
                                value="all"
                                checked={props.sortby == "all"}
                                onChange={props.onSortByChange}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                          <li className={classes.filterBody_list_item}>
                            <label className="custom-radio">
                              <FormattedMessage id="filter.topics" />
                              <input
                                type="radio"
                                value="topics"
                                checked={props.sortby == "topics"}
                                onChange={props.onSortByChange}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                          <li className={classes.filterBody_list_item}>
                            <label className="custom-radio">
                              <FormattedMessage id="filter.classes" />
                              <input
                                type="radio"
                                value="classes"
                                checked={props.sortby == "classes"}
                                onChange={props.onSortByChange}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                          <li className={classes.filterBody_list_item}>
                            <label className="custom-radio">
                              <FormattedMessage id="filter.series" />
                              <input
                                type="radio"
                                value="series"
                                checked={props.sortby == "series"}
                                onChange={props.onSortByChange}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        </ul>
                      </div>

                      {props.sortby !== "series" &&
                        props.sortby !== "topics" && (
                          <div className={classes.filterBody}>
                            <div className={classes.filterBody_heading}>
                              <FormattedMessage id="filter.sortByStatus" />:
                            </div>
                            <ul className={classes.filterBody_list}>
                              <li className={classes.filterBody_list_item}>
                                <label className="custom-checkbox">
                                  <FormattedMessage id="filter.AllCourses" />
                                  <input
                                    type="checkbox"
                                    id="All"
                                    checked={props.status.length == 0}
                                    onChange={props.statuschangeHandler}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li className={classes.filterBody_list_item}>
                                <label className="custom-checkbox">
                                  <FormattedMessage id="filter.NotStarted" />
                                  <input
                                    type="checkbox"
                                    id="NotStarted"
                                    checked={props.status.includes(
                                      "NotStarted"
                                    )}
                                    onChange={props.statuschangeHandler}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li className={classes.filterBody_list_item}>
                                <label className="custom-checkbox">
                                  <FormattedMessage id="filter.InProgress" />
                                  <input
                                    type="checkbox"
                                    id="InProgress"
                                    checked={props.status.includes(
                                      "InProgress"
                                    )}
                                    onChange={props.statuschangeHandler}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                              <li className={classes.filterBody_list_item}>
                                <label className="custom-checkbox">
                                  <FormattedMessage id="filter.completed" />
                                  <input
                                    type="checkbox"
                                    id="Completed"
                                    checked={props.status.includes("Completed")}
                                    onChange={props.statuschangeHandler}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        )}

                      {homeState.TrainingTypes.length >= 2 &&
                        props.sortby !== "series" &&
                        props.sortby !== "topics" && (
                          <div className={classes.trainingTypes}>
                            <div className={classes.trainingType_header}>
                              <h3 className={classes.heading_text}>
                                <FormattedMessage id="filter.FilterByType" />:
                              </h3>
                              <div className={classes.trainingType_searchbox}>
                                <img
                                  src={smallMagnifyingGlass}
                                  alt="Category"
                                />
                                <input
                                  type="text"
                                  placeholder={props.intl.formatMessage({
                                    id: `filter.category`,
                                  })}
                                  onChange={props.changetrainingtype}
                                  value={props.trainingtypesearchbox}
                                />
                              </div>
                            </div>
                            <ul className={classes.trainingType_list}>
                              {homeState.TrainingTypes.length >= 2 && (
                                <li className={classes.trainingType_list_item}>
                                  <label className="custom-radio">
                                    <FormattedMessage id="filter.all" />
                                    <input
                                      type="radio"
                                      name="checkedH"
                                      id={0}
                                      onChange={props.clickType}
                                      checked={props.trainingtype == 0}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </li>
                              )}
                              {homeState.TrainingTypes.length >= 2 &&
                                props.trainingtypearr.map((item) => (
                                  <li
                                    key={item.TypeId}
                                    className={classes.trainingType_list_item}
                                  >
                                    <label className="custom-radio">
                                      {item.Name}
                                      <input
                                        type="radio"
                                        name="checkedH"
                                        id={item.TypeId}
                                        onChange={props.clickType}
                                        checked={
                                          props.trainingtype == item.TypeId
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              <div className={classes.homeNavbar__filterAndFavourite}>
                <HomeNavbarLink to="/myfavorites">
                  <div className={classes.tooltip}>
                    <FavouriteIconBig />
                    <span className={classes.tooltiptext}>
                      <FormattedMessage id="favorite.showFavorite" />
                    </span>
                  </div>
                </HomeNavbarLink>
              </div>
            </div>
          </div>
        ) : location.pathname === "/connect/poll/pollDetail" ? (
          <div className={classes.homeNavbar__links}>
            <HomeNavbarLink to="/home">
              <Logo logoImage={backLogo} />
              <Logo logoImage={homeNavLogo} />
            </HomeNavbarLink>
            <HomeNavbarLink to={`/connect/poll`}>
              <FormattedMessage id="poll.poll" />
            </HomeNavbarLink>
            <ArrowNextIcon />
            <HomeNavbarLink to={`/connect/poll/pollDetail`}>
              <FormattedMessage id="poll.live" />
            </HomeNavbarLink>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* FIlter for mobile */}
      {globalSearchState.globalSearch.length && isWidthLessThan > 0 ? ( //888 bug
        <div
          className={`${classes.homeNavbar} ${classes.homeNavbarMobileFilter}`}
        ></div>
      ) : (
        ""
      )}
      {isGlobalFilterVisible && <div className={classes.opaqueLayer}></div>}
    </>
  );
};

export default injectIntl(HomeNavbar);
