import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import HomeSidebarConnect from "../../../components/CustomSvgIcons/HomeSidebarConnect";
import HomeSidebarContribute from "../../../components/CustomSvgIcons/HomeSidebarContribute";
import HomeSidebarHome from "../../../components/CustomSvgIcons/HomeSidebarHome";
import HomeSidebarLogout from "../../../components/CustomSvgIcons/HomeSidebarLogout";
import HomeSidebarMyFavorites from "../../../components/CustomSvgIcons/HomeSidebarMyFavorites";
import HomeSidebarQuiz from "../../../components/CustomSvgIcons/HomeSidebarQuiz";
import HomeSidebarTopics from "../../../components/CustomSvgIcons/HomeSidebarTopics";
import HomeHeaderLanguageChange from "../../../components/HomeComponents/HomeHeaderLanguageChange/HomeHeaderLanguageChange";
import HomeSidebarLink from "../../../components/HomeComponents/HomeSidebarLink/HomeSidebarLink";
import { logoutHandler } from "../../../store/actions/actionHandlers/loginActionHandler";
import classes from "./HomeSidebar.module.scss";
const HomeSidebar = ({ isSidebarVisible, reference, handleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const homeState = useSelector((state) => state.homeReducer);
  const { themeStyles } = homeState;
  const tenantData = JSON.parse(localStorage.getItem("tenantData"));
  const sidebarData = [
    {
      id: 1,
      label: "home.home",
      to: "/home",
      icon: HomeSidebarHome,
      msom: true,
    },
    {
      id: 2,
      label: "home.topics",
      to: "/topics",
      icon: HomeSidebarTopics,
      msom: tenantData?.ActiveFeatures.includes("Topic"),
    },
    {
      id: 3,
      label: "home.connect",
      to: "/connect",
      icon: HomeSidebarConnect,
      msom:
        tenantData?.ActiveFeatures.includes("Learner Query") ||
        tenantData?.ActiveFeatures.includes("Forum") ||
        tenantData?.ActiveFeatures.includes("Poll")
          ? true
          : false,
    },
    {
      id: 4,
      label: "home.contribute",
      to: "/contribute",
      icon: HomeSidebarContribute,
      msom: tenantData?.ActiveFeatures.includes("ShotclassesCrowdSourcing") ? true : false,
    },
    {
      id: 5,
      label: "home.quiz",
      to: "/quiz",
      icon: HomeSidebarQuiz,
      msom:
        (tenantData?.ActiveFeatures.includes("LiveQuizzing") ||
        tenantData?.ActiveFeatures.includes("VirtualQuizzing")) ? true : false,
    },
    {
      id: 6,
      label: "favorite.myFavorites",
      to: "/myfavorites",
      icon: HomeSidebarMyFavorites,
      msom: true,
    },
  ];
  return (
    <>
      <div
        className={
          isSidebarVisible ? classes.homeSidebar : classes.homeSidebar__hide
        }
      >
        {isSidebarVisible ? (
          <div ref={reference} className={classes.homeSidebar__list}>
            <div className={classes.homeSidebar__list__links}>
              {sidebarData.map(({ id, label, to, icon, msom }) => {
                return (
                  <HomeSidebarLink
                    key={id}
                    label={label}
                    to={to}
                    icon={icon}
                    handleSidebar={handleSidebar}
                    msom={msom}
                  />
                );
              })}
              <HomeHeaderLanguageChange />
            </div>
            <div
              className={classes.homeSidebar__list__logout}
              onClick={() => {
                dispatch(logoutHandler(navigate));
              }}
            >
              <HomeSidebarLogout
                fill={themeStyles?.CustomData?.PrimaryColor1}
              />
            </div>
          </div>
        ) : (
          <div className={classes.homeSidebar__list}>
            <div className={classes.homeSidebar__list__links}>
              {sidebarData.map(({ id, label, to, icon }) => {
                return (
                  <HomeSidebarLink
                    key={id}
                    label={label}
                    to={to}
                    icon={icon}
                    handleSidebar={handleSidebar}
                  />
                );
              })}
            </div>
            <div className={classes.homeSidebar__list__logout}>
              <HomeSidebarLogout
                fill={themeStyles?.CustomData?.PrimaryColor1}
              />
            </div>
          </div>
        )}
      </div>
      <div className={classes.sideNavbarOpaqueLayer}></div>
    </>
  );
};

export default HomeSidebar;
