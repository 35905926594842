import React from "react";
import { useSelector } from "react-redux";
import Modal from "../../CommonComponents/Modal/Modal";
import ModalBody from "../../CommonComponents/Modal/ModalBody";
import ModalHeader from "../../CommonComponents/Modal/ModalHeader";
import LeaderboardModalCarousel from "../LeaderboardModalCarousel/LeaderboardModalCarousel";
import classes from "../LeaderboardModal/LeaderboardModal.module.scss";
import { FormattedMessage } from "react-intl";
const LeaderboardModal = ({ closeModal }) => {
  const homeState = useSelector((state) => state.homeReducer);
  const { leaderboardData } = homeState;

  return (
    // <Modal closeModal={closeModal} className="modal_leader_board">
    <div className="modal_leader_board modal_wrapper">
      <div
        className="modal_dialog"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal_content">
          {leaderboardData?.length > 0 && (
            <>
              <div className={classes.title}>
                <div className="modal_header">
                  <h5 className="modal_title"><FormattedMessage id="credits.myGames" /> - <FormattedMessage id="credits.leaderboard" /></h5>
                  <button
                    type="button"
                    className="modal_close"
                    onClick={closeModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className={classes.title1}>
                <div className="modal_header">
                  <h5 className="modal_title"><FormattedMessage id="credits.myGames" /></h5>
                  <button
                    type="button"
                    className="modal_close"
                    onClick={closeModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="modal_body">
            <LeaderboardModalCarousel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardModal;
