import actionTypes from "../actionTypes";

export const requestAPI = () => ({
  type: actionTypes.API_REQ_STARTED,
});
export const getRelatedResultAction = (data) => ({
  type: actionTypes.commonTypes.GET_RELATED_RESULT,
  payload: data,
});
export const updateGlobalSearchTextAction = (data) => ({
  type: actionTypes.commonTypes.GLOBAL_SEARCH_TEXT,
  payload: data,
});
export const toggleDropdownText = data => ({
  type: actionTypes.commonTypes.DROPDOWN_TEXT_BOOLEAN,
  payload: data,
});
export const toggleAdvanceSearchAction = (data) => ({
  type: actionTypes.commonTypes.TOGGLE_ADVANCE_SEARCH,
  payload: data,
});
export const toggleSearchBarAction = (data) => ({
  type: actionTypes.commonTypes.TOGGLE_SEARCHBAR,
  payload: data,
});
export const toggleAdvanceSearchLoadingAction = (data) => ({
  type: actionTypes.commonTypes.TOGGLE_ADVANCE_SEARCH_LOADING,
  payload: data,
});
export const updateGlobalCheckboxes = (data) => ({
  type: actionTypes.commonTypes.UPDATE_GLOBAL_CHECKBOX,
  payload: data
})
export const updateResultLanguage = (data) => ({
  type: actionTypes.commonTypes.UPDATE_RESULT_LANGUAGE,
  payload: data
})
export const catchError = data => ({
  type: actionTypes.errorTypes.API_ERROR,
  payload: data,
});
