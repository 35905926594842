import actionTypes from "../actions/actionTypes";

const initialState = {
  getCreditDetails: null,
  creditStateType: 1,
  creditFilterType: 1,
};

const creditReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.creditTypes.GET_CREDIT_DETAILS:
      return {
        ...state,
        getCreditDetails: payload,
      };

    case actionTypes.creditTypes.SET_CREDIT_STATE_TYPE:
      return {
        ...state,
        creditStateType: payload,
      };

    case actionTypes.creditTypes.SET_CREDIT_FILTER_TYPE:
      return {
        ...state,
        creditFilterType: payload,
      };

    default:
      return state;
  }
};

export default creditReducer;
