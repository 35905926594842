import React from "react";
import Button from "../../CommonComponents/Button/Button";
import classes from "./QuizModal.module.scss";

const QuizModal = (props) => {
    const {
        isShowCrossIcon,
        closeModal,
        heading,
        bodytext,
        okButtonText,
        IsCancelButtonRequired,
        cancelBtnText,
        submitClick,
    } = props;
    return (
        <div className={classes.quizContainer}>
            <div className={classes.quizContainerModal}>
                {isShowCrossIcon && (
                    <div
                        className={classes.quizContainerModal__closeButton}
                        onClick={closeModal}
                    >
                        &times;
                    </div>
                )}

                <div className={classes.quizContainerModal__container}>
                    <div className={classes.quizContainerModal__heading}>
                        {heading}
                    </div>
                    <div className={classes.quizContainerModal__details}>
                        {bodytext}
                    </div>
                    <div className={classes.quizContainerModal__buttonAndLink}>
                        <Button
                            type="submit"
                            className={classes.primaryThemeButton}
                            label={okButtonText}
                            onClick={submitClick}
                        />
                        {IsCancelButtonRequired === true && (
                            <p
                                onClick={closeModal}
                                className={classes.quizContainerModal__link}
                            >
                                {cancelBtnText}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuizModal;
