import React from "react";

const HomeSidebarLogout = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <path
        id="db9"
        d="M16,1A15,15,0,1,0,31,16,15,15,0,0,0,16,1ZM15,8a1,1,0,0,1,2,0v7a1,1,0,0,1-2,0Zm1,17a9.014,9.014,0,0,1-9-9,8.92,8.92,0,0,1,3.38-7.02,1,1,0,1,1,1.24,1.56,7,7,0,1,0,8.76,0,1,1,0,0,1,1.24-1.56A8.92,8.92,0,0,1,25,16,9.014,9.014,0,0,1,16,25Z"
        transform="translate(-1 -1)"
        fill={fill ? fill : "#d31b61"}
      />
    </svg>
  );
};

export default HomeSidebarLogout;
