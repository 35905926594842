import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { logoutHandler } from "../../../store/actions/actionHandlers/loginActionHandler";
import { fetchInterceptor } from "../../../utils/fetchInterceptor";
import getTokenFromLocal from "../../../utils/getToken";
import SessionExpiredModal from "../SessionExpiredModal/SessionExpiredModal";
import ExtendTimerModal from "./ExtendTimerModal";

const ExtendTimer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sessionTime = 20; //2
  const warningTime = 18; //0

  const [timer, setTimer] = useState({ minutes: 0, seconds: 0 });
  const [extendModal, setExtendModal] = useState(false);
  const [sessionExpiredModal, setSessionExpiredModal] = useState(false);

  const homeState = useSelector((state) => state.homeReducer);
  const { resetTimer } = homeState;
  let token = getTokenFromLocal();

  // useEffect(() => {
  //   if (location.pathname === "/" || location.pathname === "/login") {
  //     setExtendModal(false);
  //   }
  // }, [location]);

  useEffect(() => {
    fetchInterceptor(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (resetTimer && token) {
        const total = new Date() - resetTimer;
        const second = Math.floor((total / 1000) % 60);
        const minute = Math.floor((total / 1000 / 60) % 60);
        setTimer({ minutes: minute, seconds: second });
        if (minute >= warningTime && minute < sessionTime) {
          setExtendModal(true);
        } else if (minute >= sessionTime) {
          setExtendModal(false);
          dispatch(logoutHandler(navigate));
          setSessionExpiredModal(true);
        } else if (extendModal === true || sessionExpiredModal === true) {
          setExtendModal(false);
          setSessionExpiredModal(false);
        }
      } else if (extendModal === true) {
        setExtendModal(false);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [
    dispatch,
    extendModal,
    sessionExpiredModal,
    location.pathname,
    resetTimer,
    token,
  ]);
  // console.log(19 - timer.minutes, 60 - timer.seconds);
  const toggleExtendModal = () => {
    setExtendModal(!extendModal);
  };
  const toggleSessionExpiredModal = () => {
    setSessionExpiredModal(!sessionExpiredModal);
  };

  return (
    <>
      {/* <div>
        {timer.minutes < 20 ? 19 - timer.minutes : 0} :{" "}
        {timer.minutes >= 20 && timer.seconds > 0 ? 0 : 60 - timer.seconds}
      </div> */}
      {extendModal && (
        <ExtendTimerModal
          closeModal={toggleExtendModal}
          minutes={timer.minutes}
          seconds={timer.seconds}
          sessionTime={sessionTime}
          warningTime={warningTime}
        />
      )}
      {sessionExpiredModal && (
        <SessionExpiredModal closeModal={toggleSessionExpiredModal} />
      )}
    </>
  );
};

export default ExtendTimer;
