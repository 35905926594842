import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoInternetFound from "../../components/CustomSvgIcons/NoInternetFound";
import classes from "./NoInternetPage.module.scss";
const NoInternetPage = () => {
  const homeState = useSelector((state) => state.homeReducer);
  const { themeStyles } = homeState;

  // const [visible, setVisible] = useState(false);

  // const showVisible = () => {
  //   setVisible(true);
  // };
  // const hideVisible = () => {
  //   setVisible(false);
  // };
  // useEffect(() => {
  //   window.addEventListener("offline", showVisible);

  //   window.addEventListener("online", hideVisible);

  //   return () => {
  //     window.removeEventListener("offline", showVisible);
  //     window.removeEventListener("online", hideVisible);
  //   };
  // }, []);

  return (
    <div
      className={
        // visible ?
        classes.noInternetPage
        //  : classes.noInternetPageHide
      }
    >
      <div className={classes.noInternetPage__main}>
        <NoInternetFound fill={themeStyles?.CustomData?.PrimaryColor1} />
        <div className={classes.noInternetPage__main__errorMessage}>
          No Internet Connection
        </div>
      </div>
    </div>
  );
};

export default NoInternetPage;
