import {
  GET_GENERAL_QUERIES_API_URL,
  PUT_GENERAL_QUERIES_API_URL,
  POST_GENERAL_QUERIES_API_URL,
  GET_FORUM_TOPICS_API_URL,
  SHOTCLASS_API_URL
} from "../../../utils/devApiContants";
import * as connectActionCreator from "../actionCreators/connectActionCreator";
import { errorTypes } from "../../../utils/errorTypes";
import { setErrorHandler, setLoadingHandler, unSetLoadingHandler } from "./errorActionHandler";
import {FormattedMessage} from "react-intl";

const { FAIL, SUCCESS } = errorTypes;
export const getGeneralQueriesHandler = (isLoad) => async (dispatch) => {
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(GET_GENERAL_QUERIES_API_URL, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => response.json())
    .then((result) => {
      dispatch(connectActionCreator.getGeneralQueriesAction(result));
    })

    .catch((error) => {
      dispatch(connectActionCreator.catchError(error));
      console.log("error " + error);
    }).finally(() => {
      dispatch(unSetLoadingHandler());
    });
};
export const putQueries = (queries, isLoad) => async (dispatch) => {
  console.log(queries);
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(PUT_GENERAL_QUERIES_API_URL, {
    method: 'PUT',
    headers: JSON.parse(localStorage.getItem('headers')),
    body: JSON.stringify(queries),
  }).then(response => {
    if (response.status === 415) {
      dispatch(setErrorHandler(FAIL, `Unsupported Media Type`));
    } else if (response.status === 401) {
      console.log('putQueries CLOSE');
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    } else {
      dispatch(setErrorHandler(SUCCESS, <FormattedMessage id="query.commentPostedSuccessfully"/>));
      dispatch(getGeneralQueriesHandler(true));
    }
  }).catch(error => {
    dispatch(connectActionCreator.catchError(error));
    console.log('error ' + error);
  }).finally(() => {
    // dispatch(unSetLoadingHandler());
  });
};

export const postExpertCornerQuery = (queries, toggleSubmitSuccess, isLoad) => async (dispatch) => {
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(POST_GENERAL_QUERIES_API_URL, {
    method: 'POST',
    headers: JSON.parse(localStorage.getItem('headers')),
    body: JSON.stringify(queries),
  }).then(response => {
    if (response.status === 415) {
      dispatch(setErrorHandler(FAIL, `Unsupported Media Type`));
    } else if (response.status === 401) {
      console.log('postExpertCornerQuery CLOSE');
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    } else {
      dispatch(getGeneralQueriesHandler(true));
      // dispatch(setErrorHandler(SUCCESS, `Query posted successfully`));
      toggleSubmitSuccess();
    }
  }).catch(error => {
    dispatch(connectActionCreator.catchError(error));
    console.log('error ' + error);
  });
};

export const getForumTopics = (isLoad) => async (dispatch, getState) => {
  console.log('current stateI:', getState());
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(GET_FORUM_TOPICS_API_URL, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => response.json())
    .then((result) => {
      dispatch(connectActionCreator.getForumTopicsDone(result.Content));
    })

    .catch((error) => {
      dispatch(connectActionCreator.catchError(error));
      console.log("error " + error);
    }).finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const putForumFollowUnfollow = (Id, IsForumFollowed) => async (dispatch, getState) => {
  await fetch(SHOTCLASS_API_URL + '/forums/forumtopics/topic?topicId=' + Id + '&IsForumFollowed=' + !IsForumFollowed, {
    method: 'PUT',
    headers: JSON.parse(localStorage.getItem('headers')),
  }).then(response => response.json())
    .then(json => {
      let object = getState().connectReducer.forumTopics.find(x => x.Id === Id)
      object.IsForumFollowed = json.ForumFollowed;
      dispatch(connectActionCreator.putForumFollowUnfollowDone(json));
      console.log('current stateS details:', getState());
      return json;
    })
    .catch(error => {
      dispatch(connectActionCreator.catchError(error));
      console.log('error ' + error);
    }).finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const getForumTopicsDetails = (Id, isLoad) => async (dispatch, getState) => {
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(GET_FORUM_TOPICS_API_URL + '/' + Id + '/threads?&pageNum=1&pageSize=10', {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then(response => {
      var tenanatObj = JSON.parse(localStorage.tenantData);
      console.log('getForumTopicsDetails response.status -- ', response.status);
      if (response.status === 200) {
        response.json().then(json => {
          dispatch(connectActionCreator.getForumTopicsDetailsDone(json));
          console.log('current stateS details:', getState());
          return json.Content;
        });
      } else if (response.status === 401 && tenanatObj.SecurityProviderName !== 'aad') {
        console.log('getForumTopicsDetails CLOSE');
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
      }
    })
    .catch((error) => {
      dispatch(connectActionCreator.catchError(error));
      console.log("error " + error);
    }).finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const postForumThread = (threads, toggleSubmitSuccess, isLoad) => async (dispatch) => {
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(SHOTCLASS_API_URL + '/forums/forumtopics/threads', {
    method: 'POST',
    headers: JSON.parse(localStorage.getItem('headers')),
    body: JSON.stringify(threads),
  }).then(response => {
    if (response.status === 415) {
      dispatch(setErrorHandler(FAIL, `Unsupported Media Type`));
    } else if (response.status === 401) {
      console.log('postExpertCornerQuery CLOSE');
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    } else {
      dispatch(getForumTopicsDetails(threads.TopicsId, true));
      // dispatch(setErrorHandler(SUCCESS, `Thread posted successfully`));
      toggleSubmitSuccess();
    }
  }).catch(error => {
    dispatch(connectActionCreator.catchError(error));
    console.log('error ' + error);
  }).finally(() => {
    dispatch(unSetLoadingHandler());
  });
};

export const getForumTopicsComments = (ThreadId, TopicsId, isLoad) => async (dispatch, getState) => {
  console.log('current stateI:', getState());
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(GET_FORUM_TOPICS_API_URL + '/' + TopicsId + '/threads/' + ThreadId + '/comments', {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then(response => {
      var tenanatObj = JSON.parse(localStorage.tenantData);
      console.log('getForumTopicsComments response.status -- ', response.status);
      if (response.status === 200) {
        response.json().then(json => {
          dispatch(connectActionCreator.getForumTopicCommentsDone(json));
          console.log('current stateS details:', getState());
          return json;
        });
      } else if (response.status === 401 && tenanatObj.SecurityProviderName !== 'aad') {
        console.log('getForumTopicsComments CLOSE');
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
      }
    })
    .then(json => {
      dispatch(getForumTopicsDetails(TopicsId, false));
    })
    .catch((error) => {
      dispatch(connectActionCreator.catchError(error));
      console.log("error " + error);
    }).finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const postForumComments = (ThreadId, TopicsId, comments, isLoad) => async (dispatch) => {
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(SHOTCLASS_API_URL + '/forumtopics/' + TopicsId + '/threads/' + ThreadId + '/comments', {
    method: 'POST',
    headers: JSON.parse(localStorage.getItem('headers')),
    body: JSON.stringify(comments),
  }).then(response => {
    if (response.status === 415) {
      dispatch(setErrorHandler(FAIL, `Unsupported Media Type`));
    } else if (response.status === 401) {
      console.log('Put Forum Comments CLOSE');
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    } else {
      dispatch(setErrorHandler(SUCCESS, <FormattedMessage id="forum.commentPostedSuccessfully"/>));
      dispatch(getForumTopicsComments(ThreadId, TopicsId, true));
    }
  }).catch(error => {
    dispatch(connectActionCreator.catchError(error));
    console.log('error ' + error);
  }).finally(() => {
    // dispatch(unSetLoadingHandler());
  });
};

export const deleteThread = (ThreadId, TopicsId, isLoad) => async (dispatch) => {
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(SHOTCLASS_API_URL + '/forums/threads/' + ThreadId, {
    method: 'DELETE',
    headers: JSON.parse(localStorage.getItem('headers')),
  }).then(response => response.json())
    .then(json => {
      dispatch(connectActionCreator.deleteThreadDone(json));
      return json;
    })
    .then(json => {
      dispatch(setErrorHandler(SUCCESS,<FormattedMessage id="forum.threadDeletedSuccessfully"/>));
      dispatch(getForumTopicsDetails(TopicsId, true));
    })
    .catch(error => {
      dispatch(connectActionCreator.catchError(error));
      console.log('error ' + error);
    }).finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const getPolls = (isLoad) => async (dispatch, getState) => {
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(SHOTCLASS_API_URL + '/me/polls', {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then(response => {
      var tenanatObj = JSON.parse(localStorage.tenantData);
      console.log('getPolls response.status -- ', response.status);
      if (response.status === 200) {
        response.json().then(json => {
          dispatch(connectActionCreator.getPollsDone(json));
          console.log('current stateS:getPollsDone', getState());
          return json;
        });
      } else if (response.status === 401 && tenanatObj.SecurityProviderName !== 'aad') {
        console.log('getPolls CLOSE');
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
      }
    })
    .catch((error) => {
      dispatch(connectActionCreator.catchError(error));
      console.log("error " + error);
    }).finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const getPollsInNotification = (setData) => async () => {
  await fetch(SHOTCLASS_API_URL + '/me/polls', {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then(response => {
      if (response.status === 200) {
        response.json().then(json => {
          setData(json.Data)
          console.log(json.Data)
        });
      }})
    .catch((error) => {
      console.log("error " + error);
    })
};

export const getPollsHistoryInNotification = (setData) => async () => {
  await fetch(SHOTCLASS_API_URL + '/me/polls/history', {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then(response => {
      if (response.status === 200) {
        response.json().then(json => {
          setData(json.Data)
          console.log(json.Data)
        });
      }})
    .catch((error) => {
      console.log("error " + error);
    })
};

export const getResponse = (pollId, distributionId, isLoad) => async (dispatch, getState) => {
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(SHOTCLASS_API_URL + '/polls' + '/' + pollId + '/' + distributionId + '/response', {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then(response => {
      var tenanatObj = JSON.parse(localStorage.tenantData);
      console.log('getPolls response.status -- ', response.status);
      if (response.status === 200) {
        response.json().then(json => {
          if (json.Data === null) {
            window.location.href = '/home/assigned';
            return;
          } else {
            dispatch(connectActionCreator.getResponseDone(json.Data));
            console.log('current stateS details:', getState());
            return json.Data;
          }
        });
      } else if (response.status === 401 && tenanatObj.SecurityProviderName !== "aad") {
        console.log('getResponse CLOSE');
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
      }
    })
    .catch((error) => {
      dispatch(connectActionCreator.catchError(error));
      console.log("error " + error);
    }).finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const pollResponseByPollId = (pollId, distributionId, setData) => async() => {
  
  await fetch(SHOTCLASS_API_URL + '/polls/' + pollId + '/' + distributionId + '/response', {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then(response => {
      if (response.status === 200) {
        response.json().then(json => {
          if(json.Data)
            setData(json.Data)
        })
      }
    })
    .catch((error) => {
      console.log("error " + error);
    })
}

export const isPollAvailable = (isLoad) => async (dispatch, getState) => {
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(SHOTCLASS_API_URL + '/polls/available', {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then(response => {
      var tenanatObj = JSON.parse(localStorage.tenantData)
      console.log('isPollAvailable response.status -- ', response.status);
      if (response.status === 200) {
        response.json().then(json => {
          dispatch(connectActionCreator.isPollAvailableDone(json));
          console.log('current stateS:isPollAvailableDone', getState());
          return json;
        });
      } else if (response.status === 401 && tenanatObj.SecurityProviderName !== "aad") {
        console.log('isPollAvailable CLOSE');
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
      }
    })
    .catch((error) => {
      dispatch(connectActionCreator.catchError(error));
      console.log("error " + error);
    }).finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const postUserPollResponse = (response, distributionId, redirectToPoll, isLoad) => async (dispatch, getState) => {
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(SHOTCLASS_API_URL + '/pollresponses', {
    method: 'POST',
    headers: JSON.parse(localStorage.getItem('headers')),
    body: JSON.stringify(response),
  }).then(response => {
    var tenanatObj = JSON.parse(localStorage.tenantData)
    console.log('postUserPollResponse response.status -- ', response.status);
    if (response.status === 200) {
      response.json().then(json => {
        dispatch(connectActionCreator.postUserPollResoponseDone(json));
        console.log('current stateS details:', getState());
      });
    } else if (response.status === 401 && tenanatObj.SecurityProviderName !== "aad") {
      console.log('postUserPollResponse CLOSE');
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    }
  })
    .then(json =>
      dispatch(isPollAvailable()))
    .then(json => {
      // dispatch(setErrorHandler(SUCCESS, `Vote Submitted Successfully`));
      dispatch(getResponse(response.PollId, distributionId));
      console.log('current stateS details:', getState());
      return json;
    }).catch(error => {
      dispatch(connectActionCreator.catchError(error));
      console.log('error ' + error);
    }).finally(() => {
      redirectToPoll();
      dispatch(unSetLoadingHandler());
    });
};

export const getPollHistory = (isLoad) => async (dispatch,getState) => {
  if (isLoad) dispatch(setLoadingHandler());
  await fetch(SHOTCLASS_API_URL + '/me/polls/history', {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  }).then(response => {
    var tenanatObj = JSON.parse(localStorage.tenantData);
    console.log('getPollHistory response.status -- ', response.status);
    if (response.status === 200) {
      response.json().then(json => {
        dispatch(connectActionCreator.getPollHistoryDone(json));
        console.log('current stateS:getPollHistoryDone', getState());
        return json.Content;
      });
    } else if (response.status === 401 && tenanatObj.SecurityProviderName !== 'aad') {
      console.log('getPollHistory CLOSE');
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    }
  }).catch((error) => {
    dispatch(connectActionCreator.catchError(error));
    console.log("error " + error);
  }).finally(() => {
    dispatch(unSetLoadingHandler());
  });
};