import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import classes from "./HomeFooterContainer.module.scss";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
const HomeFooterContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const privacyPolicyState = useSelector((state) => state.privacyPolicyReducer);

  const { isPrivacyPolicy, getPrivacyPolicyDone } = privacyPolicyState;

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (
      location?.pathname === "/" ||
      location?.pathname === "/login" ||
      location?.pathname.includes("/connect") ||
      location?.pathname.includes("/account") ||
      location?.pathname.includes("/series") ||
      location?.pathname.includes("/quiz") ||
      location?.pathname.includes("/shotclass") ||
      location?.pathname.includes("/nopagefound") ||
      location?.pathname.includes("/aadlogin") ||
      location?.pathname.includes("/resetPassword") ||
      location?.pathname.includes("/skillDetail") ||
      isPrivacyPolicy === true
    ) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [location?.pathname, isPrivacyPolicy]);

  return (
    isPrivacyPolicy === false &&
    getPrivacyPolicyDone && (
      <>
        <div
          className={
            isVisible ? classes.heightAboveFooter : classes.hideFooterContainer
          }
        >
          &nbsp;
        </div>
        <div
          className={
            isVisible ? classes.footerContainer : classes.hideFooterContainer
          }
        >
          <ul className={classes.footerList}>
            <li className={classes.first}>
              <FormattedMessage id="home.copyright" />
            </li>
            |
            <li
              onClick={() => navigate("/privacyPolicy")}
              className={
                location.pathname === "/privacyPolicy"
                  ? classes.footerList__active
                  : ""
              }
            >
              <FormattedMessage id="home.privacyPolicy" />
            </li>
            |{" "}
            <li
              onClick={() => navigate("/contactUs")}
              className={
                location.pathname === "/contactUs"
                  ? classes.footerList__active
                  : ""
              }
            >
              <FormattedMessage id="home.contactUs" />
            </li>
          </ul>
        </div>
      </>
    )
  );
};

export default HomeFooterContainer;
