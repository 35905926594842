import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import ProtectedRoute from "../../hoc/ProtectedRoute";
import ExtendTimer from "../../components/CommonComponents/ExtendTimerModal/ExtendTimer";
import HomeFooterContainer from "../../containers/HomeContainers/HomeFooterContainer/HomeFooterContainer";
import PageLoader from "../../components/CommonComponents/PageLoader/PageLoader";
import NoInternetPage from "../NoInternetPage/NoInternetPage";
import PollResults from "../ConnectPage/PollPage/PollResults/PollResults";
// import UnderMaintenance from "../UnderMaintenance/UnderMaintenance";

const AccountPage = lazy(() => import("../AccountPage/AccountPage"));
const CheckTenantPage = lazy(() =>
  import("../CheckTenantPage/CheckTenantPage")
);
const ConnectPage = lazy(() => import("../ConnectPage/ConnectPage"));
const ForumPage = lazy(() => import("../ConnectPage/ForumPage/ForumPage"));
const PollPage = lazy(() => import("../ConnectPage/PollPage/PollPage"));
const PollAttempt = lazy(() =>
  import("../ConnectPage/PollPage/PollAttempt/PollAttempt")
);
const GeneralQueryPage = lazy(() =>
  import("../ConnectPage/GeneralQueriesPage/GeneralQueriesPage")
);
const ContributePage = lazy(() => import("../ContributePage/ContributePage"));
const OverviewPage = lazy(() =>
  import("../ContributePage/OverviewPage/OverviewPage")
);
const HomePage = lazy(() => import("../HomePage/HomePage"));
const LoginPage = lazy(() => import("../LoginPage/LoginPage"));
const MyFavoritesPage = lazy(() =>
  import("../MyFavoritesPage/MyFavoritesPage")
);
const PreLoginPage = lazy(() => import("../PreLoginPage/PreLoginPage"));
const QuizPage = lazy(() => import("../QuizPage/QuizPage"));
const SeriesPage = lazy(() => import("../SeriesPage/SeriesPage"));
const ShotclassCoursePage = lazy(() =>
  import("../ShotclassCoursePage/ShotclassCoursePage")
);
const GroupTopicsPage = lazy(() => import("../TopicsPage/GroupTopicsPage"));
const TopicDescription = lazy(() =>
  import("../ConnectPage/ForumPage/TopicDescription/TopicDescription")
);
const AADLoginPage = lazy(() => import("../AADLoginPage/AADLoginPage"));
const ConfirmPasswordPage = lazy(() =>
  import("../ChangePasswordPage/ConfirmPasswordPage")
);
const PrivacyPolicypage = lazy(() =>
  import("../PrivacyPolicyPage/PrivacyPolicyPage")
);
const CreditPage = lazy(() =>
  import("../../../src/views/CreditPage/CreditPage")
);
const NotificationsPage = lazy(() =>
  import("../AccountPage/NotificationsPage/NotificationsPage")
);
const SkillsPage = lazy(() => import("../AccountPage/SkillsPage/SkillsPage"));
const MyAccountPage = lazy(() =>
  import("../AccountPage/MyAccountsPage/MyAccountPage")
);
const NoPageFoundPage = lazy(() =>
  import("../NoPageFoundPage/NoPageFoundPage")
);
const RelatedSeriesPage = lazy(() =>
  import("../SeriesPage/RelatedSeriesPage/RelatedSeriesPage")
);
const GlobalSearchPage = lazy(() =>
  import("../GlobalSearchPage/GlobalSearchPage")
);
const TopicsPage = lazy(() => import("../TopicsPage/TopicsPage/TopicsPage"));
const TopicDetailsPage = lazy(() =>
  import("../TopicsPage/TopicDetailsPage/TopicDetailsPage")
);
const ContactUsPage = lazy(() => import("../ContactUsPage/ContactUsPage"));
const VirtualQuizPage = lazy(() =>
  import("../QuizPage/VirtualQuizPage/VirtualQuizPage")
);
const LiveQuizPage = lazy(() =>
  import("../QuizPage/LiveQuizPage/LiveQuizPage")
);
const RootRoute = () => {
  const [visible, setVisible] = useState(false);

  const showVisible = () => {
    setVisible(true);
  };
  const hideVisible = () => {
    setVisible(false);
  };
  useEffect(() => {
    window.addEventListener("offline", showVisible);

    window.addEventListener("online", hideVisible);

    return () => {
      window.removeEventListener("offline", showVisible);
      window.removeEventListener("online", hideVisible);
    };
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <ExtendTimer />
        <Suspense fallback={<PageLoader />}>
          {visible && <NoInternetPage />}
          <Routes>
            <Route exact path="/" element={<PreLoginPage />} />
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/aadlogin" element={<AADLoginPage />} />
            <Route exact path="/globalSearch" element={<GlobalSearchPage />} />
            <Route path="/home" element={<ProtectedRoute />}>
              <Route exact path="/home" element={<HomePage />} />
            </Route>
            <Route path="/shotclass" element={<ProtectedRoute />}>
              <Route
                exact
                path="/shotclass/:id"
                element={<ShotclassCoursePage />}
              />
              <Route
                exact
                path="/shotclass/:id/search"
                element={<ShotclassCoursePage />}
              />
            </Route>
            <Route path="series/:id" element={<ProtectedRoute />}>
              <Route exact path="/series/:id" element={<SeriesPage />} />
              <Route exact path="/series/:id/search" element={<SeriesPage />} />
              <Route exact path="related" element={<RelatedSeriesPage />} />
            </Route>

            <Route path="topics" element={<ProtectedRoute />}>
              <Route exact path="/topics" element={<GroupTopicsPage />} />
              <Route exact path=":topicId" element={<TopicsPage />} />
              <Route
                exact
                path=":topicId/topicDetails/:subTopicId"
                element={<TopicDetailsPage />}
              />
              <Route
                exact
                path=":topicId/topicDetails/:subTopicId/shotclass/:id"
                element={<ShotclassCoursePage />}
              />
              <Route
                exact
                path=":topicId/topicDetails/:subTopicId/series/:id"
                element={<SeriesPage />}
              />
              <Route
                exact
                path=":topicId/topicDetails/:subTopicId/series/:id/related"
                element={<RelatedSeriesPage />}
              />
            </Route>
            <Route exact path="connect" element={<ProtectedRoute />}>
              <Route exact path="/connect" element={<ConnectPage />} />
              <Route
                exact
                path="generalqueries"
                element={<GeneralQueryPage />}
              />
              <Route exact path="forum" element={<ForumPage />} />
              <Route exact path="forum/:id" element={<TopicDescription />} />
              <Route exact path="poll" element={<PollPage />} />
              <Route exact path="poll/pollDetail" element={<PollAttempt />} />
            </Route>
            <Route path="/contribute" element={<ProtectedRoute />}>
              <Route exact path="/contribute" element={<ContributePage />} />
              <Route exact path="/contribute/:id" element={<OverviewPage />} />
            </Route>
            <Route path="/credit" element={<ProtectedRoute />}>
              <Route exact path="/credit" element={<CreditPage />} />
            </Route>
            <Route exact path="quiz" element={<ProtectedRoute />}>
              <Route exact path="/quiz" element={<QuizPage />} />
              <Route exact path="virtualquiz" element={<VirtualQuizPage />} />
              <Route exact path="livequiz" element={<LiveQuizPage />} />
            </Route>
            <Route path="/myfavorites" element={<ProtectedRoute />}>
              <Route exact path="/myfavorites" element={<MyFavoritesPage />} />
            </Route>
            <Route
              exact
              path="/skillDetail/:skillsId"
              element={<SkillsPage />}
            />
            <Route
              exact
              path="/skillDetail/:skillsId/classDetail/:id"
              element={<ShotclassCoursePage />}
            /><Route
            exact
            path="/skillDetail/:skillsId/classDetail/:id/series/:id"
            element={<SeriesPage />}
            />
            <Route
              exact
              path="/skillDetail/:skillsId/classDetail/:id/series/:id/related"
              element={<RelatedSeriesPage />}
            />
            
            <Route exact path="account" element={<ProtectedRoute />}>
              <Route exact path="/account" element={<AccountPage />} />
              <Route
                exact
                path="notifications"
                element={<NotificationsPage />}
              />
              <Route
                exact
                path="/account/notifications/classDetails/:id"
                element={<ShotclassCoursePage />}
              />
              <Route
                exact
                path="/account/notifications/pollResult"
                element={<PollPage />}
              />
              <Route
                exact
                path="/account/notifications/pollDetail/activePoll"
                element={<PollPage />}
              />
              <Route
                exact
                path="/account/notifications/pollDetail"
                element={<PollAttempt />}
              />
              <Route exact path="myaccount" element={<MyAccountPage />} />
            </Route>
            <Route path="/accessdenied" element={<h1>Access Denied</h1>} />
            <Route path="/nopagefound" element={<NoPageFoundPage />} />
            {/* <Route path="/undermaintenance" element={<UnderMaintenance />} /> */}
            <Route path="/resetPassword" element={<ProtectedRoute />}>
              <Route
                exact
                path="/resetPassword"
                element={<ConfirmPasswordPage />}
              />
            </Route>
            <Route path="/privacyPolicy" element={<ProtectedRoute />}>
              <Route
                exact
                path="/privacyPolicy"
                element={<PrivacyPolicypage />}
              />
            </Route>

            <Route path="/contactUs" element={<ProtectedRoute />}>
              <Route exact path="/contactUs" element={<ContactUsPage />} />
            </Route>
            <Route path="/aadlogin/:handle" element={<AADLoginPage />} />
            <Route path="*" element={<CheckTenantPage />} />
          </Routes>
        </Suspense>
        <HomeFooterContainer />
      </Router>
    </>
  );
};

export default RootRoute;
