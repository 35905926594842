import * as errorActionCreator from "../actionCreators/errorActionCreator";

export const setErrorHandler = (errorType, error, closeTimer) => (dispatch) => {
  dispatch(errorActionCreator.setErrorAction(errorType, error, closeTimer));
};
export const unSetErrorHandler = () => (dispatch) => {
  dispatch(errorActionCreator.unSetErrorAction());
};

export const setLoadingHandler = () => (dispatch) => {
  dispatch(errorActionCreator.setLoadingAction());
};
export const unSetLoadingHandler = () => (dispatch) => {
  dispatch(errorActionCreator.unSetLoadingAction());
};
