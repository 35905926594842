import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { resetTimerAction } from "../../../store/actions/actionCreators/homeActionCreator";
import {
  getLastLoginTimeHandler,
  logoutHandler,
} from "../../../store/actions/actionHandlers/loginActionHandler";
import { postVQAssessScore } from "../../../store/actions/actionHandlers/quizActionHandler";
import { convertToMillisec } from "../../../utils/commonQuizFunction";
import Button from "../Button/Button";
import classes from "./ExtendTimerModal.module.scss";
const ExtendTimerModal = ({
  closeModal,
  minutes,
  seconds,
  sessionTime,
  warningTime,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const quizState = useSelector((state) => state.quizReducer);
  const { quizData, virtualQuizData } = quizState;

  const userScoreObjCreation = (IsSubmitted, IsUserAssessmentSuccess) => {
    console.log('inside----------')
    const totalQDuration = convertToMillisec();
    const { ShotClassId, VirtualOrLiveQuizEventId } = quizData;
    let UserQuestionResponse = [];
    virtualQuizData.AssessmentSet.QuestionList.forEach((ques) => {
        let optionId, IsRightAnswer;
        ques.ChoiceList.forEach((option) => {
            if (
                option.Isselected === true ||
                option.IsselectedByUserInResonse === true
            ) {
                optionId = option.ID;
                if (option.IsAnswer === true) {
                    IsRightAnswer = true;
                } else {
                    IsRightAnswer = false;
                }
            }
        });
        UserQuestionResponse.push({
            QuestionId: ques.ID,
            ChoiceId: optionId,
            IsRightAnswer: IsRightAnswer,
        });
    });
    return {
        ClassId: parseInt(ShotClassId),
        ClassName: virtualQuizData.Name,
        CourseCompletionDuration: totalQDuration,
        IsQuizSubmitForLiveOrVirtualEvent: IsSubmitted,
        IsUserAssessmentSuccess: IsUserAssessmentSuccess,
        Score: 0,
        TotalCACount: 0,
        UserQuestionResponse: UserQuestionResponse,
        VirtualOrLiveQuizEventId: VirtualOrLiveQuizEventId,
    };
};
  return (
    <div className="modal_extendTimer modal_wrapper">
      <div
        className="modal_dialog"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal_content">
          <div className="modal_body">
            <div className={classes.extendTimer}>
              <div className={classes.extendTimer__subHeader}>
                Your session will expire in:{" "}
                {minutes < sessionTime
                  ? sessionTime - 1 - minutes < 10
                    ? "0" + (sessionTime - 1 - minutes).toString()
                    : sessionTime - 1 - minutes
                  : "00"}
                :
                {minutes >= sessionTime && seconds > 0
                  ? "00"
                  : 60 - seconds < 10
                  ? "0" + (60 - seconds).toString()
                  : 60 - seconds}
              </div>
              <div className={classes.extendTimer__buttonContainer}>
                <Button
                  onClick={() => {
                    dispatch(resetTimerAction());
                    dispatch(getLastLoginTimeHandler(navigate, true));
                    closeModal();
                  }}
                  type="submit"
                  label="Extend"
                  className={
                    classes.extendTimer__buttonContainer__primaryThemeButton
                  }
                />
                <div
                  onClick={() => {
                    if(location.pathname === "/quiz/virtualquiz" ||
                      location.pathname.includes("virtualquiz")){
                        const userScoreObj = userScoreObjCreation(false, false);
                        dispatch(postVQAssessScore(userScoreObj, userScoreObj.ClassId));
                    }
                    dispatch(logoutHandler(navigate));
                    closeModal();
                  }}
                  className={classes.extendTimer__buttonContainer__logoutLink}
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtendTimerModal;
