import actionTypes from "../actionTypes";

export const getAllPublishedTopicsAction = (data) => ({
  type: actionTypes.topicsTypes.GET_ALL_PUBLISHED_TOPICS,
  payload: data,
});
export const setLoadingPublishedTopicsAction = (data) => ({
  type: actionTypes.topicsTypes.SET_LOADING_PUBLISHED_TOPICS,
  payload: data,
});
export const getAllPublishedTopicsCountAction = (data) => ({
  type: actionTypes.topicsTypes.GET_ALL_PUBLISHED_TOPICS_COUNT,
  payload: data,
});

export const getSubTopicsAction = (data) => ({
  type: actionTypes.topicsTypes.GET_SUB_TOPICS,
  payload: data,
});

export const getTopicDetailsAction = (data) => ({
  type: actionTypes.topicsTypes.GET_TOPIC_DETAILS,
  payload: data,
});
export const setLoadingTopicsDetailsAction = (data) => ({
  type: actionTypes.topicsTypes.SET_LOADING_TOPICS_DETAILS,
  payload: data,
});

export const setInitialTopicsStateAction = () => ({
  type: actionTypes.topicsTypes.SET_INITIAL_TOPICS_STATE,
});
