import React from "react";

const HomeHeaderLangIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.93"
      height="23.93"
      viewBox="0 0 23.93 23.93"
    >
      <g id="lang_icon" transform="translate(0 0)">
        <path
          id="Path_331"
          data-name="Path 331"
          d="M18.965,7A11.965,11.965,0,1,0,30.93,18.965,11.969,11.969,0,0,0,18.965,7Zm-.278.565v6.948a16.25,16.25,0,0,1-4.913-.8,14.152,14.152,0,0,1,1.087-2.887c.99-1.905,2.3-3.084,3.748-3.252Zm.557,0c1.477.131,2.817,1.318,3.826,3.261a14.152,14.152,0,0,1,1.087,2.887,16.25,16.25,0,0,1-4.913.8ZM16.626,7.8a7.666,7.666,0,0,0-2.261,2.765,14.6,14.6,0,0,0-1.122,2.956,14.749,14.749,0,0,1-3.191-1.67A11.4,11.4,0,0,1,16.626,7.8Zm4.687,0a11.372,11.372,0,0,1,6.565,4.052,14.748,14.748,0,0,1-3.191,1.67,14.608,14.608,0,0,0-1.122-2.956A7.693,7.693,0,0,0,21.313,7.8Zm-11.6,4.5a15.254,15.254,0,0,0,3.4,1.765,21.454,21.454,0,0,0-.548,4.626h-5A11.336,11.336,0,0,1,9.713,12.3Zm18.5,0a11.353,11.353,0,0,1,2.148,6.391h-5a21.456,21.456,0,0,0-.548-4.626A15.255,15.255,0,0,0,28.217,12.3ZM13.643,14.252a16.868,16.868,0,0,0,5.043.817v3.617H13.122A20.931,20.931,0,0,1,13.643,14.252Zm10.643,0a20.931,20.931,0,0,1,.522,4.435H19.243V15.069A16.867,16.867,0,0,0,24.286,14.252ZM7.565,19.243h5a21.4,21.4,0,0,0,.548,4.626,15.156,15.156,0,0,0-3.4,1.765A11.348,11.348,0,0,1,7.565,19.243Zm5.556,0h5.565v3.626a16.931,16.931,0,0,0-5.043.809A20.872,20.872,0,0,1,13.122,19.243Zm6.122,0h5.565a20.871,20.871,0,0,1-.522,4.435,16.931,16.931,0,0,0-5.043-.809Zm6.122,0h5a11.364,11.364,0,0,1-2.148,6.391,15.157,15.157,0,0,0-3.4-1.765A21.4,21.4,0,0,0,25.365,19.243Zm-6.678,4.182v6.93c-1.477-.133-2.817-1.319-3.826-3.261a14.091,14.091,0,0,1-1.087-2.869A16.3,16.3,0,0,1,18.687,23.426Zm.557,0a16.3,16.3,0,0,1,4.913.8A14.093,14.093,0,0,1,23.069,27.1c-1.009,1.942-2.349,3.128-3.826,3.261Zm-5.991.983a14.646,14.646,0,0,0,1.113,2.948,7.786,7.786,0,0,0,2.243,2.765,11.392,11.392,0,0,1-6.565-4.043A14.642,14.642,0,0,1,13.252,24.408Zm11.426,0a14.643,14.643,0,0,1,3.209,1.67,11.372,11.372,0,0,1-6.574,4.052,7.787,7.787,0,0,0,2.252-2.774,14.646,14.646,0,0,0,1.113-2.948Z"
          transform="translate(-7 -7)"
          fill={fill ? fill : "#707070"}
        />
      </g>
    </svg>
  );
};

export default HomeHeaderLangIcon;
