import actionTypes from "../actionTypes";

export const validateLiveEventPasscode = (data) => ({
  type: actionTypes.quizTypes.VALIDATE_PASSCODE,
  payload: data,
});

export const resetQuizToPasswordAccessPage = () => ({
  type: actionTypes.quizTypes.RESET_QUIZ_TO_PASSCODE_ACCESS_PAGE,
  payload: null,
});

export const fetchQuizDetails = (data) => ({
  type: actionTypes.quizTypes.virtualQuiz.FETCH_QUIZ_DETAILS,
  payload: data,
});

export const postVQAssesScoreDone = (data) => ({
  type: actionTypes.quizTypes.virtualQuiz.POSTVQASSESSCORE,
  payload: data,
});

export const getVQLeaderboardDetails = (data) => ({
  type: actionTypes.quizTypes.virtualQuiz.FETCH_QUIZ_LEADERBOARD,
  payload: data,
});

export const getVQQNADetails = (data) => ({
  type: actionTypes.quizTypes.virtualQuiz.FETCH_QUIZ_QNA,
  payload: data,
});

export const getVQCorrectAnswerReport = (data) => ({
  type: actionTypes.quizTypes.virtualQuiz.FETCH_QUIZ_CORRECT_ANSWER_REPORT,
  payload: data,
});

export const setTimerDialog = (data) => ({
  type: actionTypes.quizTypes.virtualQuiz.timer.SET_TIMER_DIALOG,
  payload: data,
});

export const setSessionDialog = (data) => ({
  type: actionTypes.quizTypes.virtualQuiz.timer.SET_SESSION_DIALOG,
  payload: data,
});

export const startQuizTimer = (data) => ({
  type: actionTypes.quizTypes.virtualQuiz.timer.VQUIZ_TIMER_STARTED,
  payload: data,
});

export const endQuizTimer = (data) => ({
  type: actionTypes.quizTypes.virtualQuiz.timer.VQUIZ_TIMER_ENDED,
  payload: data,
});

export const endQuizCounter = (data) => ({
  type: actionTypes.quizTypes.virtualQuiz.timer.VQUIZ_TIMER_ENDEDCOUNT,
  payload: data,
});

export const startQuizCounter = (data) => ({
  type: actionTypes.quizTypes.virtualQuiz.timer.VQUIZ_TIMER_STARTEDCOUNT,
  payload: data,
});

export const setSignalRNegotiateAction = (data) => ({
  type: actionTypes.quizTypes.liveQuiz.SET_SIGNALR_NAGOTIATE,
  payload: data,
});

export const getUserChoiceCountLiveQuiz = (data) => ({
  type: actionTypes.quizTypes.liveQuiz.FETCH_USER_CHOICE_COUNT_LIVE_QUIZ,
  payload: data,
});

export const getBeginEndLiveQuizQuestion = (data) => ({
  type: actionTypes.quizTypes.liveQuiz.FETCH_BEGIN_END_LIVE_QUIZ_QUESTION,
  payload: data,
});

export const setLiveQuizScreenView = (data) => ({
  type: actionTypes.quizTypes.liveQuiz.SET_LIVE_QUIZ_SCREEN_VIEW,
  payload: data,
});
export const postLiveQuizAssessmentScoreAction = (data) => ({
  type: actionTypes.quizTypes.liveQuiz.POST_LIVE_QUIZ_ASSESSMENT_SCORE,
  payload: data,
});
export const getLiveQuizUserLeaderBoardAction = (data) => ({
  type: actionTypes.quizTypes.liveQuiz.GET_LIVE_QUIZ_USER_LEADERBOARD,
  payload: data,
});
export const setLiveQuizNormalExitAction = (data) => ({
  type: actionTypes.quizTypes.liveQuiz.SET_LIVE_QUIZ_NORMAL_EXIT,
  payload: data,
});
