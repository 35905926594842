import React from "react";
import { NavLink } from "react-router-dom";
import homeSidebarArrow from "../../../assets/images/homeIcons/homeSidebarArrow.svg";
import { useLocation } from "react-router";
import classes from "./HomeSidebarLink.module.scss";
import { FormattedMessage } from "react-intl";
const HomeSidebarLink = ({
  to,
  label,
  handleSidebar,
  icon: Component,
  msom,
}) => {
  const location = useLocation();
  return (
    <>
      {msom && (
        <NavLink
          to={to}
          onClick={handleSidebar}
          className={({ isActive }) => {
            return isActive
              ? classes.homeSidebarLink + " " + classes.homeSidebarLink__active
              : classes.homeSidebarLink;
          }}
        >
          <div className={classes.icon}>
            <Component active={to === location.pathname} />
          </div>
          <div className={classes.name}>{<FormattedMessage id={label} />}</div>
          <div className={classes.iconArrow}>
            <img
              src={homeSidebarArrow}
              alt="right arrow"
              className={classes.iconArrowIcon}
            />
          </div>
        </NavLink>
      )}
    </>
  );
};

export default HomeSidebarLink;
