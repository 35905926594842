import React from "react";

const HomeHeaderSearchIcon = ({ fill, stroke, circleNotRequired }) => {
  if (circleNotRequired) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.727"
        height="18.726"
        viewBox="0 0 18.727 18.726"
      >
        <path
          id="search_ic"
          d="M36.554,35.729l-5.173-5.176a7.623,7.623,0,1,0-.828.828l5.173,5.173a.6.6,0,0,0,.415.173.585.585,0,0,0,.413-1ZM25.608,32.045a6.437,6.437,0,1,1,6.437-6.437A6.445,6.445,0,0,1,25.608,32.045Z"
          transform="translate(-18 -18)"
          fill="#a4a4a4"
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41"
        height="42"
        viewBox="0 0 41 42"
      >
        <g id="search_icon" transform="translate(-989.614 -346.205)">
          <g
            id="Group_25"
            data-name="Group 25"
            transform="translate(989.4 346.594)"
          >
            <g
              id="Rectangle_2"
              data-name="Rectangle 2"
              transform="translate(0.214 -0.389)"
              fill="none"
              stroke={stroke ? stroke : "#BCBCBC"}
              strokeWidth="1"
            >
              <rect width="41" height="42" rx="20.5" stroke="none" />
              <rect
                x="0.5"
                y="0.5"
                width="40"
                height="41"
                rx="20"
                fill="none"
              />
            </g>
          </g>
          <path
            id="search_ic"
            d="M36.554,35.729l-5.173-5.176a7.623,7.623,0,1,0-.828.828l5.173,5.173a.6.6,0,0,0,.415.173.585.585,0,0,0,.413-1ZM25.608,32.045a6.437,6.437,0,1,1,6.437-6.437A6.445,6.445,0,0,1,25.608,32.045Z"
            transform="translate(983.206 340.4)"
            fill={fill ? fill : "#BCBCBC"}
          />
        </g>
      </svg>
    );
  }
};

export default HomeHeaderSearchIcon;
