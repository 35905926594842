import actionTypes from "../actionTypes";

export const isUserExistsAction = (data) => ({
  type: actionTypes.userTypes.IS_USER_EXISTS,
  payload: data,
});
export const isUserActiveAction = (data) => ({
  type: actionTypes.userTypes.IS_USER_ACTIVE,
  payload: data,
});
export const isMailSentForForgotPasswordAction = (data) => ({
  type: actionTypes.userTypes.IS_MAIL_SENT_FORGOT_PASSWORD,
  payload: data,
});
export const isPasswordResetRequiredAction = (data) => ({
  type: actionTypes.userTypes.IS_PASSWORD_RESET_REQUIRED,
  payload: data,
});
export const getUserProfileAction = (data) => ({
  type: actionTypes.userTypes.GET_USER_PROFILE,
  payload: data,
});
export const setIsPasswordResetDone = (data) => ({
  type: actionTypes.userTypes.IS_PASSWORD_RESET_DONE,
  payload: data,
});
