import actionTypes from "../actions/actionTypes";

const initialState = {
  isProductPrivacyEnable: false,
  isPrivacyPolicy: false,
  getPrivacyPolicyDone: false,
  //isPrivacyPolicyAccepted: false,
};

const privacyPolicyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.userTypes.GET_USER_PRIVACY_POLICY:
      return {
        ...state,
        isProductPrivacyEnable: payload.IsProductPrivacyEnable,
        isPrivacyPolicy: payload.IsPrivacyPolicy,
        getPrivacyPolicyDone: true,
        //isPrivacyPolicyAccepted: payload.IsPrivacyPolicyAccepted,
      };
    case actionTypes.userTypes.SET_INITIAL_PRIVACY_POLICY_STATE:
      return {
        ...state,
        isProductPrivacyEnable: false,
        isPrivacyPolicy: false,
        getPrivacyPolicyDone: false,
      };

    case actionTypes.userTypes.POST_USER_PRIVACY_POLICY:
      return {
        ...state,
        isProductPrivacyEnable: false,
        isPrivacyPolicy: false,
        // isPrivacyPolicyAccepted: false,
      };

    default:
      return state;
  }
};

export default privacyPolicyReducer;
