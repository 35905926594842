import React, { Component, useCallback } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classes from "./ReportProgressContainer.module.scss";
import profilePicture from "../../../assets/images/homeIcons/userCircleIcon.svg";
import * as d3 from "d3";
import { useLocation } from "react-router";

const ReportProgressContainer = (props) => {
    const userState = useSelector((state) => state.userReducer);
    const { profile } = userState;
    const location = useLocation();

    const chRef = React.createRef();

    const { data, colors, showNameLabel, chartDimmension, colorsReport } =
        props;

    const {
        width,
        height,
        margin,
        donutInnerArc,
        donutOuterArc,
        outerArcInner,
        outerArcOuter,
        polylinesPosition,
        labelPosition,
    } = chartDimmension;

    const drawChart = useCallback(() => {
        // const width=1000;
        // const height = 450;
        // const margin = 10;

        let radius = Math.min(width, height) / 2 - margin;
        // legend Position
        //let legendPosition = d3.arc().innerRadius(radius/1.75).outerRadius(radius);

        // Create SVG
        const svg = d3
            .select(chRef.current)
            .append("svg")
            .attr("width", width)
            .attr("height", height)

            .append("g")
            .attr(
                "transform",
                "translate(" + width / 2 + "," + height / 2 + ")"
            );

        let pie = d3
            .pie()
            .sort(null)
            .value((d) => d.value);
        let data_ready = pie(data);

        let arc = d3
            .arc()
            .innerRadius(radius * donutInnerArc) // This is the size of the donut hole
            .outerRadius(radius * donutOuterArc);

        let outerArc = d3
            .arc()
            .innerRadius(radius * outerArcInner)
            .outerRadius(radius * outerArcOuter);

        // Donut partition
        svg.selectAll("allSlices")
            .data(data_ready)
            .enter()
            .append("path")
            .attr("d", arc)
            .attr("fill", (d) => {
                return colorsReport === true
                    ? colors(d.data.name)
                    : d.data.color;
            })

            .attr("stroke", "white")
            .style("stroke-width", "0px")
            .style("opacity", "0.8");

        // Legend group and legend name
        svg.selectAll("allPolylines")
            .data(data_ready)
            .enter()
            .append("polyline")
            .attr("stroke", "black")

            .style("fill", "none")
            .attr("stroke-width", 1)
            .attr("points", (d) => {
                let posA = arc.centroid(d);
                let posB = outerArc.centroid(d);
                let posC = outerArc.centroid(d);
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                posC[0] =
                    radius * polylinesPosition * (midangle < Math.PI ? 1 : -1);
                return [posA, posB, posC];
            });

        //Label for value
        svg.selectAll("allLabels")
            .data(data_ready)
            .enter()

            .append("text")

            .text((d) => {
                return showNameLabel === true
                    ? d.data.name + " : " + d.data.value + "%"
                    : d.data.value + "%";
            })

            .attr("transform", (d) => {
                let pos = outerArc.centroid(d);

                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                pos[0] = radius * labelPosition * (midangle < Math.PI ? 1 : -1);
                return "translate(" + pos + ")";
            })

            .style("text-anchor", (d) => {
                var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
                return midangle < Math.PI ? "start" : "end";
            });
    }, [data, chRef?.current]);

    useEffect(() => {
        console.log("data", data);
        d3.select(chRef.current).selectChildren().remove();
        drawChart();
    }, [drawChart, chRef?.current]);

    return (
        <div
            className={classes.chartMain}
            style={{
                position:
                    location.pathname === "/account/myaccount"
                        ? "relative"
                        : "initial",
            }}
        >
            <div className={classes.chart} ref={chRef}></div>
            {location.pathname === "/account/myaccount" && (
                // <div className={classes.chartInner}>
                <div className={classes.profPic}>
                    <img
                        src={
                            profile?.ProfileImageUrl === "Profil" ||
                            profile?.ProfileImageUrl === "" ||
                            profile?.ProfileImageUrl === null
                                ? profilePicture
                                : profile?.ProfileImageUrl
                        }
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = profilePicture;
                        }}
                        alt="profile"
                    />
                </div>
                // </div>
            )}
        </div>
    );
};

export default ReportProgressContainer;
