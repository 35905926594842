import React from "react";

const LogoutIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.732"
      height="16.242"
      viewBox="0 0 18.732 16.242"
    >
      <g id="logout_ic" transform="translate(0)">
        <path
          id="Path_142"
          data-name="Path 142"
          d="M2.4,1.587A1.884,1.884,0,0,0,.529,3.462V15.956A1.884,1.884,0,0,0,2.4,17.83h5a1.884,1.884,0,0,0,1.874-1.874V14.082H6.153a3.154,3.154,0,0,1-3.125-3.124v-2.5A3.154,3.154,0,0,1,6.153,5.334H9.275V3.462A1.884,1.884,0,0,0,7.4,1.587Zm10.079,2.5a1.884,1.884,0,0,0-1.957,1.872v.625H6.153A1.884,1.884,0,0,0,4.279,8.459v2.5a1.885,1.885,0,0,0,1.875,1.875h4.372v.625a1.9,1.9,0,0,0,3,1.5l5-3.747a1.89,1.89,0,0,0,0-3l-5-3.749A1.872,1.872,0,0,0,12.482,4.087Z"
          transform="translate(-0.529 -1.587)"
          fill={fill ? fill : "#707070"}
        />
      </g>
    </svg>
  );
};

export default LogoutIcon;
