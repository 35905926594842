import React from "react";
import classes from "./NoConversationFound.module.scss";
const NoConversationFound = (props) => {
  return  (
    <div className={classes.noConversationFound}>
      <img src={props.imageUrl} alt={props.title}/>
      <h2>
        {props.title}
      </h2>
    </div>
  );
};

export default NoConversationFound;
