import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import getTokenFromLocal from "../utils/getToken";

const ProtectedRoute = () => {
  let location = useLocation();
  let token = getTokenFromLocal();
  return token ? <Outlet /> : <Navigate to="/" state={{ from: location }} />;
};

export default ProtectedRoute;
