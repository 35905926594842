import React from "react";

const HomeSidebarMyFavorites = ({ active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.86"
      height="15.126"
      viewBox="0 0 15.86 15.126"
    >
      <path
        id="ft4"
        d="M20.053,8.884a4.4,4.4,0,0,0-2.657-.94,5.359,5.359,0,0,0-3.469,1.525,5.4,5.4,0,0,0-3.466-1.525,4.4,4.4,0,0,0-2.655.94,4.217,4.217,0,0,0-1.783,3.957c.174,1.821,1.423,4.04,3.429,6.086,1.29,1.314,4.382,4.09,4.414,4.118a.094.094,0,0,0,.126,0c.031-.028,3.125-2.8,4.415-4.119,2-2.047,3.254-4.265,3.43-6.083A4.222,4.222,0,0,0,20.053,8.884Z"
        transform="translate(-5.999 -7.944)"
        fill={active ? "#fff" : "#404040"}
      />
    </svg>
  );
};

export default HomeSidebarMyFavorites;
