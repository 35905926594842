import actionTypes from "../actions/actionTypes";

const initialState = {
  globalSearchText:'',
  isAdvanceSearch:false,
  isAdvanceSearchLoading:false,
  isShowSearchBar:false,
  relatedResult: [],
  isDropdownText:false,
  globalCheckboxes:[],
  resultLanguage:'All'
};

const commonReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.API_REQ_STARTED:
    return { ...state};
    case actionTypes.errorTypes.API_ERROR:
      return { ...state,error: payload};
    case actionTypes.commonTypes.GET_RELATED_RESULT:
      return { ...state,relatedResult: payload };
    case actionTypes.commonTypes.GLOBAL_SEARCH_TEXT:
      return { ...state,globalSearchText: payload };
    case actionTypes.commonTypes.TOGGLE_ADVANCE_SEARCH:
      return { ...state,isAdvanceSearch: payload };
    case actionTypes.commonTypes.TOGGLE_SEARCHBAR:
      return { ...state,isShowSearchBar: payload };
    case actionTypes.commonTypes.TOGGLE_ADVANCE_SEARCH_LOADING:
      return { ...state,isAdvanceSearchLoading: payload };
    case actionTypes.commonTypes.DROPDOWN_TEXT_BOOLEAN:
      return { ...state,isDropdownText: payload };
    case actionTypes.commonTypes.UPDATE_GLOBAL_CHECKBOX:
      return { ...state, globalCheckboxes:payload};
    case actionTypes.commonTypes.UPDATE_RESULT_LANGUAGE:
      return { ...state, resultLanguage:payload};
    default:
      return state;
  }
};

export default commonReducer;
