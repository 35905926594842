import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { unSetErrorHandler } from "../../store/actions/actionHandlers/errorActionHandler";
import { errorTypes } from "../../utils/errorTypes";

const ToastError = () => {
  const dispatch = useDispatch();
  const errorState = useSelector((state) => state.errorReducer);

  const { error, errorType, isError, closeTimer } = errorState;

  const { SUCCESS, FAIL, WARN } = errorTypes;

  const arabicLanguage = localStorage.getItem("languageTerm");

  useEffect(() => {
    if (isError) {
      if (errorType === SUCCESS) {
        toast.success(error);
      } else if (errorType === WARN) {
        toast.warn(error);
      } else if (errorType === FAIL) {
        toast.error(error);
      }
      const timer = setTimeout(() => {
        dispatch(unSetErrorHandler());
      }, 3500);
      return () => clearTimeout(timer);
    }
  }, [dispatch, isError, error, errorType, FAIL, WARN, SUCCESS]);

  return (
    <ToastContainer
      position={arabicLanguage === "ar-SA" ? "top-left" : "top-right"}
      autoClose={closeTimer}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      newestOnTop
    />
  );
};

export default ToastError;
