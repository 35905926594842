// import { getToken } from "./adalConfig";

const getTokenFromLocal = () => {
  // let headers = localStorage.getItem("headers");
  // if (!headers?.TenantSlugName) {
  //   let headers = {
  //     "Content-Type": "application/json",
  //     TenantSlugName: localStorage.getItem("tenantName"),
  //     Authorization: "bearer " + getToken(),
  //   };
  //   localStorage.setItem("headers", JSON.stringify(headers));
  // }

  let token = localStorage.getItem("token");
  if (!token) {
    return false;
  }
  return token;
};

export default getTokenFromLocal;
