import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import errorReducer from "./errorReducer";
import userReducer from "./userReducer";
import homeReducer from "./homeReducer";
import contributeReducer from "./contributeReducer";
import privacyPolicyReducer from "./privacyPolicyReducer";
import connectReducer from "./connectReducer";
import commonReducer from "./commonReducer";
import globalSearchReducer from "./globalSearchReducer";
import actionTypes from "../actions/actionTypes";
import classReducer from "./classReducer";
import seriesReducer from "./seriesReducer";
import quizReducer from "./quizReducer";
import creditReducer from "./creditReducer";
import accountReducer from "./accountReducer";
import topicsReducer from "./topicsReducer";
import favoriteReducer from "./favoriteReducer";
import contactUsReducer from "./contactUsReducer";

const appReducer = combineReducers({
  loginReducer,
  errorReducer,
  userReducer,
  homeReducer,
  contributeReducer,
  privacyPolicyReducer,
  classReducer,
  seriesReducer,
  connectReducer,
  commonReducer,
  globalSearchReducer,
  quizReducer,
  creditReducer,
  accountReducer,
  topicsReducer,
  favoriteReducer,
  contactUsReducer,
});
const rootReducer = (state, action) => {
  const { type } = action;
  if (type === actionTypes.loginTypes.LOGOUT) state = undefined;
  return appReducer(state, action);
};

export default rootReducer;
