import actionTypes from "../actions/actionTypes";

const initialState = {
  classDetails: null,
  currentView: 0,
  classTab: null,
  classAssetId: null,
  classQueries: null,
  scormVersion: null,
  scormBookmark: null,
  postAssessmentScore: null,
  videoDurationForLogout: null,
  nextClass: null,
  nextClassInSeries: null,
  relatedClasses: null,
};

const classReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.classTypes.GET_CLASS_DETAILS:
      return {
        ...state,
        classDetails: payload,
      };
    case actionTypes.classTypes.SET_CURRENT_CLASS_VIEW:
      return {
        ...state,
        currentView: payload,
      };
    case actionTypes.classTypes.GET_CLASS_TAB:
      return {
        ...state,
        classTab: payload,
      };
    case actionTypes.classTypes.GET_CLASS_ASSET_ID:
      return {
        ...state,
        classAssetId: payload,
      };
    case actionTypes.classTypes.GET_CLASS_QUERIES:
      return {
        ...state,
        classQueries: payload,
      };
    case actionTypes.classTypes.GET_SCORM_VERSION:
      return {
        ...state,
        scormVersion: payload,
      };
    case actionTypes.classTypes.GET_SCORM_BOOKMARK:
      return {
        ...state,
        scormBookmark: payload,
      };
    case actionTypes.classTypes.UPDATE_VIDEO_DURATION_FOR_LOGOUT:
      return {
        ...state,
        videoDurationForLogout: payload,
      };
    case actionTypes.classTypes.POST_ASSESSMENT_SCORE:
      return {
        ...state,
        postAssessmentScore: payload,
      };
    case actionTypes.classTypes.GET_NEXT_CLASS:
      return {
        ...state,
        nextClass: payload,
      };
    case actionTypes.classTypes.GET_NEXT_CLASS_IN_SERIES:
      return {
        ...state,
        nextClassInSeries: payload,
      };
    case actionTypes.classTypes.GET_RELATED_CLASSES:
      return {
        ...state,
        relatedClasses: payload,
      };
    default:
      return state;
  }
};

export default classReducer;
