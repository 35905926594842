import React from "react";

const CloseIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.8"
      height="13.8"
      viewBox="0 0 13.8 13.8"
    >
      <path
        id="close_icon"
        d="M32.65,26.8l5.6-5.625a.742.742,0,0,0-1.05-1.05L31.6,25.75l-5.625-5.625a.742.742,0,1,0-1.05,1.05l5.6,5.625-5.6,5.625a.725.725,0,0,0,0,1.05.725.725,0,0,0,1.05,0L31.6,27.85l5.625,5.625a.725.725,0,0,0,1.05,0,.725.725,0,0,0,0-1.05Z"
        transform="translate(-24.7 -19.9)"
        fill={fill ? fill : "#707070"}
      />
    </svg>
  );
};
export default CloseIcon;
