import {
  ADD_REMOVE_FAVORITE_CLASS_API_URL,
  GET_CLASS_ASSET_ID_API_URL,
  GET_CLASS_DETAILS_API_URL,
  GET_NEXT_CLASS_API_URL,
  GET_NEXT_CLASS_IN_SERIES_API_URL,
  GET_QUERIES_CLASS_API_URL,
  GET_RELATED_CLASSES_API_URL,
  GET_SCORM_BOOKMARK_API_URL,
  GET_SCORM_VERSION_API_URL,
  GET_USER_CLASS_ACCESS_API_URL,
  POST_ASSESSMENT_SCORE_API_URL,
  POST_QUERY_CLASS_API_URL,
  POST_QUERY_COMMENT_API_URL,
  POST_RATING_CLASS_API_URL,
  POST_SURVEY_API_URL,
  UPDATE_CLASS_PROGRESS_STATUS_API_URL,
  UPDATE_VIDEO_PLAYED_DURATION_API_URL,
} from "../../../utils/devApiContants";
import * as classActionCreator from "../actionCreators/classActionCreator";
import {
  getCompletedClassesAction,
  getInProgressClassesAction,
  getNotInProgressClassesAction,
  getTrendingClassesAction,
} from "../actionCreators/homeActionCreator";
import { getClassesBySeriesIdAction } from "../actionCreators/seriesActionCreator";
import { logoutHandler } from "./loginActionHandler";
import {
  setErrorHandler,
  setLoadingHandler,
  unSetLoadingHandler,
} from "./errorActionHandler";
import { errorTypes } from "../../../utils/errorTypes";
import * as topicsActionCreator from "../actionCreators/topicsActionCreator";
import * as globalSearchActionCreator from "../actionCreators/globalSearchActionCreator";
import { FormattedMessage } from "react-intl";
import {
  getOtherFavoriteClassesAction,
  getOtherFavoriteClassesCountAction,
} from "../actionCreators/favoriteActionCreator";
const { SUCCESS, WARN, FAIL } = errorTypes;

export const getClassTabHandler = (data) => (dispatch) => {
  dispatch(classActionCreator.getClassTabAction(data));
};

export const getUserClassAccessActionHandler =
  (classID) => async (dispatch) => {
    dispatch(setLoadingHandler());
    return await fetch(GET_USER_CLASS_ACCESS_API_URL + classID, {
      method: "GET",
      headers: JSON.parse(localStorage.getItem("headers")),
    })
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log("error " + err);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };
export const getClassDetailsHandler =
  (classID, navigate, variant) => async (dispatch) => {
    dispatch(setLoadingHandler());
    dispatch(classActionCreator.getClassDetailsAction("null"));
    let url = null;
    if (variant)
      url = GET_CLASS_DETAILS_API_URL + classID + "?locale=" + variant;
    else url = GET_CLASS_DETAILS_API_URL + classID;

    await fetch(url, {
      method: "GET",
      headers: JSON.parse(localStorage.getItem("headers")),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          // localStorage.clear();
          // sessionStorage.clear();
          // navigate("/");
          dispatch(logoutHandler(navigate));
        }
      })
      .then((result) => {
        const convertInSecs = (time) => {
          if (time === null) {
            time = "00:00:00";
          }
          let a = time.split(":");
          let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
          return seconds;
        };
        const getWidthInPercentage = (durPlayed, totalDur) => {
          if (durPlayed === 0 && totalDur === 0) return 0;

          let perWidth = (durPlayed / totalDur) * 100;
          if (perWidth > 100) return 100;
          else return perWidth;
        };

        if (result.Mediatype === 1) {
          let videoDuration = convertInSecs(result?.VideoDuration);
          let videoDurationPlayed = convertInSecs(result?.VideoDurationPlayed);
          let width = getWidthInPercentage(videoDurationPlayed, videoDuration);

          result.width = width;
          result.videoDurationInNumber = videoDuration;
          result.videoDurationPlayedInNumber = videoDurationPlayed;
          dispatch(classActionCreator.getClassDetailsAction(result));
          dispatch(getClassAssetIdHandler(result.AssetId)).then(() => {
            dispatch(unSetLoadingHandler());
          });
        } else {
          dispatch(unSetLoadingHandler());
          if (result.Mediatype === 2) {
            let videoDuration = convertInSecs(result?.VideoDuration);
            let videoDurationPlayed = convertInSecs(
              result?.VideoDurationPlayed
            );
            let width = getWidthInPercentage(
              videoDurationPlayed,
              videoDuration
            );

            result.width = width;
            result.videoDurationInNumber = videoDuration;
            result.videoDurationPlayedInNumber = videoDurationPlayed;
            dispatch(classActionCreator.getClassDetailsAction(result));
          }
          if (result.Mediatype === 9) {
            dispatch(getScormVersionHandler(result.ID));
            dispatch(getScormBookmarkHandler(result.ID));
            dispatch(classActionCreator.getClassDetailsAction(result));
          } else {
            dispatch(classActionCreator.getClassDetailsAction(result));
          }
        }
        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("error " + error);
      });
    // .finally(() => {
    //   dispatch(unSetLoadingHandler());
    // });
  };

export const getClassAssetIdHandler = (id) => async (dispatch) => {
  // dispatch(setLoadingHandler());

  return await fetch(GET_CLASS_ASSET_ID_API_URL + id, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => response.json())
    .then((result) => {
      localStorage.setItem("classAssetId", JSON.stringify(result));
      dispatch(classActionCreator.getClassAssetIdAction(result));
      return result;
    })

    .catch((error) => {
      console.log("error " + error);
    });
  // .finally(() => {
  //   dispatch(unSetLoadingHandler());
  // });
};

export const getClassQueriesHandler = (classId) => async (dispatch) => {
  dispatch(setLoadingHandler());
  await fetch(GET_QUERIES_CLASS_API_URL, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => response.json())
    .then((result) => {
      const queriesById = result?.filter(
        (query) => query?.RelatedId === classId.toString()
      );
      dispatch(classActionCreator.getClassQueriesAction(queriesById));
    })

    .catch((error) => {
      console.log("error " + error);
    })
    .finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const postQueryCommentActionHandler =
  (queries, classId, navigate) => async (dispatch) => {
    dispatch(setLoadingHandler());
    await fetch(POST_QUERY_COMMENT_API_URL, {
      method: "PUT",
      headers: JSON.parse(localStorage.getItem("headers")),
      body: JSON.stringify(queries),
    })
      .then((response) => {
        if (response.status === 415) {
          dispatch(setErrorHandler(FAIL, `Unsupported Media Type`));
        } else if (response.status === 401) {
          dispatch(logoutHandler(navigate));
        } else {
          dispatch(
            setErrorHandler(
              SUCCESS,
              <FormattedMessage id="forum.commentPostedSuccessfully" />
            )
          );
          return response.json();
        }
      })
      .then((result) => {
        dispatch(getClassQueriesHandler(classId));
      })
      .catch((error) => {
        console.log("error " + error);
      });
  };

export const postQueryClassActionHandler =
  (queries, classId, navigate) => async (dispatch) => {
    dispatch(setLoadingHandler());
    await fetch(POST_QUERY_CLASS_API_URL, {
      method: "POST",
      headers: JSON.parse(localStorage.getItem("headers")),
      body: JSON.stringify(queries),
    })
      .then((response) => {
        if (response.status === 415) {
          dispatch(setErrorHandler(FAIL, `Unsupported Media Type`));
        } else if (response.status === 401) {
          dispatch(logoutHandler(navigate));
        } else {
          dispatch(
            setErrorHandler(
              SUCCESS,
              <FormattedMessage id="qa.queryAddedSuccessfully" />
            )
          );
          return response.json();
        }
      })
      .then((result) => {
        dispatch(getClassQueriesHandler(classId));
      })
      .catch((error) => {
        console.log("error " + error);
      });
  };

export const getScormVersionHandler = (id) => async (dispatch) => {
  dispatch(setLoadingHandler());

  await fetch(GET_SCORM_VERSION_API_URL + id, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => response.json())
    .then((result) => {
      dispatch(classActionCreator.getScormVersionAction(result));
      // dispatch(unSetLoadingHandler());
    })
    .catch((error) => {
      // dispatch(unSetLoadingHandler());
      console.log("error " + error);
    })
    .finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const getScormBookmarkHandler = (id) => async (dispatch) => {
  dispatch(setLoadingHandler());

  await fetch(GET_SCORM_BOOKMARK_API_URL + id, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => response.json())
    .then((result) => {
      dispatch(classActionCreator.getScormBookmarkAction(result));
      // dispatch(unSetLoadingHandler());
    })
    .catch((error) => {
      // dispatch(unSetLoadingHandler());
      console.log("error " + error);
    })
    .finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const updateClassProgressStatusActionHandler =
  (classId, status) => async () => {
    return await fetch(
      UPDATE_CLASS_PROGRESS_STATUS_API_URL + classId + "&status=" + status,

      {
        method: "PUT",
        headers: JSON.parse(localStorage.getItem("headers")),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.log("error " + error);
      });
  };
export const updateVideoPlayedDuration =
  (classId, videoDuration) => async (dispatch) => {
    // UPDATE_VIDEO_PLAYED_DURATION
    return await fetch(
      UPDATE_VIDEO_PLAYED_DURATION_API_URL +
        classId +
        "&duration=" +
        videoDuration,

      {
        method: "PUT",
        headers: JSON.parse(localStorage.getItem("headers")),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        console.log("error " + error);
      });
  };
export const addRemoveFavoriteClassHandler =
  (id, isAddTrue, navigate, tab) => async (dispatch, getState) => {
    // dispatch(setLoadingHandler());

    await fetch(
      ADD_REMOVE_FAVORITE_CLASS_API_URL +
        id +
        "/favouritenew?IsClassFav=" +
        isAddTrue,
      {
        method: "PUT",
        headers: JSON.parse(localStorage.getItem("headers")),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          dispatch(logoutHandler(navigate));
        }
      })
      .then((json) => {
        // dispatch(getAssignedClassesHandle  r(1, 20, "en-GB", navigate));
        if (tab === "assigned") {
          let updatedArray = getState().homeReducer?.notInProgressClasses?.map(
            (shotclass) => {
              if (shotclass?.ID !== id) {
                return shotclass;
              } else {
                return { ...shotclass, IsFavourite: json.IsFavourite };
              }
            }
          );
          dispatch(getNotInProgressClassesAction(updatedArray));
        } else if (tab === "inProgress") {
          let updatedArray = getState().homeReducer?.inProgressClasses?.map(
            (shotclass) => {
              if (shotclass?.ID !== id) {
                return shotclass;
              } else {
                return { ...shotclass, IsFavourite: json.IsFavourite };
              }
            }
          );
          dispatch(getInProgressClassesAction(updatedArray));
        } else if (tab === "trending") {
          let updatedArray = getState().homeReducer?.trendingClasses?.map(
            (shotclass) => {
              if (shotclass?.ID !== id) {
                return shotclass;
              } else {
                return { ...shotclass, IsFavourite: json.IsFavourite };
              }
            }
          );
          dispatch(getTrendingClassesAction(updatedArray));
        } else if (tab === "completed") {
          let updatedArray = getState().homeReducer?.completedClasses?.map(
            (shotclass) => {
              if (shotclass?.ID !== id) {
                return shotclass;
              } else {
                return { ...shotclass, IsFavourite: json.IsFavourite };
              }
            }
          );
          dispatch(getCompletedClassesAction(updatedArray));
        } else if (tab === "series") {
          let updatedArray =
            getState().seriesReducer?.classesBySeriesId?.Content?.map(
              (shotclass) => {
                if (shotclass?.ID !== id) {
                  return shotclass;
                } else {
                  return { ...shotclass, IsFavourite: json.IsFavourite };
                }
              }
            );
          dispatch(
            getClassesBySeriesIdAction({
              ...getState().seriesReducer?.classesBySeriesId,
              Content: updatedArray,
            })
          );
        } else if (tab === "related") {
          let updatedArray = getState().classReducer?.relatedClasses?.map(
            (shotclass) => {
              if (shotclass?.ID !== id) {
                return shotclass;
              } else {
                return { ...shotclass, IsFavourite: json.IsFavourite };
              }
            }
          );
          dispatch(classActionCreator.getRelatedClassesAction(updatedArray));
        } else if (tab === "topicDetails") {
          let topicShotclass =
            getState().topicsReducer?.topicDetails?.TopicCourses?.Shotclass;
          let updatedArray = topicShotclass?.map((shotclass) => {
            if (shotclass?.ID !== id) {
              return shotclass;
            } else {
              return { ...shotclass, IsFavourite: json.IsFavourite };
            }
          });
          let topicResult = {
            ...getState().topicsReducer?.topicDetails,
            TopicCourses: {
              ...getState().topicsReducer?.topicDetails?.TopicCourses,
              Shotclass: updatedArray,
            },
          };

          dispatch(topicsActionCreator.getTopicDetailsAction(topicResult));
        } else if (tab === "globalSearch") {
          let globalSearch = getState().globalSearchReducer.globalSearch;
          let selectedItem = globalSearch.find(
            (x) => x.GlobalSearch.ShotClassId === id
          );
          selectedItem.GlobalSearch.IsClassFavourite = json.IsFavourite;
          dispatch(globalSearchActionCreator.getSearchResultDone(globalSearch));
        } else if (tab === "other") {
          let updatedArray =
            getState().favoriteReducer?.otherFavoriteClasses?.map(
              (shotclass) => {
                if (shotclass?.ID !== id) {
                  return shotclass;
                } else {
                  return { ...shotclass, IsFavourite: json.IsFavourite };
                }
              }
            );
          dispatch(getOtherFavoriteClassesAction(updatedArray));
          dispatch(getOtherFavoriteClassesCountAction(updatedArray?.length));
        }
        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        console.log("error " + error);
      });
  };

export const postAssessmentScoreActionHandler =
  (obj, classID, tab, navigate, finish) => async (dispatch) => {
    dispatch(setLoadingHandler());    
    return await fetch(
      POST_ASSESSMENT_SCORE_API_URL + classID + "/assessScores",
      {
        method: "POST",
        headers: JSON.parse(localStorage.getItem("headers")),
        body: JSON.stringify(obj),
      }
    )
      .then((response) => {
        let tenanatObj = JSON.parse(localStorage.getItem("tenantData"));
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 401 &&
          tenanatObj.SecurityProviderName !== "aad"
        ) {
          dispatch(logoutHandler(navigate));
        }
      })
      .then((result) => {
        dispatch(
          classActionCreator.postAssessmentScoreAction(
            obj?.IsUserAssessmentSuccess
          )
        );
        if (!!tab && tab?.type === "series") {
          dispatch(getNextClassOfSeriesActionHandler(tab?.seriesId));
        } else {
          dispatch(getNextClassActionHandler());
        }
        // dispatch(unSetLoadingHandler());
        if (finish) {
          dispatch(classActionCreator.setCurrentViewAction(3));
        }
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };

export const getNextClassOfSeriesActionHandler =
  (seriesId) => async (dispatch) => {
    dispatch(setLoadingHandler());

    fetch(GET_NEXT_CLASS_IN_SERIES_API_URL + seriesId, {
      method: "GET",
      headers: JSON.parse(localStorage.getItem("headers")),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.RelatedSeries?.length > 0) {
          let updatedRelatedSeries = result?.RelatedSeries?.map((series) => {
            return { ...series, Type: "Program" };
          });
          dispatch(
            classActionCreator.getNextClassInSeriesAction({
              ...result,
              RelatedSeries: updatedRelatedSeries,
            })
          );
        } else {
          dispatch(classActionCreator.getNextClassInSeriesAction(result));
        }
      })
      .catch((error) => {
        console.log("error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };
export const getNextClassActionHandler = () => async (dispatch) => {
  dispatch(setLoadingHandler());

  fetch(GET_NEXT_CLASS_API_URL, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.Variants !== undefined) {
        dispatch(classActionCreator.getNextClassAction(result));
        // navigate(`/shotclass/${result.Variants[0]?.Id}`);
      } else {
        dispatch(classActionCreator.getNextClassAction([]));
      }
    })
    .catch((error) => {
      console.log("error " + error);
    })
    .finally(() => {
      dispatch(unSetLoadingHandler());
    });
};

export const getRelatedClassesActionHandler =
  (locale, relClassId, setCurrentView) => async (dispatch) => {
    dispatch(setLoadingHandler());
    fetch(GET_RELATED_CLASSES_API_URL + locale + "&searchText=" + relClassId, {
      method: "GET",
      headers: JSON.parse(localStorage.getItem("headers")),
    })
      .then((response) => response.json())
      .then((result) => {
        dispatch(classActionCreator.getRelatedClassesAction(result.Content));
        if (result.Content.length > 0) {
          setCurrentView(4);
        } else {
          dispatch(
            setErrorHandler(
              WARN,
              <FormattedMessage id= "class.noRelatedClassGotoHome" />
            )
          );
        }
      })
      .catch((error) => {
        console.log("error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };

export const postRatingForClassActionHandler =
  (ratingObj, classId) => (dispatch) => {
    dispatch(setLoadingHandler());

    fetch(POST_RATING_CLASS_API_URL + classId + "/rating", {
      method: "PUT",
      headers: JSON.parse(localStorage.getItem("headers")),
      body: JSON.stringify(ratingObj),
    })
      .then((response) => response.json())
      .then((json) => {
        // dispatch(putRatingDone(json));
        console.log(json);
      })
      .catch((error) => {
        console.log("error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };

export const setSelectedQuizOptionActionHandler =
  (questionId, answerId) => (dispatch, getState) => {
    let classDetailsForQuiz = getState().classReducer?.classDetails;
    let updatedClassDetailsForQuiz =
      classDetailsForQuiz?.AssessmentSet?.QuestionList.map((question) => {
        if (question.ID !== questionId) {
          return { ...question };
        } else if (question.ID === questionId) {
          question.isAnswered = true;
          question.ChoiceList.map((answer) => {
            if (answer.ID !== answerId) {
              answer.Isselected = false;
            } else if (answer.ID === answerId) {
              answer.Isselected = true;
              if (answer.Isselected === true && answer.IsAnswer === true) {
                question.correctlyAnswered = true;
              } else {
                question.correctlyAnswered = false;
              }
            }
            return { ...answer };
          });
        }
        return { ...question };
      });
    console.log(updatedClassDetailsForQuiz);
    let dataAfterUpdate = {
      ...classDetailsForQuiz,
      AssessmentSet: {
        ...classDetailsForQuiz.AssessmentSet,
        QuestionList: updatedClassDetailsForQuiz,
      },
    };
    dispatch(classActionCreator.getClassDetailsAction(dataAfterUpdate));
  };

export const clearSelectedQuizOptionActionHandler =
  () => (dispatch, getState) => {
    let classDetailsForQuiz = getState().classReducer?.classDetails;
    let updatedClassDetailsForQuiz =
      classDetailsForQuiz?.AssessmentSet?.QuestionList.map((question) => {
        question.isAnswered = false;
        question.correctlyAnswered = false;
        question.ChoiceList.map((answer) => {
          answer.Isselected = false;
          return { ...answer };
        });
        return { ...question };
      });
    let dataAfterUpdate = {
      ...classDetailsForQuiz,
      AssessmentSet: {
        ...classDetailsForQuiz.AssessmentSet,
        QuestionList: updatedClassDetailsForQuiz,
      },
    };
    dispatch(classActionCreator.getClassDetailsAction(dataAfterUpdate));
  };

export const postSurveyActionHandler =
  (obj, tab, navigate, setCurrentView, setViewNumber) => async (dispatch) => {
    dispatch(setLoadingHandler());

    fetch(POST_SURVEY_API_URL, {
      method: "POST",
      headers: JSON.parse(localStorage.getItem("headers")),
      body: JSON.stringify(obj),
    })
      .then((response) => {
        if (response.status === 401) {
          dispatch(logoutHandler(navigate));
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        dispatch(classActionCreator.postAssessmentScoreAction(true));
        if (!!tab && tab?.type === "series") {
          dispatch(getNextClassOfSeriesActionHandler(tab?.seriesId));
        } else {
          dispatch(getNextClassActionHandler());
        }
        if (setViewNumber) {
          setCurrentView(setViewNumber);
        }
      })

      .catch((error) => {
        console.log("error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };

export const setSelectedSurveyOptionActionHandler =
  (questionId, answerId) => (dispatch, getState) => {
    let classDetailsForSurvey = getState().classReducer?.classDetails;
    let updatedClassDetailsForSurvey =
      classDetailsForSurvey?.Survey?.Questions.map((question) => {
        if (question.Id !== questionId) {
          return { ...question };
        } else if (question.Id === questionId) {
          question.isAnswered = true;
          question?.Choices?.map((answer) => {
            if (answer.Id !== answerId) {
              answer.Isselected = false;
            } else if (answer.Id === answerId) {
              answer.Isselected = true;
            }
            return { ...answer };
          });
        }
        return { ...question };
      });
    let dataAfterUpdate = {
      ...classDetailsForSurvey,
      Survey: {
        ...classDetailsForSurvey.Survey,
        Questions: updatedClassDetailsForSurvey,
      },
    };
    dispatch(classActionCreator.getClassDetailsAction(dataAfterUpdate));
  };

export const setSelectedSurveyMultiOptionActionHandler =
  (questionId, answerId) => (dispatch, getState) => {
    let classDetailsForSurvey = getState().classReducer?.classDetails;
    let updatedClassDetailsForSurvey =
      classDetailsForSurvey?.Survey?.Questions.map((question) => {
        if (question.Id !== questionId) {
          return { ...question };
        } else if (question.Id === questionId) {
          let count = 0;
          question?.Choices?.map((answer) => {
            if (answer.Id === answerId) {
              if (answer.Isselected === true) {
                answer.Isselected = false;
              } else {
                answer.Isselected = true;
              }
            }
            if (answer.Isselected === true) {
              count++;
            }
            return { ...answer };
          });
          if (count > 0) {
            question.isAnswered = true;
          } else {
            question.isAnswered = false;
          }
        }
        return { ...question };
      });
    let dataAfterUpdate = {
      ...classDetailsForSurvey,
      Survey: {
        ...classDetailsForSurvey.Survey,
        Questions: updatedClassDetailsForSurvey,
      },
    };
    dispatch(classActionCreator.getClassDetailsAction(dataAfterUpdate));
  };

export const setEnteredTextSurveyActionHandler =
  (questionId, text) => (dispatch, getState) => {
    let classDetailsForSurvey = getState().classReducer?.classDetails;
    let updatedClassDetailsForSurvey =
      classDetailsForSurvey?.Survey?.Questions.map((question) => {
        if (question.Id !== questionId) {
          return { ...question };
        } else if (question.Id === questionId) {
          if (text?.length > 0 && text?.length < 301) {
            question.textAnswer = text;
            question.isAnswered = true;
          } else if (text?.length > 300) {
            dispatch(setErrorHandler(WARN, <FormattedMessage id="class.only300CharactersAllowed" />));
            question.isAnswered = true;
          } else if (text?.length === 0) {
            question.textAnswer = text;
            question.isAnswered = false;
          }
        }
        return { ...question };
      });
    let dataAfterUpdate = {
      ...classDetailsForSurvey,
      Survey: {
        ...classDetailsForSurvey.Survey,
        Questions: updatedClassDetailsForSurvey,
      },
    };
    dispatch(classActionCreator.getClassDetailsAction(dataAfterUpdate));
  };
