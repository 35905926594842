import { applyMiddleware, createStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const composeEnhancers = composeWithDevTools(
  { trace: true } 
  );

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);