import actionTypes from "../actionTypes";

export const getUserPrivacyPolicyAction = (data) => ({
  type: actionTypes.userTypes.GET_USER_PRIVACY_POLICY,
  payload: data,
});
export const setInitialPrivacyPolicyStateAction = () => ({
  type: actionTypes.userTypes.SET_INITIAL_PRIVACY_POLICY_STATE,
});

export const postUserPrivacyPolicyAction = (data) => ({
  type: actionTypes.userTypes.POST_USER_PRIVACY_POLICY,
  payload: data,
});
