import React from "react";
import classes from "./Button.module.scss";
const Button = ({ type, label, className, link, disabled, onClick }) => {
  return type === "submit" ? (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${classes.pinkButton} ${className}`}
    >
      {label}
    </button>
  ) : (
    <a href={link} target="_blank" rel="noreferrer">
      <button
        type={type}
        disabled={disabled}
        className={`${classes.pinkButton} ${className}`}
      >
        {label}
      </button>
    </a>
  );
};

export default Button;
