import React from "react";

const HomeSidebarConnect = ({ active }) => {
  return (
    <svg
      id="ft-3"
      xmlns="http://www.w3.org/2000/svg"
      width="24.018"
      height="9.237"
      viewBox="0 0 24.018 9.237"
    >
      <path
        id="Path_43"
        data-name="Path 43"
        d="M29.472,985.44a.528.528,0,0,1,.2.043.549.549,0,0,1,.3.3.528.528,0,0,1,.043.2v8.145a.57.57,0,0,1-.043.213.525.525,0,0,1-.119.171.561.561,0,0,1-.179.119.528.528,0,0,1-.2.042H21.327a.57.57,0,0,1-.213-.042.6.6,0,0,1-.29-.29.57.57,0,0,1-.043-.213v-3.522H15.194a4.623,4.623,0,1,1,0-1.092h5.586v-3.531a.529.529,0,0,1,.043-.2.561.561,0,0,1,.119-.179.524.524,0,0,1,.171-.119.57.57,0,0,1,.213-.043Zm-18.858,1.092a3.527,3.527,0,1,0,3.48,4.077h-3.48a.581.581,0,0,1-.213-.043.548.548,0,0,1-.3-.3.519.519,0,0,1-.043-.2.562.562,0,0,1,.043-.213.52.52,0,0,1,.512-.333h3.48A3.518,3.518,0,0,0,10.614,986.532Z"
        transform="translate(-6 -985.44)"
        fill={active ? "#fff" : "#404040"}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default HomeSidebarConnect;
