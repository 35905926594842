import React from "react";

const AccountUserIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.077"
      height="19.018"
      viewBox="0 0 17.077 19.018"
    >
      <g id="user_ic" transform="translate(0 0)">
        <path
          id="Path_136"
          data-name="Path 136"
          d="M11.959,61.259c-.023,1.027-3.421,3.564-3.421,3.564s-3.4-2.537-3.421-3.564A5.636,5.636,0,0,0,0,66.871c0,3.113,2.523,2.943,5.636,2.943h5.8c3.113,0,5.636.17,5.636-2.943A5.636,5.636,0,0,0,11.959,61.259Z"
          transform="translate(0 -50.798)"
          fill={fill ? fill : "#707070"}
        />
        <path
          id="Path_137"
          data-name="Path 137"
          d="M72.119,27.632s0,.007,0,.01h0Z"
          transform="translate(-59.804 -22.913)"
          fill={fill ? fill : "#707070"}
        />
        <path
          id="Path_138"
          data-name="Path 138"
          d="M27.188,0a4.7,4.7,0,0,0-4.7,4.7c0,2.6,2.1,6.5,4.7,6.5s4.7-3.9,4.7-6.5A4.7,4.7,0,0,0,27.188,0Z"
          transform="translate(-18.649 0)"
          fill={fill ? fill : "#707070"}
        />
      </g>
    </svg>
  );
};

export default AccountUserIcon;
