import React from "react";

const MissedVoteIcon = ({ fill }) => {
  return (
    <svg
      id="missed_vote_ic"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="8.171"
      viewBox="0 0 16 8.171"
    >
      <path
        id="Path_547"
        data-name="Path 547"
        d="M19.5,8h-5a.5.5,0,0,0,0,1h3.793l-5.732,5.732a1.536,1.536,0,0,1-2.121,0L4.854,9.146a.5.5,0,1,0-.707.707l5.586,5.586a2.5,2.5,0,0,0,3.535,0L19,9.707V13.5a.5.5,0,0,0,1,0v-5a.5.5,0,0,0-.5-.5Z"
        transform="translate(-4 -8)"
      />
    </svg>
  );
};
export default MissedVoteIcon;
