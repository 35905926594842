import {
  GET_USER_PROFILE_API_URL,
  ISPASSWORD_RESET_REQUIRED_API_URL,
  ISUSER_ACTIVE_API_URL,
  ISUSER_EXISTS_API_URL,
  PUT_EDIT_PROFILE,
  SENTMAIL_FORGOTPASSWORD_API_URL,
} from "../../../utils/devApiContants";
import { errorTypes } from "../../../utils/errorTypes";
import * as userActionCreator from "../actionCreators/userActionCreator";
import {
  setErrorHandler,
  setLoadingHandler,
  unSetLoadingHandler,
} from "./errorActionHandler";
import { logoutHandler } from "./loginActionHandler";

const { FAIL } = errorTypes;

//settingUpHeaders
const myHeaders = {
  "Content-Type": "application/json",
  TenantSlugName: localStorage.getItem("tenantSlugName"),
};

export const isUserExistsHandler =
  (tenantName, userName) => async (dispatch) => {
    dispatch(setLoadingHandler());
    await fetch(
      ISUSER_EXISTS_API_URL +
        "?tenantName=" +
        tenantName +
        "&loginId=" +
        userName,
      {
        method: "GET",
        headers: myHeaders,
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          dispatch(
            setErrorHandler(FAIL, "Error while checking user existence")
          );
          return;
        } else if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        console.log(result);
        if (result === false) {
          dispatch(userActionCreator.isUserExistsAction(false));
        } else {
          dispatch(userActionCreator.isUserExistsAction(true));
        }
        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("Fetch error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };
export const isUserActiveHandler =
  (tenantName, userName) => async (dispatch) => {
    dispatch(setLoadingHandler());

    await fetch(
      ISUSER_ACTIVE_API_URL +
        "?tenantName=" +
        tenantName +
        "&loginId=" +
        userName,
      {
        method: "GET",
        headers: myHeaders,
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          dispatch(
            setErrorHandler(
              FAIL,
              "Error while checking whether user active is not"
            )
          );
          return;
        } else if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        if (result === false) {
          dispatch(userActionCreator.isUserActiveAction(false));
        } else {
          dispatch(userActionCreator.isUserActiveAction(true));
        }
        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("Fetch error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };
export const isMailSentForForgotPasswordHandler =
  (tenantName, userName) => async (dispatch) => {
    dispatch(setLoadingHandler());

    let data = {
      loginId: userName,
      tenantName: tenantName,
    };
    await fetch(SENTMAIL_FORGOTPASSWORD_API_URL, {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders,
    })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(setErrorHandler(FAIL, "Error while sending mail"));
          return;
        } else if (response.status === 200) {
          return response;
        }
      })
      .then((result) => {
        console.log(result);
        if (result !== undefined) {
          dispatch(userActionCreator.isMailSentForForgotPasswordAction(true));
        } else {
          dispatch(userActionCreator.isMailSentForForgotPasswordAction(false));
        }
        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("Fetch error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };
export const isPasswordResetRequiredHandler =
  (tenantName, userName) => async (dispatch) => {
    dispatch(setLoadingHandler());

    let data = {
      loginId: userName,
      tenantName: tenantName,
      isResetRequired: true,
    };
    await fetch(ISPASSWORD_RESET_REQUIRED_API_URL, {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders,
    })
      .then((response) => {
        if (response.status !== 200) {
          dispatch(
            setErrorHandler(
              FAIL,
              "Error while checking whether user required reset password is not"
            )
          );
          return;
        } else if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        console.log(result);

        if (result !== undefined) {
          dispatch(userActionCreator.isPasswordResetRequiredAction(result));
        } else {
          dispatch(userActionCreator.isPasswordResetRequiredAction(result));
        }
        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("Fetch error " + error);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };

export const getUserProfileHandler = () => async (dispatch) => {
  return await fetch(GET_USER_PROFILE_API_URL, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then(async (response) => {
      const responseData = await (response.status === 401
        ? response.text()
        : response.json());
      return { data: responseData, status: response.status };
    })
    .then((result) => {
      localStorage.setItem("userId", result?.data?.ID);
      dispatch(userActionCreator.getUserProfileAction(result?.data));
      return result;
    })
    .catch((error) => {
      console.log("error " + error);
    });
};

export const editProfile = (userProfile, navigate) => async (dispatch) => {
  dispatch(setLoadingHandler());
  await fetch(PUT_EDIT_PROFILE, {
    method: "PUT",
    headers: JSON.parse(localStorage.getItem("headers")),
    body: JSON.stringify(userProfile),
  })
    .then((response) => {
      if (response.status === 415) {
        dispatch(setErrorHandler(FAIL, "Unsupported Media Type"));
      } else if (response.status === 401) {
        dispatch(logoutHandler(navigate));
      } else {
        dispatch(getUserProfileHandler());
      }
    })
    .catch((error) => {
      console.log("error " + error);
    })
    .finally(() => {
      dispatch(unSetLoadingHandler());
    });
};
