import React, { useEffect, useState } from "react";
import { SHOTCLASS_API_URL } from "../../../utils/devApiContants";
import ToggleSwitch from "../../CommonComponents/ToggleSwitch/ToggleSwitch";
import Logo from "../../CommonComponents/Logo/Logo";
import CloseIcon from "../../CustomSvgIcons/CloseIcon_nobg";
import magnifyingGlass from "../../../assets/images/ci-8.png";
import HomeHeaderSearchIcon from "../../CustomSvgIcons/HomeHeaderSearchIcon";
import HomeHeaderLangIcon from "../../CustomSvgIcons/HomeHeaderLangIcon";
import worldLang from "../../../assets/images/homeIcons/langChangeIcon.svg";
import cupLeaderBoard from "../../../assets/images/cup.png";
import profilePicture from "../../../assets/images/homeIcons/userCircleIcon.svg";
import useComponentVisible from "../../../hooks/useComponentVisible";
import HomeHeaderLanguageChange from "../HomeHeaderLanguageChange/HomeHeaderLanguageChange";
import HomeHeaderProfile from "../HomeHeaderProfile/HomeHeaderProfile";
import LeaderboardModal from "../LeaderboardModal/LeaderboardModal";
import { useSelector, useDispatch } from "react-redux";
import classes from "./HomeHeaderLinks.module.scss";
import axios from "axios";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  toggleSearchBarAction,
  toggleAdvanceSearchAction,
  toggleAdvanceSearchLoadingAction,
  getRelatedResultAction,
  updateGlobalSearchTextAction,
  toggleDropdownText,
  updateGlobalCheckboxes,
  updateResultLanguage,
} from "../../../store/actions/actionCreators/commonActionCreator";

import { useNavigate } from "react-router";
import {
  removeGlobalSearchResult,
  getSearchResultDone,
} from "../../../store/actions/actionCreators/globalSearchActionCreator";
import { getSearchResult } from "../../../store/actions/actionHandlers/globalSearchActionHandler";
import useWindowWidthLessThan from "../../../hooks/useWindowWidthLessThan";
let sourceToken = null;
const HomeHeaderLinks = ({
  resethandler,
  setIsSidebar,
  isDarkTheme,
  intl,
  ref,
}) => {
  let relatedResultTimer = null;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userReducer);
  const commonState = useSelector((state) => state.commonReducer);
  const { profile } = userState;
  const homeState = useSelector((state) => state.homeReducer);
  const { notifications, leaderboardData } = homeState;
  const [leaderBoardPoints, setLederBoardPoints] = useState(0);
  const [advanchSearchFlag, setAdvanchSearchFlag] = useState(false);
  const [globalSearchVal, setGlobalSearchVal] = useState(
    commonState.globalSearchText
  );

  const tenantData = JSON.parse(localStorage.getItem("tenantData"));
  const themeData = JSON.parse(localStorage.getItem("themeData"));
  // console.log(tenantData?.ActiveFeatures.includes("Gamification"));
  const isWidthLessThan = useWindowWidthLessThan(600);

  useEffect(() => {
    if (leaderboardData) {
      setLederBoardPoints(0);
      leaderboardData?.map((data) =>
        data.UserLeaderBoardScores.map((points) => {
          if (points.ScoreType === "Points") {
            setLederBoardPoints(
              (state) => state + parseInt(points.ScoreValueMap)
            );
          }
          return points;
        })
      );
    }
  }, [leaderboardData]);

  useEffect(() => {
    const tenantSlugName = localStorage.getItem("tenantSlugName");
    if (
      (tenantSlugName?.toLowerCase() === "search" &&
        !window.location.href.includes("/globalSearch")) ||
      (tenantSlugName?.toLowerCase() === "qaaadsep26" &&
        !window.location.href.includes("/globalSearch")) ||
      (tenantSlugName?.toLowerCase() === "ltimindtree" &&
        !window.location.href.includes("/globalSearch"))
    ) {
      dispatch(toggleAdvanceSearchAction(true));
    } 
    // else {
    //   if (!window.location.href.includes("/globalSearch"))
    //     dispatch(toggleAdvanceSearchAction(false));
    // }
  }, [dispatch]);

  const [languageRef, isLanguageVisible, setIsLanguageVisible] =
    useComponentVisible();
  const [profileRef, isProfileVisible, setIsProfileVisible] =
    useComponentVisible();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleClickLanguagePopUp = () => {
    setIsLanguageVisible(!isLanguageVisible);
  };
  const handleClickProfilePopUp = () => {
    // console.log("==" + isProfileVisible);
    setIsProfileVisible(!isProfileVisible);
    // console.log("====" + isProfileVisible);
  };
  const handleClickLeaderboardPopUp = () => {
    if (leaderBoardPoints !== 0) {
      setIsModalVisible(!isModalVisible);
    }
    setIsSidebar(false);
    setIsLanguageVisible(false);
    setIsProfileVisible(false);
  };
  const advanceSearchSwitchhandler = (e) => {
    dispatch(toggleAdvanceSearchAction(!commonState.isAdvanceSearch));
    // dispatch(updateGlobalSearchTextAction(''));
    // dispatch(getSearchResultDone([]));
  };

  const globalSearch = (event) => {
    if (event.target.value.trim() != "") {
      if (event.keyCode == 13) {
        //hide the suggestion in the dropdown
        if (sourceToken) {
          sourceToken.cancel();
          dispatch(getRelatedResultAction([]));
          dispatch(toggleAdvanceSearchLoadingAction(false));
        }

        dispatch(updateGlobalSearchTextAction(event.target.value));
        dispatch(
          getSearchResult(
            document.getElementById("globalSearchBar").value,
            true,
            true
          )
        );
        // resethandler();
        dispatch(updateGlobalCheckboxes([])); //reset to all on enter
        navigate("/globalSearch");
      }
    }
  };
  // console.log(commonState);
  const showRelatedResult = (event) => {
    // debugger;
    let queryString = event.target.value;
    if (sourceToken) {
      sourceToken.cancel();
    }
    clearTimeout(relatedResultTimer);
    if (event.target.value.length > 3 && commonState.isAdvanceSearch) {
      relatedResultTimer = setTimeout(() => {
        // console.log("api call");

        dispatch(toggleAdvanceSearchLoadingAction(true));
        sourceToken = axios.CancelToken.source();

        axios
          .get(
            `${SHOTCLASS_API_URL}/SemanticSearchs/autocomplete?query=${queryString}`,
            {
              //method: 'GET',
              cancelToken: sourceToken.token,
              headers: JSON.parse(localStorage.getItem("headers")),
            }
          )
          // .then(
          // 	response =>response.json()
          // )
          .then((json) => {
            // console.log(json.data);
            dispatch(toggleAdvanceSearchLoadingAction(false));
            if (json.data.suggestions.Suggestions !== null) {
              //selecting max 10 suggestion
              let suggestionlength = json.data.suggestions.Suggestions.length;
              if (suggestionlength > 10) {
                json.data.suggestions.Suggestions =
                  json.data.suggestions.Suggestions.slice(0, 10);
              }
              dispatch(
                getRelatedResultAction(json.data.suggestions.Suggestions)
              );
            } else {
              dispatch(getRelatedResultAction([]));
            }
          })
          .catch((error) => {
            console.log("Search Dropdown list Error " + error);
          });
      }, 1000); //search dropdown suggetions 0==>1000
    } else if (event.target.value.length <= 3) {
      dispatch(getRelatedResultAction([]));
      dispatch(toggleAdvanceSearchLoadingAction(false));
    }
  };

  const clearSearch = () => {
    if (document.getElementById("globalSearchBar").value != "") {
      document.getElementById("globalSearchBar").value = "";
      // dispatch(updateGlobalSearchTextAction(''));
      if (sourceToken) {
        sourceToken.cancel();
      }
      dispatch(getRelatedResultAction([]));
      dispatch(toggleAdvanceSearchLoadingAction(false));
    } else {
      // console.log("second");
      dispatch(toggleSearchBarAction(false));
    }
  };

  const handleSearchBarRedirect = () => {
    dispatch(toggleSearchBarAction(true));
    dispatch(toggleAdvanceSearchLoadingAction(false));
    if (!window.location.href.includes("/globalSearch")) {
      navigate("/globalSearch");
      dispatch(toggleAdvanceSearchAction(false));
      dispatch(updateResultLanguage("All"));
      dispatch(updateGlobalCheckboxes([]));
      dispatch(updateGlobalSearchTextAction(""));
      dispatch(removeGlobalSearchResult());
      if (sourceToken) {
        sourceToken.cancel();
        dispatch(getRelatedResultAction([]));
        dispatch(toggleAdvanceSearchLoadingAction(false));
      }
    }
  };

  useEffect(() => {
    if (document.getElementById("globalSearchBar") && !isWidthLessThan) {
      dispatch(getRelatedResultAction([]));
      dispatch(toggleAdvanceSearchLoadingAction(false));
    }
  }, [dispatch, document.getElementById("globalSearchBar")]);
  return (
    <div className={classes.homeHeaderLinks}>
      {!commonState.isShowSearchBar && isWidthLessThan && (
        <div
          className={classes.homeHeaderLinks__logo}
          onClick={handleSearchBarRedirect}
        >
          {/* <div className={classes.homeHeaderLinks__logo__magnifyingGlass}>
            <Logo logoImage={magnifyingGlass} />
      </div> */}
          <HomeHeaderSearchIcon
            fill={isDarkTheme && "#fff"}
            stroke={isDarkTheme && "#fff"}
          />
        </div>
      )}
      {isWidthLessThan
        ? commonState.isShowSearchBar && (
            <div
              className={classes.homeHeader_searchContainer}
              style={{
                backgroundColor: isDarkTheme
                  ? themeData?.CustomData?.PrimaryColor1
                  : "#fff",
              }}
            >
              <div className={classes.homeHeader_searchbar}>
                {tenantData?.ActiveFeatures.includes("SemanticSearch") && (
                  <div
                    className={
                      isDarkTheme
                        ? classes.switchIcon + " " + classes.switchIconDark
                        : classes.switchIcon
                    }
                  >
                    <FormattedMessage id="search.advanced" />
                    <ToggleSwitch
                      checked={commonState.isAdvanceSearch}
                      onSwitchChange={advanceSearchSwitchhandler}
                    />
                  </div>
                )}
                <div className={classes.input_box}>
                  <input
                    id={"globalSearchBar"}
                    type="text"
                    className={
                      isDarkTheme
                        ? classes.input_box__input +
                          " " +
                          classes.input_box__inputDark
                        : classes.input_box__input
                    }
                    //value={commonState?.globalSearchText}
                    autoComplete={"off"}
                    placeholder={
                      isWidthLessThan
                        ? intl.formatMessage({
                            id: "Search.course",
                          })
                        : intl.formatMessage({
                            id: "search.searchCourseClassSeries",
                          })
                    }
                    autoFocus={true}
                    onKeyDown={(e) => globalSearch(e)}
                    onChange={(e) => {
                      // dispatch(updateGlobalSearchTextAction(e.target.value));
                      showRelatedResult(e);
                    }}
                  />
                  <button
                    className={classes.remove_search}
                    onClick={clearSearch}
                  >
                    <CloseIcon fill={isDarkTheme && "#fff"} />
                  </button>
                </div>
                <div className={classes.icon_box}>
                  <button
                    className={classes.searchIcon}
                    onClick={() => {
                      if (
                        document
                          .getElementById("globalSearchBar")
                          .value.trim() != ""
                      ) {
                        if (sourceToken) {
                          sourceToken.cancel();
                          dispatch(getRelatedResultAction([]));
                          dispatch(toggleAdvanceSearchLoadingAction(false));
                        }
                        dispatch(
                          updateGlobalSearchTextAction(
                            document.getElementById("globalSearchBar").value
                          )
                        );
                        dispatch(
                          getSearchResult(
                            document.getElementById("globalSearchBar").value,
                            true,
                            true
                          )
                        );
                        dispatch(updateGlobalCheckboxes([])); //reset to all on search click
                        // resethandler();
                        navigate("/globalSearch");
                      }
                    }}
                  >
                    {/* <Logo logoImage={magnifyingGlass} /> */}
                    <HomeHeaderSearchIcon
                      circleNotRequired
                      fill={isDarkTheme && "#fff"}
                      stroke={isDarkTheme && "#fff"}
                    />
                  </button>
                </div>
              </div>
              {((commonState.relatedResult.length > 0 &&
                commonState.isAdvanceSearch) ||
                commonState.isAdvanceSearchLoading) && (
                <div className={classes.relatedResult}>
                  {commonState.isAdvanceSearchLoading ? (
                    <div className={classes.result_item}>
                      <span className={classes.result_item_text}>
                        <FormattedMessage id="search.loading" />
                      </span>
                    </div>
                  ) : (
                    commonState.relatedResult.map((item, index) => (
                      <div
                        className={classes.result_item}
                        key={index}
                        onClick={() => {
                          dispatch(updateGlobalSearchTextAction(item));
                          dispatch(toggleDropdownText(true));
                          // this.props.history.push('/globalSearch');
                          navigate("/globalSearch");
                          document.getElementById("globalSearchBar").value =
                            item;
                          // resethandler();
                          dispatch(updateGlobalCheckboxes([])); //reset to all on suggetion click
                          dispatch(getRelatedResultAction([]));
                        }}
                      >
                        <span className={classes.result_item_text}>{item}</span>
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          )
        : !commonState.isShowSearchBar && (
            <div
              className={classes.homeHeader_searchContainer}
              style={{
                backgroundColor: isDarkTheme
                  ? themeData?.CustomData?.PrimaryColor1
                  : "#fff",
              }}
            >
              <div className={classes.homeHeader_searchbar}>
                {tenantData?.ActiveFeatures.includes("SemanticSearch") && (
                  <div
                    className={
                      isDarkTheme
                        ? classes.switchIcon + " " + classes.switchIconDark
                        : classes.switchIcon
                    }
                  >
                    <FormattedMessage id="search.advanced" />
                    <ToggleSwitch
                      checked={commonState.isAdvanceSearch}
                      onSwitchChange={advanceSearchSwitchhandler}
                    />
                  </div>
                )}
                <div className={classes.input_box}>
                  <input
                    id={"globalSearchBar"}
                    type="text"
                    className={
                      isDarkTheme
                        ? classes.input_box__input +
                          " " +
                          classes.input_box__inputDark
                        : classes.input_box__input
                    }
                    // value={commonState.globalSearchText}
                    autoComplete={"off"}
                    placeholder={
                      isWidthLessThan
                        ? intl.formatMessage({
                            id: "Search.course",
                          })
                        : intl.formatMessage({
                            id: "search.searchCourseClassSeries",
                          })
                    }
                    autoFocus={true}
                    onKeyDown={(e) => globalSearch(e)}
                    onChange={(e) => {
                      // dispatch(updateGlobalSearchTextAction(e.target.value));
                      showRelatedResult(e);
                    }}
                  />
                  <button
                    className={classes.remove_search}
                    onClick={clearSearch}
                  >
                    <CloseIcon fill={isDarkTheme && "#fff"} />
                  </button>
                </div>
                <div className={classes.icon_box}>
                  <button
                    className={classes.searchIcon}
                    onClick={() => {
                      if (
                        document
                          .getElementById("globalSearchBar")
                          .value.trim() != ""
                      ) {
                        if (sourceToken) {
                          sourceToken.cancel();
                          dispatch(getRelatedResultAction([]));
                          dispatch(toggleAdvanceSearchLoadingAction(false));
                        }
                        dispatch(
                          updateGlobalSearchTextAction(
                            document.getElementById("globalSearchBar").value
                          )
                        );
                        dispatch(
                          getSearchResult(
                            document.getElementById("globalSearchBar").value,
                            true,
                            true
                          )
                        );
                        dispatch(updateGlobalCheckboxes([])); //reset to all on search click
                        // resethandler();
                        navigate("/globalSearch");
                      }
                    }}
                  >
                    {/* <Logo logoImage={magnifyingGlass} /> */}
                    <HomeHeaderSearchIcon
                      circleNotRequired
                      fill={isDarkTheme && "#fff"}
                      stroke={isDarkTheme && "#fff"}
                    />
                  </button>
                </div>
              </div>
              {((commonState.relatedResult.length > 0 &&
                commonState.isAdvanceSearch) ||
                commonState.isAdvanceSearchLoading) && (
                <div className={classes.relatedResult}>
                  {commonState.isAdvanceSearchLoading ? (
                    <div className={classes.result_item}>
                      <span className={classes.result_item_text}>
                        <FormattedMessage id="search.loading" />
                      </span>
                    </div>
                  ) : (
                    commonState.relatedResult.map((item, index) => (
                      <div
                        className={classes.result_item}
                        key={index}
                        onClick={() => {
                          dispatch(updateGlobalSearchTextAction(item));
                          dispatch(toggleDropdownText(true));
                          // this.props.history.push('/globalSearch');
                          navigate("/globalSearch");
                          document.getElementById("globalSearchBar").value =
                            item;
                          // resethandler();
                          dispatch(updateGlobalCheckboxes([])); //reset to all on suggetion click
                          dispatch(getRelatedResultAction([]));
                        }}
                      >
                        <span className={classes.result_item_text}>{item}</span>
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          )}

      <div
        className={
          classes.homeHeaderLinks__logo +
          " " +
          classes.homeHeaderLinks__logo__worldLangContainer
        }
      >
        <div
          onClick={handleClickLanguagePopUp}
          className={classes.homeHeaderLinks__logo__worldLang}
        >
          {/* <Logo logoImage={worldLang} /> */}
          <HomeHeaderLangIcon fill={isDarkTheme && "#fff"} />
          <div style={{ color: isDarkTheme ? "#fff" : "#000" }}>
            {localStorage.getItem("languageTerm")
              ? localStorage
                  .getItem("languageTerm")
                  ?.split("-")[0]
                  ?.toLocaleUpperCase()
              : "EN"}
          </div>
        </div>
        {isLanguageVisible ? (
          <HomeHeaderLanguageChange
            reference={languageRef}
            handleClickLanguagePopUp={handleClickLanguagePopUp}
          />
        ) : (
          <></>
        )}
      </div>
      {tenantData?.ActiveFeatures.includes("Gamification") && (
        <div
          className={classes.homeHeaderLinks__logo}
          onClick={handleClickLeaderboardPopUp}
        >
          <div className={classes.homeHeaderLinks__logo__leaderBoardScore}>
            <Logo logoImage={cupLeaderBoard} />
            <div
              className={classes.homeHeaderLinks__logo__leaderBoardScore__score}
            >
              {leaderBoardPoints}
            </div>
          </div>
          {isModalVisible ? (
            <LeaderboardModal closeModal={handleClickLeaderboardPopUp} />
          ) : (
            <></>
          )}
        </div>
      )}
      <div className={classes.homeHeaderLinks__logo}>
        <div
          className={classes.homeHeaderLinks__logo__profile}
          onClick={handleClickProfilePopUp}
        >
          <img
            src={
              profile?.ProfileImageUrl === "Profil" ||
              profile?.ProfileImageUrl === "" ||
              profile?.ProfileImageUrl === null
                ? profilePicture
                : profile?.ProfileImageUrl
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = profilePicture;
            }}
            className={
              isDarkTheme
                ? classes.homeHeaderLinks__logo__profile__profileImage +
                  " " +
                  classes.homeHeaderLinks__logo__profile__profileImageDark
                : classes.homeHeaderLinks__logo__profile__profileImage
            }
            alt="profile"
          />
          <div
            className={
              isDarkTheme
                ? classes.homeHeaderLinks__logo__profile__notificationCount +
                  " " +
                  classes.homeHeaderLinks__logo__profile__notificationCountDark
                : classes.homeHeaderLinks__logo__profile__notificationCount
            }
          >
            {notifications?.TotalNotifications > 99
              ? "99+"
              : notifications?.TotalNotifications}
          </div>
        </div>
        {isProfileVisible ? (
          <HomeHeaderProfile
            reference={profileRef}
            handleClickProfilePopUp={handleClickProfilePopUp}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default injectIntl(HomeHeaderLinks);
