import actionTypes from "../actionTypes";

export const setErrorAction = (errorType, error, closeTimer) => ({
  type: actionTypes.errorTypes.SET_ERROR,
  payload: { errorType, error, closeTimer },
});
export const unSetErrorAction = () => ({
  type: actionTypes.errorTypes.UNSET_ERROR,
});

export const setLoadingAction = () => ({
  type: actionTypes.errorTypes.SET_LOADING,
});
export const unSetLoadingAction = () => ({
  type: actionTypes.errorTypes.UNSET_LOADING,
});
