import actionTypes from "../actions/actionTypes";
import { FormattedMessage } from "react-intl";
const initialState = {
  allLanguages: null,
  selectedLanguage: "en",
  notifications: null,
  themeStyles: null,
  isIterate: true,
  resetTimer: new Date(),
  isRedirectedToTopics: false,
  assignedClasses: null,
  assignedClassesDone: false,
  assignedClassesCount: 0,
  completedClasses: null,
  completedClassesDone: false,
  completedClassesCount: 0,
  trendingClasses: null,
  trendingClassesDone: false,
  trendingClassesCount: 0,
  inProgressClasses: null,
  inProgressClassesDone: false,
  inProgressClassesCount: 0,
  notInProgressClasses: null,
  notInProgressClassesDone: false,
  notInProgressClassesCount: 0,
  assignedSeries: null,
  assignedSeriesDone: false,
  assignedSeriesCount: 0,
  completedSeries: null,
  completedSeriesDone: false,
  completedSeriesCount: 0,
  trendingSeries: null,
  trendingSeriesDone: false,
  trendingSeriesCount: 0,
  inProgressSeries: null,
  inProgressSeriesDone: false,
  inProgressSeriesCount: 0,
  notInProgressSeries: null,
  notInProgressSeriesDone: false,
  notInProgressSeriesCount: 0,
  leaderboardData: null,
  TrainingTypes: [],
  homeFilter: {
    sortBy: [
      {
        id: 1,
        name: "All",
        isSelected: true,
      },
      { id: 2, name: "Name", isSelected: false },
      { id: 3, name: "Classes", isSelected: false },
      { id: 4, name: "Series", isSelected: false },
    ],
    sortByStatus: [
      {
        id: 1,
        name: "All Courses",
        isSelected: true,
      },
      { id: 2, name: "In-Progress", isSelected: false },
      { id: 3, name: "Not Started", isSelected: false },
      { id: 4, name: "Completed", isSelected: false },
    ],
    filterByType: [],
  },
  homeFilterSelected: {
    sortBy: "All",
    sortByStatus: "All Courses",
    filterByType: "All",
  },
};

const homeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.homeTypes.GET_ALL_LANGUAGES:
      return {
        ...state,
        allLanguages: payload,
      };
    case actionTypes.homeTypes.SET_LANGUAGE:
      return {
        ...state,
        selectedLanguage: payload,
      };
    case actionTypes.homeTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      };
    case actionTypes.homeTypes.GET_LEADERBOARD:
      return {
        ...state,
        leaderboardData: payload,
      };
    case actionTypes.homeTypes.SET_THEME_STYLES:
      return {
        ...state,
        themeStyles: payload,
      };
    case actionTypes.homeTypes.RESET_TIMER:
      return {
        ...state,
        resetTimer: new Date(),
      };
    case actionTypes.homeTypes.IS_REDIRECTED_TO_TOPICS:
      return {
        ...state,
        isRedirectedToTopics: true,
      };

    case actionTypes.homeTypes.GET_ASSIGNED_CLASSES:
      return {
        ...state,
        assignedClasses: payload,
      };
    case actionTypes.homeTypes.SET_LOADING_ASSIGNED_CLASSES:
      return {
        ...state,
        assignedClassesDone: payload,
      };
    case actionTypes.homeTypes.GET_ASSIGNED_CLASSES_COUNT:
      return {
        ...state,
        assignedClassesCount: payload,
      };

    case actionTypes.homeTypes.GET_COMPLETED_CLASSES:
      return {
        ...state,
        completedClasses: payload,
      };
    case actionTypes.homeTypes.SET_LOADING_COMPLETED_CLASSES:
      return {
        ...state,
        completedClassesDone: payload,
      };
    case actionTypes.homeTypes.GET_COMPLETED_CLASSES_COUNT:
      return {
        ...state,
        completedClassesCount: payload,
      };

    case actionTypes.homeTypes.GET_TRENDING_CLASSES:
      return {
        ...state,
        trendingClasses: payload,
      };
    case actionTypes.homeTypes.SET_LOADING_TRENDING_CLASSES:
      return {
        ...state,
        trendingClassesDone: payload,
      };
    case actionTypes.homeTypes.GET_TRENDING_CLASSES_COUNT:
      return {
        ...state,
        trendingClassesCount: payload,
      };
    case actionTypes.homeTypes.GET_INPROGRESS_CLASSES:
      return {
        ...state,
        inProgressClasses: payload,
      };
    case actionTypes.homeTypes.SET_LOADING_INPROGRESS_CLASSES:
      return {
        ...state,
        inProgressClassesDone: payload,
      };
    case actionTypes.homeTypes.GET_INPROGRESS_CLASSES_COUNT:
      return {
        ...state,
        inProgressClassesCount: payload,
      };

    case actionTypes.homeTypes.GET_NOTINPROGRESS_CLASSES:
      return {
        ...state,
        notInProgressClasses: payload,
      };
    case actionTypes.homeTypes.SET_LOADING_NOTINPROGRESS_CLASSES:
      return {
        ...state,
        notInProgressClassesDone: payload,
      };
    case actionTypes.homeTypes.GET_NOTINPROGRESS_CLASSES_COUNT:
      return {
        ...state,
        notInProgressClassesCount: payload,
      };

    case actionTypes.homeTypes.GET_ASSIGNED_SERIES:
      return {
        ...state,
        assignedSeries: payload,
      };
    case actionTypes.homeTypes.SET_LOADING_ASSIGNED_SERIES:
      return {
        ...state,
        assignedSeriesDone: payload,
      };
    case actionTypes.homeTypes.GET_ASSIGNED_SERIES_COUNT:
      return {
        ...state,
        assignedSeriesCount: payload,
      };

    case actionTypes.homeTypes.GET_COMPLETED_SERIES:
      return {
        ...state,
        completedSeries: payload,
      };
    case actionTypes.homeTypes.SET_LOADING_COMPLETED_SERIES:
      return {
        ...state,
        completedSeriesDone: payload,
      };
    case actionTypes.homeTypes.GET_COMPLETED_SERIES_COUNT:
      return {
        ...state,
        completedSeriesCount: payload,
      };

    case actionTypes.homeTypes.GET_TRENDING_SERIES:
      return {
        ...state,
        trendingSeries: payload,
      };
    case actionTypes.homeTypes.SET_LOADING_TRENDING_SERIES:
      return {
        ...state,
        trendingSeriesDone: payload,
      };
    case actionTypes.homeTypes.GET_TRENDING_SERIES_COUNT:
      return {
        ...state,
        trendingSeriesCount: payload,
      };

    case actionTypes.homeTypes.GET_INPROGRESS_SERIES:
      return {
        ...state,
        inProgressSeries: payload,
      };
    case actionTypes.homeTypes.SET_LOADING_INPROGRESS_SERIES:
      return {
        ...state,
        inProgressSeriesDone: payload,
      };
    case actionTypes.homeTypes.GET_INPROGRESS_SERIES_COUNT:
      return {
        ...state,
        inProgressSeriesCount: payload,
      };

    case actionTypes.homeTypes.GET_NOTINPROGRESS_SERIES:
      return {
        ...state,
        notInProgressSeries: payload,
      };
    case actionTypes.homeTypes.SET_LOADING_NOTINPROGRESS_SERIES:
      return {
        ...state,
        notInProgressSeriesDone: payload,
      };
    case actionTypes.homeTypes.GET_NOTINPROGRESS_SERIES_COUNT:
      return {
        ...state,
        notInProgressSeriesCount: payload,
      };
    case actionTypes.homeTypes.HOME_GET_TRAININGTYPE:
      return {
        ...state,
        TrainingTypes: payload,
        homeFilter: {
          ...state.homeFilter,
          filterByType: [
            {
              TypeId: -1,
              Name: "All",
              TenantId: 0,
              CanDelete: false,
              isSelected: true,
            },
            ...payload,
          ],
        },
      };
    case actionTypes.homeTypes.SET_HOME_FILTER:
      return {
        ...state,
        homeFilter: payload,
      };
    case actionTypes.homeTypes.SET_HOME_FILTER_SELECTED:
      return {
        ...state,
        homeFilterSelected: payload,
      };
    case actionTypes.homeTypes.SET_HOME_BANNER_ITERATION:
      return {
        ...state,
        isIterate: payload,
      };
    case actionTypes.homeTypes.SET_INITIAL_CLASSES_SERIES_STATE:
      return {
        ...state,
        assignedClasses: null,
        assignedClassesDone: false,
        assignedClassesCount: 0,
        completedClasses: null,
        completedClassesDone: false,
        completedClassesCount: 0,
        trendingClasses: null,
        trendingClassesDone: false,
        trendingClassesCount: 0,
        inProgressClasses: null,
        inProgressClassesDone: false,
        inProgressClassesCount: 0,
        notInProgressClasses: null,
        notInProgressClassesDone: false,
        notInProgressClassesCount: 0,
        assignedSeries: null,
        assignedSeriesDone: false,
        assignedSeriesCount: 0,
        completedSeries: null,
        completedSeriesDone: false,
        completedSeriesCount: 0,
        trendingSeries: null,
        trendingSeriesDone: false,
        trendingSeriesCount: 0,
        inProgressSeries: null,
        inProgressSeriesDone: false,
        inProgressSeriesCount: 0,
        notInProgressSeries: null,
        notInProgressSeriesDone: false,
        notInProgressSeriesCount: 0,
      };

    default:
      return state;
  }
};

export default homeReducer;
