import React from "react";

const NoInternetFound = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="289.087"
      height="365.252"
      viewBox="0 0 289.087 365.252"
    >
      <g
        id="Group_6444"
        data-name="Group 6444"
        transform="translate(-538.457 -173.748)"
      >
        <text
          id="Oh_"
          data-name="Oh!"
          transform="translate(606 510)"
          fill={fill ? fill : "#d31b61"}
          fontSize="97"
          fontFamily="ProximaNova-Bold, Proxima Nova"
          fontWeight="700"
        >
          <tspan x="0" y="0">
            Oh!
          </tspan>
        </text>
        <g
          id="undraw_internet_on_the_go_re_vben"
          transform="translate(538.457 173.749)"
        >
          <path
            id="Path_1910"
            data-name="Path 1910"
            d="M509.84,187.892h-220.6a1.415,1.415,0,0,0-1.414-1.392H279.14a1.415,1.415,0,0,0-1.414,1.392H275.6a1.415,1.415,0,0,0-1.414-1.392h-8.688a1.415,1.415,0,0,0-1.414,1.392h-7.742a16.965,16.965,0,0,0-16.946,16.946V205c-.027,0-.052-.008-.079-.008h-.2a1.417,1.417,0,0,0-1.417,1.417v16.572a1.417,1.417,0,0,0,1.417,1.417h.2c.027,0,.053-.006.079-.008V379.806a16.965,16.965,0,0,0,16.946,16.945h253.5a16.965,16.965,0,0,0,16.946-16.946V204.837A16.965,16.965,0,0,0,509.84,187.892Zm11.845,191.914a11.859,11.859,0,0,1-11.845,11.845h-253.5a11.859,11.859,0,0,1-11.845-11.845V204.837a11.859,11.859,0,0,1,11.845-11.845h253.5a11.859,11.859,0,0,1,11.845,11.845Z"
            transform="translate(-237.699 -186.5)"
            fill="#ccc"
          />
          <path
            id="Path_1911"
            data-name="Path 1911"
            d="M515.808,400h-253.5a14.7,14.7,0,0,1-14.679-14.679V210.349a14.7,14.7,0,0,1,14.679-14.679h253.5a14.7,14.7,0,0,1,14.679,14.679V385.317A14.7,14.7,0,0,1,515.808,400Z"
            transform="translate(-243.667 -192.012)"
            fill="#e6e6e6"
          />
          <path
            id="Path_1912"
            data-name="Path 1912"
            d="M533.016,405.986H279.36a3.235,3.235,0,0,1-3.231-3.231V228.541a3.234,3.234,0,0,1,3.231-3.231H533.016a3.234,3.234,0,0,1,3.231,3.231V402.755A3.235,3.235,0,0,1,533.016,405.986Z"
            transform="translate(-260.797 -209.827)"
            fill="#fff"
          />
          <circle
            id="Ellipse_416"
            data-name="Ellipse 416"
            cx="1.213"
            cy="1.213"
            r="1.213"
            transform="translate(8.49 104.305)"
            fill="#fff"
          />
          <path
            id="Path_1913"
            data-name="Path 1913"
            d="M408.548,283.092H354.662a15.962,15.962,0,0,0-15.944,15.944v4.871a.74.74,0,0,0-.936.723v3.746a.74.74,0,0,0,.936.723v5.671a.74.74,0,0,0-.936.723v9.364a.74.74,0,0,0,.936.723V329a.74.74,0,0,0-.936.723v9.364a.74.74,0,0,0,.936.723v98.884a15.962,15.962,0,0,0,15.944,15.944h53.886a15.962,15.962,0,0,0,15.944-15.944V299.036A15.962,15.962,0,0,0,408.548,283.092Zm12.573,155.6a12.587,12.587,0,0,1-12.573,12.573H354.662a12.587,12.587,0,0,1-12.573-12.573V299.036a12.587,12.587,0,0,1,12.573-12.573h53.886a12.587,12.587,0,0,1,12.573,12.573Z"
            transform="translate(-297.853 -244.556)"
            fill="#ccc"
          />
          <path
            id="Path_1914"
            data-name="Path 1914"
            d="M412.216,455.4H358.33a14.462,14.462,0,0,1-14.445-14.445V301.293a14.462,14.462,0,0,1,14.445-14.445h53.886a14.462,14.462,0,0,1,14.445,14.445V440.951A14.462,14.462,0,0,1,412.216,455.4Z"
            transform="translate(-301.521 -246.813)"
            fill="#e6e6e6"
          />
          <path
            id="Path_1915"
            data-name="Path 1915"
            d="M418.626,456.535H362.077a9.754,9.754,0,0,1-9.743-9.743V302.223a9.754,9.754,0,0,1,9.743-9.743h7.375a1.612,1.612,0,0,1,1.61,1.61,4.762,4.762,0,0,0,4.757,4.757h28.316a4.762,4.762,0,0,0,4.757-4.757,1.612,1.612,0,0,1,1.61-1.61h8.124a9.754,9.754,0,0,1,9.743,9.743V446.792A9.754,9.754,0,0,1,418.626,456.535Z"
            transform="translate(-306.6 -250.199)"
            fill="#fff"
          />
          <path
            id="Path_1916"
            data-name="Path 1916"
            d="M442.443,298.438H435.7a.749.749,0,1,1,0-1.5h6.742a.749.749,0,0,1,0,1.5Z"
            transform="translate(-356.256 -252.879)"
            fill="#fff"
          />
          <circle
            id="Ellipse_417"
            data-name="Ellipse 417"
            cx="0.375"
            cy="0.375"
            r="0.375"
            transform="translate(90.494 44.622)"
            fill="#fff"
          />
          <path
            id="Path_1917"
            data-name="Path 1917"
            d="M466.415,388.778l.078,1.743a147.31,147.31,0,0,1,30.2,2.209v-1.767A148.711,148.711,0,0,0,466.415,388.778Z"
            transform="translate(-375.167 -308.015)"
            fill="#ccc"
          />
          <path
            id="Path_1918"
            data-name="Path 1918"
            d="M438.188,399.41q-.1,1-.169,2.013-.138,1.991-.138,4.014a58.307,58.307,0,0,0,41.666,55.85V349.577A58.278,58.278,0,0,0,438.188,399.41Z"
            transform="translate(-358.016 -284.516)"
            fill={fill ? fill : "#d31b61"}
          />
          <circle
            id="Ellipse_418"
            data-name="Ellipse 418"
            cx="1.149"
            cy="1.149"
            r="1.149"
            transform="translate(49.948 78.826)"
            fill="#ccc"
          />
          <circle
            id="Ellipse_419"
            data-name="Ellipse 419"
            cx="2.269"
            cy="2.269"
            r="2.269"
            transform="translate(46.38 122.658)"
            fill={fill ? fill : "#d31b61"}
          />
          <circle
            id="Ellipse_420"
            data-name="Ellipse 420"
            cx="1.643"
            cy="1.643"
            r="1.643"
            transform="translate(89.732 63.862)"
            fill="#ccc"
          />
          <circle
            id="Ellipse_421"
            data-name="Ellipse 421"
            cx="1.311"
            cy="1.311"
            r="1.311"
            transform="translate(52.679 155.661)"
            fill="#ccc"
          />
          <circle
            id="Ellipse_422"
            data-name="Ellipse 422"
            cx="1.42"
            cy="1.42"
            r="1.42"
            transform="translate(79.052 173.799)"
            fill={fill ? fill : "#d31b61"}
          />
          <path
            id="Path_1919"
            data-name="Path 1919"
            d="M294.448,76.648h-2.8v-2.8h-1.5v2.8h-2.8v1.5h2.8v2.8h1.5v-2.8h2.8Z"
            transform="translate(-172.703 -44.385)"
            fill={fill ? fill : "#d31b61"}
          />
          <circle
            id="Ellipse_423"
            data-name="Ellipse 423"
            cx="4.896"
            cy="4.896"
            r="4.896"
            transform="translate(96.494 106.233)"
            opacity="0.1"
          />
          <circle
            id="Ellipse_424"
            data-name="Ellipse 424"
            cx="4.896"
            cy="4.896"
            r="4.896"
            transform="translate(98.942 140.726)"
            opacity="0.1"
          />
          <path
            id="Path_1920"
            data-name="Path 1920"
            d="M416.257,423.1c-6.008-4.034-10.482-8.014-13.3-11.829s-3.813-7.244-2.979-10.219,3.477-5.391,7.856-7.182,10.281-2.87,17.51-3.193l-.078-1.743c-15.479.694-25.057,4.83-26.968,11.647s4.121,15.329,16.986,23.967c10.49,7.044,24.541,13.55,40.264,18.7v-1.836C440.2,436.341,426.5,429.978,416.257,423.1Z"
            transform="translate(-334.017 -308.171)"
            fill="#ccc"
          />
          <path
            id="Path_1921"
            data-name="Path 1921"
            d="M440.462,475.915q-1.065-.716-2.067-1.43-.1,1-.169,2.013.621.433,1.265.865c10.49,7.045,24.541,13.55,40.263,18.7v-1.836C464.4,489.156,450.706,482.792,440.462,475.915Z"
            transform="translate(-358.224 -359.591)"
            opacity="0.1"
          />
          <path
            id="Path_1922"
            data-name="Path 1922"
            d="M576.155,400.576c-6.861-1.923-13.661-3.489-20.259-4.695v1.773c6.445,1.188,13.086,2.723,19.789,4.6,21.338,5.981,40.647,14.7,52.975,23.913l1.045-1.4C617.21,415.432,597.692,406.613,576.155,400.576Z"
            transform="translate(-428.948 -312.347)"
            fill="#ccc"
          />
          <path
            id="Path_1923"
            data-name="Path 1923"
            d="M627.379,401.815A58.3,58.3,0,0,0,555.9,345.062v113.5a58.29,58.29,0,0,0,65.593-31.193q.451-.919.867-1.856a58.047,58.047,0,0,0,5.024-23.7Z"
            transform="translate(-428.948 -280.894)"
            fill={fill ? fill : "#d31b61"}
          />
          <circle
            id="Ellipse_425"
            data-name="Ellipse 425"
            cx="0.668"
            cy="0.668"
            r="0.668"
            transform="translate(141.044 50.818)"
            fill="#f2f2f2"
          />
          <circle
            id="Ellipse_426"
            data-name="Ellipse 426"
            cx="1.512"
            cy="1.512"
            r="1.512"
            transform="translate(185.374 53.979)"
            fill="#ccc"
          />
          <circle
            id="Ellipse_427"
            data-name="Ellipse 427"
            cx="2.455"
            cy="2.455"
            r="2.455"
            transform="translate(238.062 115.795)"
            fill={fill ? fill : "#d31b61"}
          />
          <circle
            id="Ellipse_428"
            data-name="Ellipse 428"
            cx="1.053"
            cy="1.053"
            r="1.053"
            transform="translate(220.266 77.985)"
            fill="#ccc"
          />
          <circle
            id="Ellipse_429"
            data-name="Ellipse 429"
            cx="1.053"
            cy="1.053"
            r="1.053"
            transform="translate(216.579 112.219)"
            fill="#ccc"
          />
          <path
            id="Path_1924"
            data-name="Path 1924"
            d="M466.469,458.217h-1.76v-1.759h-.944v1.759h-1.759v.944h1.759v1.759h.944v-1.759h1.76Z"
            transform="translate(-277.685 -274.35)"
            fill={fill ? fill : "#d31b61"}
          />
          <path
            id="Path_1925"
            data-name="Path 1925"
            d="M569.944,436.315H568.63V435h-.707v1.315h-1.315v.706h1.315v1.315h.707v-1.315h1.315Z"
            transform="translate(-340.555 -261.453)"
            fill="#ccc"
          />
          <circle
            id="Ellipse_430"
            data-name="Ellipse 430"
            cx="4.896"
            cy="4.896"
            r="4.896"
            transform="translate(136.148 70.405)"
            opacity="0.1"
          />
          <circle
            id="Ellipse_431"
            data-name="Ellipse 431"
            cx="4.896"
            cy="4.896"
            r="4.896"
            transform="translate(136.148 113.8)"
            opacity="0.1"
          />
          <circle
            id="Ellipse_432"
            data-name="Ellipse 432"
            cx="4.896"
            cy="4.896"
            r="4.896"
            transform="translate(162.408 87.541)"
            opacity="0.1"
          />
          <circle
            id="Ellipse_433"
            data-name="Ellipse 433"
            cx="4.896"
            cy="4.896"
            r="4.896"
            transform="translate(178.653 120.698)"
            opacity="0.1"
          />
          <path
            id="Path_1926"
            data-name="Path 1926"
            d="M640.7,479.244c-2.3-3.527-6-7.211-11-10.948l-1.045,1.4c9.713,7.262,14.238,14.2,12.741,19.545-.834,2.975-3.477,5.391-7.856,7.182s-10.281,2.869-17.51,3.193q-2.3.1-4.728.1c-14.144,0-31.2-2.619-48.635-7.506q-3.429-.961-6.775-2.011v1.826q3.116.968,6.3,1.864c17.589,4.93,34.808,7.571,49.115,7.571,1.638,0,3.24-.035,4.8-.1,15.479-.694,25.057-4.83,26.968-11.647C643.965,486.547,643.165,483.026,640.7,479.244Z"
            transform="translate(-428.948 -355.871)"
            fill="#ccc"
          />
          <path
            id="Path_1927"
            data-name="Path 1927"
            d="M616.034,536.108q-2.3.1-4.729.1c-14.143,0-31.2-2.618-48.635-7.506q-3.429-.961-6.775-2.011v1.827q3.115.968,6.3,1.864c17.59,4.929,34.808,7.57,49.115,7.57,1.638,0,3.241-.035,4.8-.1q2.822-.126,5.376-.4.451-.919.867-1.856Q619.388,535.956,616.034,536.108Z"
            transform="translate(-428.948 -390.971)"
            opacity="0.1"
          />
          <circle
            id="Ellipse_434"
            data-name="Ellipse 434"
            cx="6.383"
            cy="6.383"
            r="6.383"
            transform="translate(160.9 65.629)"
            fill="#fff"
          />
          <path
            id="Path_1928"
            data-name="Path 1928"
            d="M643.3,343.45a9.124,9.124,0,0,0-9.124,9.124c0,5.039,9.124,21.586,9.124,21.586s9.124-16.547,9.124-21.586a9.124,9.124,0,0,0-9.124-9.124Zm0,13.575a4.228,4.228,0,1,1,4.228-4.228,4.228,4.228,0,0,1-4.228,4.228Z"
            transform="translate(-476 -280.833)"
            fill="#3f3d56"
          />
          <circle
            id="Ellipse_435"
            data-name="Ellipse 435"
            cx="6.383"
            cy="6.383"
            r="6.383"
            transform="translate(134.569 88.768)"
            fill="#fff"
          />
          <path
            id="Path_1929"
            data-name="Path 1929"
            d="M577.3,401.45a9.124,9.124,0,0,0-9.124,9.124c0,5.039,9.124,21.586,9.124,21.586s9.124-16.547,9.124-21.586a9.124,9.124,0,0,0-9.124-9.124Zm0,13.575a4.228,4.228,0,1,1,4.228-4.228,4.228,4.228,0,0,1-4.228,4.228Z"
            transform="translate(-436.331 -315.694)"
            fill="#3f3d56"
          />
          <circle
            id="Ellipse_436"
            data-name="Ellipse 436"
            cx="6.383"
            cy="6.383"
            r="6.383"
            transform="translate(134.569 47.676)"
            fill="#fff"
          />
          <path
            id="Path_1930"
            data-name="Path 1930"
            d="M577.3,298.45a9.124,9.124,0,0,0-9.124,9.124c0,5.039,9.124,21.586,9.124,21.586s9.124-16.547,9.124-21.586a9.124,9.124,0,0,0-9.124-9.124Zm0,13.575a4.228,4.228,0,1,1,4.228-4.228,4.228,4.228,0,0,1-4.228,4.228Z"
            transform="translate(-436.331 -253.787)"
            fill="#ccc"
          />
          <circle
            id="Ellipse_437"
            data-name="Ellipse 437"
            cx="6.383"
            cy="6.383"
            r="6.383"
            transform="translate(177.258 97.146)"
            fill="#fff"
          />
          <path
            id="Path_1931"
            data-name="Path 1931"
            d="M684.3,422.45a9.124,9.124,0,0,0-9.124,9.124c0,5.039,9.124,21.586,9.124,21.586s9.124-16.547,9.124-21.586a9.124,9.124,0,0,0-9.124-9.124Zm0,13.575a4.228,4.228,0,1,1,4.228-4.228,4.228,4.228,0,0,1-4.228,4.228Z"
            transform="translate(-500.643 -328.316)"
            fill="#ccc"
          />
          <circle
            id="Ellipse_438"
            data-name="Ellipse 438"
            cx="6.383"
            cy="6.383"
            r="6.383"
            transform="translate(95.072 82.784)"
            fill="#fff"
          />
          <path
            id="Path_1932"
            data-name="Path 1932"
            d="M478.3,386.45a9.124,9.124,0,0,0-9.124,9.124c0,5.039,9.124,21.586,9.124,21.586s9.124-16.547,9.124-21.586a9.124,9.124,0,0,0-9.124-9.124Zm0,13.575a4.228,4.228,0,1,1,4.228-4.228,4.228,4.228,0,0,1-4.228,4.228Z"
            transform="translate(-376.828 -306.678)"
            fill="#ccc"
          />
          <circle
            id="Ellipse_439"
            data-name="Ellipse 439"
            cx="6.383"
            cy="6.383"
            r="6.383"
            transform="translate(97.466 117.493)"
            fill="#fff"
          />
          <path
            id="Path_1933"
            data-name="Path 1933"
            d="M484.3,473.45a9.124,9.124,0,0,0-9.124,9.124c0,5.039,9.124,21.586,9.124,21.586s9.124-16.547,9.124-21.586a9.124,9.124,0,0,0-9.124-9.124Zm0,13.575a4.228,4.228,0,1,1,4.228-4.228,4.228,4.228,0,0,1-4.228,4.228Z"
            transform="translate(-380.435 -358.969)"
            fill="#3f3d56"
          />
        </g>
      </g>
    </svg>
  );
};

export default NoInternetFound;
