import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./HomeNavbarLink.module.scss";
const HomeNavbarLink = ({ to, children, multiLogo, active, inActive, onClick }) => {
  return (
    <NavLink
      to={to}
      end
      // style={
      //   multiLogo && {
      //     minWidth: "4rem",
      //   }
      // }
      onClick={onClick}
      className={({ isActive }) => {
        return active
          ? classes.homeNavbarLink + " " + classes.homeNavbarLink__active
          : inActive
          ? classes.homeNavbarLink
          : isActive
          ? classes.homeNavbarLink + " " + classes.homeNavbarLink__active
          : classes.homeNavbarLink;
      }}
    >
      {children}
    </NavLink>
  );
};

export default HomeNavbarLink;
