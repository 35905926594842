export const setLTIThemeStyle = () => async () => {
  const tenantName = localStorage.getItem("tenantSlugName");
  if (
    tenantName !== undefined &&
    (tenantName?.toLowerCase() === "search" ||
      tenantName?.toLowerCase() === "qaaadsep26" ||
      tenantName?.toLowerCase() === "ltimindtree")
  ) {
    document.getElementsByTagName(
      "body"
    )[0].style.cssText = `font-family: "Aller", sans-serif; background-color: #f6f6f6;`;
  } else {
    document.getElementsByTagName("body")[0].style.fontFamily = "";
  }
};
