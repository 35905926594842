import * as msal from "@azure/msal-browser";
import * as constants from "./devApiContants";

// MSAL configuration
export const msalConfig = {
  auth: {
    clientId: constants.CLIENTID,
    authority: "https://login.microsoftonline.com/common",
    // redirectUri: "/aadlogin",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback(loglevel, message, containsPii) {
        console.log(message);
      },
      piiLoggingEnabled: false,
      logLevel: msal.LogLevel.Verbose,
    },
  },
};

// instantiate MSAL client object
// export const msalInstance = new msal.PublicClientApplication(msalConfig);

// msalInstance
//   .handleRedirectPromise()
//   .then((response) => {
//     if (response) {
//       msalInstance.setActiveAccount(response.account);
//     }
//   })
//   .catch((error) => {
//     console.log(error);
//   });
