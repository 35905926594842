import React from "react";

const NotificationBellIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.56"
      height="22"
      viewBox="0 0 19.56 22"
    >
      <g id="bell_sd" transform="translate(-2 -1)">
        <path
          id="Path_311"
          data-name="Path 311"
          d="M21.13,18.52l-1.28-.8a2,2,0,0,1-.95-1.7V10.17a7.43,7.43,0,0,0-1.49-4.49,7.09,7.09,0,0,0-3.62-2.54.32.32,0,0,1-.23-.31,1.87,1.87,0,0,0-.52-1.29,1.74,1.74,0,0,0-2.52,0A1.87,1.87,0,0,0,10,2.83a.32.32,0,0,1-.23.31A7.09,7.09,0,0,0,6.16,5.68a7.43,7.43,0,0,0-1.49,4.49V16a2,2,0,0,1-.95,1.7l-1.28.8a.9.9,0,0,0-.33.34,1.12,1.12,0,0,0-.11.47,1,1,0,0,0,.26.65.9.9,0,0,0,.63.27H20.67a.9.9,0,0,0,.63-.27,1,1,0,0,0,.26-.65,1.12,1.12,0,0,0-.11-.47A.9.9,0,0,0,21.13,18.52Z"
          fill={fill ? fill : "#707070"}
        />
        <path
          id="Path_312"
          data-name="Path 312"
          d="M11.79,23a2.64,2.64,0,0,0,2.2-1.21.41.41,0,0,0-.39-.62H9.98a.41.41,0,0,0-.39.62,2.479,2.479,0,0,0,.67.7A2.559,2.559,0,0,0,11.79,23Z"
          fill={fill ? fill : "#707070"}
        />
      </g>
    </svg>
  );
};

export default NotificationBellIcon;
