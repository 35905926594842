import actionTypes from "../actions/actionTypes";

const initialState = {
  allPublishedTopics: null,
  publishedTopicsDone: false,
  allPublishedTopicsCount: 0,
  subTopics: null,
  topicDetails: null,
  topicsDetailsDone: false,
};

const topicsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.topicsTypes.GET_ALL_PUBLISHED_TOPICS:
      return { ...state, allPublishedTopics: payload };
    case actionTypes.topicsTypes.SET_LOADING_PUBLISHED_TOPICS:
      return { ...state, publishedTopicsDone: payload };
    case actionTypes.topicsTypes.GET_ALL_PUBLISHED_TOPICS_COUNT:
      return { ...state, allPublishedTopicsCount: payload };
    case actionTypes.topicsTypes.GET_SUB_TOPICS:
      return { ...state, subTopics: payload };
    case actionTypes.topicsTypes.GET_TOPIC_DETAILS:
      return { ...state, topicDetails: payload };
    case actionTypes.topicsTypes.SET_LOADING_TOPICS_DETAILS:
      return { ...state, topicsDetailsDone: payload };
    case actionTypes.topicsTypes.SET_INITIAL_TOPICS_STATE:
      return {
        ...state,
        allPublishedTopics: null,
        publishedTopicsDone: false,
        allPublishedTopicsCount: 0,
        subTopics: null,
        topicDetails: null,
      };
    default:
      return state;
  }
};

export default topicsReducer;
