import actionTypes from "../actions/actionTypes";

const initialState = {
  getContactUs: [],
  postContactUs: true,
};

const contactUsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.errorTypes.API_ERROR:
      return { ...state, loading: false, error: payload };
    case actionTypes.contactUsTypes.POST_CONTACT_US:
      return { ...state, postContactUs: payload };
    case actionTypes.contactUsTypes.GET_CONTACT_US:
      return { ...state, getContactUs: payload };
    default:
      return state;
  }
};

export default contactUsReducer;
