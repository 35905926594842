import actionTypes from "../actions/actionTypes";

const initialState = {
  allLanguages: null,
  selectedLanguage: "en",
  notifications: null,
  themeStyles: null,
  getContributeCount: 0,
  getContributeSubmitted: [],
  postContribute: [],
  postContributeFile:[],
  getContributeYoutubeSearch: null,
  getContributeYoutubeVideosCount: 0,
  getContributeClassDetails:[],
  getContributeClassDetailsReducer: null,
  getClassAssetId:null,
  uploadChunkData:null,
};

const contributeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.homeTypes.GET_ALL_LANGUAGES:
      return {
        ...state,
        allLanguages: payload,
      };
    case actionTypes.homeTypes.SET_LANGUAGE:
      return {
        ...state,
        selectedLanguage: payload,
      };
    case actionTypes.homeTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      };
    case actionTypes.homeTypes.SET_THEME_STYLES:
      return {
        ...state,
        themeStyles: payload,
      };
    case actionTypes.contributeTypes.GET_CONTRIBUTE_CLASSES_SUBMITTED:
      return {
        ...state,
        getContributeSubmitted: payload,
      };
    case actionTypes.contributeTypes.POST_CONTRIBUTE_CLASSES:
      return {
        ...state,
        postContribute: payload,
      };
      case actionTypes.contributeTypes.POST_CONTRIBUTE_FILE_CLASSES:
        return {
          ...state,
          postContributeFile: payload,
        };
    case actionTypes.contributeTypes.GET_CONTRIBUTE_CLASSES_COUNT:
      return {
        ...state,
        getContributeCount: payload,
      };
    case actionTypes.contributeTypes.GET_CONTRIBUTE_YOUTUBE_SEARCH:
      return {
        ...state,
        getContributeYoutubeSearch: payload,
      };
    case actionTypes.contributeTypes.GET_CONTRIBUTE_YOUTUBE_COUNTS:
      return {
        ...state,
        getContributeYoutubeVideosCount: payload,
      };
    case actionTypes.contributeTypes.GET_CONTRIBUTE_CLASS_DETAILS:
      return {
        ...state,
        getContributeClassDetailsReducer: payload,
      };
      case actionTypes.contributeTypes.PUT_CONTRIBUTE_VIDEO_DURATION_DONE:
      return {
        ...state,
        putContributeVideoDurationDoneReducer: payload,
      };
    case actionTypes.contributeTypes.PUT_CONTRIBUTE_VIDEO_URL_DONE:
      return {
        ...state,
        putContributeVideoUrlDoneReducer: payload,
      };
    case actionTypes.contributeTypes.GET_CONTRIBUTE_ASSET_ID:
      return {
        ...state,
        getClassAssetId: payload,
      };
    case actionTypes.contributeTypes.UPLOAD_CHUNK:
    return {
      ...state,
      uploadChunkData: payload,
    };
        
      
      
    default:
      return state;
  }
};

export default contributeReducer;