import actionTypes from "../actionTypes";

export const getClassDetailsAction = (data) => ({
  type: actionTypes.classTypes.GET_CLASS_DETAILS,
  payload: data,
});
export const setCurrentViewAction = (data) => ({
  type: actionTypes.classTypes.SET_CURRENT_CLASS_VIEW,
  payload: data,
});
export const getClassTabAction = (data) => ({
  type: actionTypes.classTypes.GET_CLASS_TAB,
  payload: data,
});
export const getClassAssetIdAction = (data) => ({
  type: actionTypes.classTypes.GET_CLASS_ASSET_ID,
  payload: data,
});
export const getClassQueriesAction = (data) => ({
  type: actionTypes.classTypes.GET_CLASS_QUERIES,
  payload: data,
});
export const getScormVersionAction = (data) => ({
  type: actionTypes.classTypes.GET_SCORM_VERSION,
  payload: data,
});
export const getScormBookmarkAction = (data) => ({
  type: actionTypes.classTypes.GET_SCORM_BOOKMARK,
  payload: data,
});
export const addRemoveFavoriteClassAction = () => ({
  type: actionTypes.classTypes.ADD_REMOVE_FAVORITE_CLASS,
});

export const updateVideoDurationForLogoutAction = (data) => ({
  type: actionTypes.classTypes.UPDATE_VIDEO_DURATION_FOR_LOGOUT,
  payload: data,
});
export const postAssessmentScoreAction = (data) => ({
  type: actionTypes.classTypes.POST_ASSESSMENT_SCORE,
  payload: data,
});

export const getNextClassAction = (data) => ({
  type: actionTypes.classTypes.GET_NEXT_CLASS,
  payload: data,
});
export const getNextClassInSeriesAction = (data) => ({
  type: actionTypes.classTypes.GET_NEXT_CLASS_IN_SERIES,
  payload: data,
});
export const getRelatedClassesAction = (data) => ({
  type: actionTypes.classTypes.GET_RELATED_CLASSES,
  payload: data,
});
