export const convertToMillisec = () => {
    let tolDurmininms = localStorage.getItem("currentTimeMin");
    let tolDurhrinms = localStorage.getItem("currentTimeHr");
    let tolDursecinsec = localStorage.getItem("currentTimeSec");
    let totalDurationinms = localStorage.getItem("currentTimeMs");
    let totalQuizDurationinms = 0;
    tolDurmininms = tolDurmininms * 60 * 1000;
    tolDurhrinms = tolDurhrinms * 60 * 60 * 1000;
    tolDursecinsec = tolDursecinsec * 1000;
    totalQuizDurationinms =
        Number(tolDurmininms) +
        Number(tolDurhrinms) +
        Number(tolDursecinsec) +
        Number(totalDurationinms);
    const replacedTotalQDuration = totalQuizDurationinms
        .toString()
        .replaceAll(/&/g, "&")
        .replaceAll(/</g, "<")
        .replaceAll(/>/g, ">")
        .replaceAll(/"/g, '"')
        .replaceAll(/'/g, "'");
    localStorage.setItem("totalQDuration", replacedTotalQDuration);
    return totalQuizDurationinms;
};

export const convertMillisecToOther = (totalDurationinms) => {
    let secs = parseInt(totalDurationinms / 1000);
    let mins = secs / 60;
    secs = secs % 60;
    let milliseconds = parseInt(totalDurationinms % 1000);
    let hours = mins / 60;
    mins %= 60;
    localStorage.setItem("resumeTimeMin", parseInt(mins));
    localStorage.setItem("resumeTimeHr", parseInt(hours));
    localStorage.setItem("resumeTimeSec", parseInt(secs));
    localStorage.setItem("resumeTimeMs", parseInt(milliseconds));
};

export const userScoreObjCreation = (
    IsSubmitted,
    IsUserAssessmentSuccess,
    quizData,
    virtualQuizData
) => {
    const totalQDuration = convertToMillisec();
    const { ShotClassId, VirtualOrLiveQuizEventId } = quizData;
    let UserQuestionResponse = [];
    virtualQuizData.AssessmentSet.QuestionList.forEach((ques) => {
        let optionId, IsRightAnswer;
        if (ques.correctlyAnswered === true) {
            IsRightAnswer = true;
        } else {
            IsRightAnswer = false;
        }
        ques.ChoiceList.forEach((option) => {
            if (option.Isselected === true) optionId = option.ID;
        });
        UserQuestionResponse.push({
            QuestionId: ques.ID,
            ChoiceId: optionId,
            IsRightAnswer: IsRightAnswer,
        });
    });
    return {
        ClassId: parseInt(ShotClassId),
        ClassName: virtualQuizData.Name,
        CourseCompletionDuration: totalQDuration,
        IsQuizSubmitForLiveOrVirtualEvent: IsSubmitted,
        IsUserAssessmentSuccess: IsUserAssessmentSuccess,
        Score: 0,
        TotalCACount: 0,
        UserQuestionResponse: UserQuestionResponse,
        VirtualOrLiveQuizEventId: VirtualOrLiveQuizEventId,
    };
};

export const createPayloadForBeginEndLiveQuiz = (
    endQuestionId,
    instructorId,
    passCode,
    shotClassId,
    tenantId
) => {
    return {
        endQuestionId: endQuestionId,
        instructorId: instructorId,
        passCode: passCode,
        shotClassId: shotClassId,
        tenantId: tenantId,
    };
};
