// import { adalConfig, authContext, getToken } from "../../../utils/adalConfig";
import {
  CLIENTID,
  GET_LAST_LOGIN_TIME_API_URL,
  LOGIN_API_URL,
  PRE_LOGIN_API_URL,
  PASSWORD_RESET_API_URL,
  LOGOUT_API_URL,
  AAD_ADMIN_CONSENT_API_URL,
  SHOTCLASS_API_URL,
  LOGIN_RESOURCE,
} from "../../../utils/devApiContants";
import { errorTypes } from "../../../utils/errorTypes";
import * as loginActionCreator from "../actionCreators/loginActionCreator";
import { setInitialPrivacyPolicyStateAction } from "../actionCreators/privacyPolicyCreator";
import * as userActionCreator from "../actionCreators/userActionCreator";
import { FormattedMessage } from "react-intl";
import {
  setErrorHandler,
  setLoadingHandler,
  unSetLoadingHandler,
} from "./errorActionHandler";
import {
  getUserProfileHandler,
  isMailSentForForgotPasswordHandler,
  isPasswordResetRequiredHandler,
  isUserActiveHandler,
  isUserExistsHandler,
} from "./userActionHandler";
import * as microsoftTeams from "@microsoft/teams-js";
import { msalConfig } from "../../../utils/msalConfig";
import * as msal from "@azure/msal-browser";
import { decodeToken } from "../../../utils/decodeToken";

const { FAIL, WARN, SUCCESS } = errorTypes;
//settingUpHeaders
const myHeaders = {
  "Content-Type": "application/json",
  TenantSlugName: localStorage.getItem("tenantSlugName"),
};

//logout - navbar
export const logoutHandler =
  (navigate, validLogout) => async (dispatch, getState) => {
    // if (getState().errorReducer?.isError === false) {
    //   console.log(getState().errorReducer?.isError);
    //   await dispatch(setErrorHandler(WARN, `Logged out`));
    // }
    if (validLogout) {
      const tenantData = JSON.parse(localStorage.getItem("tenantData"));
      let securityProvider = localStorage.getItem("securityProviderName");
      if (securityProvider === "SAML2") {
        if (tenantData?.SAML2AuthServiceUrl !== null) {
          let logoutUrl = tenantData?.SAML2AuthServiceUrl + "/Login/Logout";
          window.location.href = logoutUrl;
          localStorage.clear();
          sessionStorage.clear();
          dispatch(loginActionCreator.logOutAction());
          navigate("/");
        } else {
          localStorage.clear();
          sessionStorage.clear();
          dispatch(loginActionCreator.logOutAction());
          navigate("/");
        }
      } else if (securityProvider === "custom") {
        await fetch(
          LOGOUT_API_URL + localStorage.getItem("userName") + "/token",
          {
            method: "DELETE",
            headers: JSON.parse(localStorage.getItem("headers")),
          }
        )
          .then((response) => {
            if (response.status === 204) {
              // localStorage.clear();
              // sessionStorage.clear();
              // dispatch(loginActionCreator.logOutAction());
              // navigate("/");
            }
          })
          .catch((error) => {
            console.log("error " + error);
          });
        localStorage.clear();
        sessionStorage.clear();
        dispatch(loginActionCreator.logOutAction());
        navigate("/");
      } else {
        // navigate("/");
        // authContext.logOut();
        const msalInstance = new msal.PublicClientApplication(msalConfig);
        localStorage.clear();
        sessionStorage.clear();
        dispatch(loginActionCreator.logOutAction());
        msalInstance.logoutRedirect();
      }
    } else {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(loginActionCreator.logOutAction());
      navigate("/");
    }
  };

export const getToken = async (loginRequest) => {
  const msalInstance = new msal.PublicClientApplication(msalConfig);
  return await msalInstance
    .acquireTokenSilent(loginRequest)
    .then((response) => {
      console.log(response);
      let headers = {
        "Content-Type": "application/json",
        TenantSlugName: localStorage.getItem("tenantSlugName"),
        Authorization: "bearer " + response.accessToken,
      };
      localStorage.setItem("headers", JSON.stringify(headers));
      localStorage.setItem("token", response.accessToken);
      return response.accessToken;
    })
    .catch((error) => {
      if (error instanceof msal.InteractionRequiredAuthError) {
        msalInstance.acquireTokenPopup(loginRequest).then((response) => {
          console.log(response);
          let headers = {
            "Content-Type": "application/json",
            TenantSlugName: localStorage.getItem("tenantSlugName"),
            Authorization: "bearer " + response.accessToken,
          };
          localStorage.setItem("headers", JSON.stringify(headers));
          localStorage.setItem("token", response.accessToken);
          return response.accessToken;
        });
      }
      console.log(error);
    });
};

// export const getGraphUserInfo = async () => {
//   const msalInstance = new msal.PublicClientApplication(msalConfig);
//   if (
//     (window.parent === window.self && window.nativeInterface) ||
//     window.name === "embedded-page-container" ||
//     window.name === "extension-tab-frame"
//   ) {
//    await msalInstance.acquireTokenByCode({
//       authority: `https://login.microsoftonline.com/${tid}`,
//       oboAssertion: token,
//       scopes: scopes,
//       skipCache: true,
//   }).then(result => {
//       console.log("Token is: " + result.accessToken);
//   }).catch(error => {
//       reject({ "error": error.errorCode });
//   });

//   }else{

//   }
//   const graphToken = await msalInstance.acquireTokenSilent({
//     scopes: ["User.Read"],
//   });
//   const headers = new Headers();
//   const bearer = `Bearer ${graphToken.accessToken}`;
//   headers.append("Authorization", bearer);
//   const options = {
//     method: "GET",
//     headers: headers,
//   };

//   return await fetch(GRAPH_ENDPOINT_API_URL, options)
//     .then((response) => response.json())
//     .catch((error) => console.log(error));
// };
export const checkAADToken = (navigate) => async (dispatch) => {
  console.log("called");
  const msalInstance = new msal.PublicClientApplication(msalConfig);
  let user =
    msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
  const loginRequest = {
    scopes: [`${LOGIN_RESOURCE}/user_impersonation`],
    account: user,
  };
  dispatch(PostProfile()).then((data) => {
    console.log(data);
    if (!data || data?.status !== 200) {
      const errorMessage = data?.data?.toLowerCase();
      if (errorMessage === "user is not active") {
        dispatch(
          setErrorHandler(
            FAIL,
            "You are not authorized. Please contact your Admin"
          )
        );
        localStorage.clear();
        sessionStorage.clear();
        dispatch(loginActionCreator.logOutAction());
        navigate("/");
      } else if (errorMessage === "invalid user") {
        dispatch(setErrorHandler(FAIL, "Invalid User"));
        localStorage.clear();
        sessionStorage.clear();
        dispatch(loginActionCreator.logOutAction());
        navigate("/");
      } else {
        setTimeout(() => {
          getToken(loginRequest).then(() => {
            dispatch(checkAADToken(navigate));
          });
        }, 1000);
      }
    } else {
      dispatch(getUserProfileHandler()).then((result) => {
        if (result?.data !== undefined) {
          if (result.status === 401) {
            const errorMessage = result?.data?.toLowerCase();
            if (errorMessage === "user is not active") {
              dispatch(
                setErrorHandler(
                  FAIL,
                  "You are not authorized. Please contact your Admin"
                )
              );
            } else if (errorMessage === "invalid bearer token") {
              dispatch(
                setErrorHandler(FAIL, "Invalid Token, try logging in again.")
              );
              // getToken(loginRequest).then(() => {
              //   dispatch(checkAADToken(navigate));
              // });
            } else if (errorMessage === "invalid user") {
              dispatch(setErrorHandler(FAIL, "Invalid User"));
            }
            localStorage.clear();
            sessionStorage.clear();
            dispatch(loginActionCreator.logOutAction());
            navigate("/");
          } else {
            console.log(result);
            localStorage.setItem("userName", result?.data?.LoginId);
            dispatch(setErrorHandler(SUCCESS, `Login Successful`));
            navigate("/home");
            return true;
          }
        } else {
          getToken(loginRequest).then(() => {
            dispatch(checkAADToken(navigate));
          });
        }
      });
    }
  });
};

export const authenticateAADUser = (navigate) => async (dispatch) => {
  const tenantData = JSON.parse(localStorage.getItem("tenantData"));
  const userName = localStorage.getItem("userName");
  let loginRequest = {
    scopes: [`${LOGIN_RESOURCE}/user_impersonation`],
  };
  if (!tenantData?.ID) {
    dispatch(setErrorHandler(FAIL, `Data not found, please login again!`));
    navigate("/");
  } else {
    const msalInstance = new msal.PublicClientApplication(msalConfig);
    const user =
      msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
    if (user) {
      loginRequest = {
        ...loginRequest,
        account: user,
      };
    }
    if (
      (window.parent === window.self && window.nativeInterface) ||
      window.name === "embedded-page-container" ||
      window.name === "extension-tab-frame"
    ) {
      console.log("window.parent ", window.parent);
      console.log("window.self ", window.self);
      console.log("window.name ", window.name);
      console.log("window.nativeInterface ", window.nativeInterface);
      microsoftTeams.initialize();
      // let userInfoRequest = {
      //   successCallback: (result) => {
      //     debugger;
      //     console.log("Success: " + result);
      //   },
      //   failureCallback: (error) => {
      //     console.log("Error getting token: " + error);
      //   },
      // };
      let authTokenRequest = {
        successCallback: (result) => {
          console.log("Success: " + result);
          localStorage.setItem("token", result);
          let headers = {
            "Content-Type": "application/json",
            TenantSlugName: tenantData?.TenantSlugName,
            Authorization: "Bearer " + result,
          };
          localStorage.setItem("headers", JSON.stringify(headers));
          // debugger;
          // microsoftTeams.authentication.getUser(userInfoRequest);
          // microsoftTeams.getContext((data) => {
          //   console.log(data);
          //   return data;
          // });
          // microsoftTeams.authentication.notifySuccess();
          dispatch(checkAADToken(navigate));
          // navigate("/aadlogin");
        },
        failureCallback: (error) => {
          // microsoftTeams.authentication.notifyFailure();
          console.log("Error getting token: " + error);
        },
      };
      microsoftTeams.authentication.getAuthToken(authTokenRequest);
    } else {
      if (userName && tenantData?.AADConfig?.IsAdminConsentGiven === false) {
        loginRequest = {
          ...loginRequest,
          prompt: "consent",
        };
        await dispatch(
          postAADAdminConsentActionHandler(
            tenantData?.ID,
            msalConfig.auth.clientId,
            tenantData?.TenantSlugName
          )
        );
      } else {
        loginRequest = {
          ...loginRequest,
          // prompt: "login",
        };
      }
      if (!user) {
        if (userName) {
          loginRequest = {
            ...loginRequest,
            extraQueryParameters: { login_hint: userName },
          };
        }
        const handleResponse = (response) => {
          if (response !== null) {
            msalInstance.setActiveAccount(response.account);
            console.log(response);
            let headers = {
              "Content-Type": "application/json",
              TenantSlugName: tenantData?.TenantSlugName,
              Authorization: "bearer " + response.accessToken,
            };
            localStorage.setItem("headers", JSON.stringify(headers));
            localStorage.setItem("token", response.accessToken);
            // navigate("/aadlogin");
            dispatch(checkAADToken(navigate));
          } else {
            const currentAccounts = msalInstance.getAllAccounts();
            if (currentAccounts.length === 0) {
              msalInstance
                .loginRedirect(loginRequest)
                .catch((e) => console.log(e));
            } else if (currentAccounts.length > 1) {
              msalInstance.setActiveAccount(currentAccounts[0]);
              console.log(response);
              let headers = {
                "Content-Type": "application/json",
                TenantSlugName: tenantData?.TenantSlugName,
                Authorization: "bearer " + response.accessToken,
              };
              localStorage.setItem("headers", JSON.stringify(headers));
              localStorage.setItem("token", response.accessToken);
              // navigate("/aadlogin");
              dispatch(checkAADToken(navigate));
            }
          }
        };
        msalInstance.handleRedirectPromise().then(handleResponse);
      } else {
        getToken(loginRequest).then(() => {
          // navigate("/aadlogin");
          dispatch(checkAADToken(navigate));
        });
      }
    }
  }
};

//getTenantDetails
export const getTenantDetailsHandler =
  (tenantName, navigate) => async (dispatch) => {
    dispatch(setLoadingHandler());
    // await fetch(PRE_LOGIN_API_URL + tenantName)
    await fetch(PRE_LOGIN_API_URL + tenantName + "&clientId=" + CLIENTID)
      .then((response) => response.json())
      .then((result) => {
        if (result === undefined || result === null) {
          navigate("/nopagefound");
        } else if (result !== undefined) {
          console.log(result);
          if (result?.Status !== "Active") {
            dispatch(
              setErrorHandler(
                FAIL,
                "Your current subscription has expired.Please contact your administrator"
              )
            );
            navigate("/");
            return;
          } else {
            // localStorage.setItem("userName", userName);
            localStorage.setItem("tenantSlugName", result?.TenantSlugName);
            localStorage.setItem(
              "themeData",
              JSON.stringify(result?.ThemeData)
            );
            localStorage.setItem("tenantData", JSON.stringify(result));
            localStorage.setItem("tenantId", result?.ID);
            localStorage.setItem(
              "securityProviderName",
              result?.SecurityProviderName
            );
            dispatch(loginActionCreator.getTenantDetailsAction(result));

            if (result?.SecurityProviderName === "custom") {
              navigate("/login");
            } else if (result?.SecurityProviderName === "aad") {
              // dispatch(authenticateAADUser(navigate));
              navigate("/aadlogin");
            } else if (result?.SecurityProviderName === "SAML2") {
              // localStorage.setItem("IsCustomTenant", false);
              // localStorage.setItem("IsSAMLTenant", true);
              // localStorage.setItem("selectedTab", "Assigned");
              localStorage.setItem(
                "SAMLconfig",
                JSON.stringify(result?.SAMLConfig)
              );
              localStorage.setItem(
                "SAML2AuthServiceUrl",
                result?.SAML2AuthServiceUrl
              );
              var samlurl = result?.SAML2AuthServiceUrl;
              console.log("saml url", samlurl);
              samlurl =
                samlurl +
                localStorage.getItem("tenantSlugName") +
                "?web=" +
                document.location.href;
              window.location.href = samlurl;
            }
          }
        }
        // dispatch(unSetLoadingHandler());
      })
      .catch((err) => {
        // dispatch(unSetLoadingHandler());
        console.log("Fetch Error", err);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };

//preLoginPage -  preLogin
export const preLoginHandler =
  //organization ID = tenant name
  (organizationId, userName, navigate) => async (dispatch) => {
    dispatch(setLoadingHandler());

    await fetch(
      PRE_LOGIN_API_URL +
      organizationId +
      "&UserName=" +
      userName +
      "&IsConsentRequired=" +
      CLIENTID
    )
      .then((response) => {
        if (response.status !== 200) {
          dispatch(
            setErrorHandler(FAIL, "Invalid Organization ID or User name")
          );
        }
        return response.json();
      })
      .then((result) => {
        if (result === undefined) {
          return;
        }
        if (result?.Status !== "Active") {
          dispatch(
            setErrorHandler(
              FAIL,
              "Your current subscription has expired. Please contact your administrator"
            )
          );
          // navigate("/");
          // return;
        } else {
          dispatch(setErrorHandler(SUCCESS, "PreLogin Successful"));
          dispatch(loginActionCreator.preLoginAction(result));

          localStorage.setItem("userName", userName);
          localStorage.setItem("tenantSlugName", result?.TenantSlugName);
          localStorage.setItem("themeData", JSON.stringify(result?.ThemeData));
          localStorage.setItem("tenantData", JSON.stringify(result));
          localStorage.setItem("tenantId", result?.ID);
          localStorage.setItem(
            "securityProviderName",
            result?.SecurityProviderName
          );

          if (result.SecurityProviderName === "custom") {
            navigate("/login");
          } else if (result.SecurityProviderName === "SAML2") {
            localStorage.setItem(
              "SAMLconfig",
              JSON.stringify(result?.SAMLConfig)
            );
            localStorage.setItem(
              "SAML2AuthServiceUrl",
              result?.SAML2AuthServiceUrl
            );
            GoToSAMLloginPage(result?.SAML2AuthServiceUrl);
            return;
          } else if (result.SecurityProviderName === "aad") {
            // dispatch(authenticateAADUser(navigate));
            navigate("/aadlogin");

            // console.log("AAD User Log in");
            // const microsoftTeamsLib = microsoftTeams;
            // || window["microsoftTeams"];

            // if (!microsoftTeamsLib) {
            //   console.log("Library not loaded");
            //   // return false; // the Microsoft Teams library is for some reason not loaded
            // }
            // console.log("Library loaded");
            //   if (
            //     (window.parent === window.self && window.nativeInterface) ||
            //     window.name === "embedded-page-container" ||
            //     window.name === "extension-tab-frame"
            //   ) {
            //     console.log("We are in teams");
            //     console.log("window.parent ", window.parent);
            //     console.log("window.self ", window.self);
            //     console.log("window.name ", window.name);
            //     console.log("window.nativeInterface ", window.nativeInterface);
            //     console.log("We are in teams");
            //     localStorage.setItem("isTeams", true);
            //     navigate("/aadlogin");
            //     return true;
            //   } else {
            //     localStorage.setItem("isTeams", false);
            //     console.log("We are not in teams");
            //                 }
          }
        }
      })
      .catch((err) => {
        console.log("Fetch Error", err);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };

//loginPage -  login
export const loginHandler =
  (userName, password, tenantName, navigate) => async (dispatch) => {
    dispatch(setLoadingHandler());

    let data = {
      LoginId: userName,
      Password: password,
      TenantName: tenantName,
      UDID: 10,
      PostLoginActions: true,
    };
    await fetch(LOGIN_API_URL, {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders,
    })
      .then((response) => {
        if (response.status !== 200) {
          if (response.status === 412) {
            dispatch(
              setErrorHandler(
                FAIL,
                "Your current password has expired. Use 'Request New Password' to generate temporary password."
              )
            );
          } else if (response.status === 401) {
            response.text().then((data) => {
              if (data === "USERID_INACTIVE") {
                dispatch(
                  setErrorHandler(
                    FAIL,
                    "You are not authorized. Please contact your Admin"
                  )
                );
              }
              let lockType = data.split("|")[1];
              if (lockType === "TL") {
                dispatch(
                  setErrorHandler(
                    FAIL,
                    "Please contact your administrator or try again after 30 minutes"
                  )
                );
              } else if (lockType === "DL") {
                dispatch(
                  setErrorHandler(
                    FAIL,
                    "Please contact your administrator to get the account unlocked"
                  )
                );
              }
            });
          } else if (response.status === 400) {
            response.text().then((data) => {
              let remainingAttempts = data.split("|")[1];
              if (remainingAttempts === "TL") {
                dispatch(
                  setErrorHandler(
                    FAIL,
                    "Please contact your administrator or try again after 30 minutes"
                  )
                );
              } else if (remainingAttempts === "DL") {
                dispatch(
                  setErrorHandler(
                    FAIL,
                    "Please contact your administrator to get the account unlocked"
                  )
                );
              } else {
                dispatch(
                  setErrorHandler(
                    WARN,
                    `You are left with ${remainingAttempts} more attempts before the account locks`
                  )
                );
              }
            });
          }
          // dispatch(unSetLoadingHandler());
        } else if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        if (result === undefined) {
          return;
        }
        const headers = {
          AuthenticationToken: result?.TokenValue,
          "Content-Type": "application/json",
          TenantSlugName: tenantName,
        };

        if (result !== null) {
          localStorage.setItem("userPassword", password);
        }
        //if (result !== null && result.IsResetRequired === true) {
        //   debugger;
        //   localStorage.setItem("IsPrivacyPolicy", result.IsPrivacyPolicy);
        // }
        localStorage.setItem("token", result?.TokenValue);
        localStorage.setItem("userName", userName);
        localStorage.setItem("headers", JSON.stringify(headers));

        if (result.IsResetRequired === true) {
          navigate("/resetPassword");
        }
        // else if (
        //   result.IsResetRequired === false &&
        //   result.PrivacyPolicyInfo.IsPrivacyPolicy === true &&
        //   result.PrivacyPolicyInfo.IsProductPrivacyEnable === true
        // ) {
        //   navigate("/privacyPolicy");
        // }
        else {
          dispatch(loginActionCreator.loginAction(result));
          // dispatch(getUserPrivacyPolicy(navigate));

          dispatch(setErrorHandler(SUCCESS, `Login Successful`));
          dispatch(setInitialPrivacyPolicyStateAction());

          // if (
          //   result.PrivacyPolicyInfo.IsPrivacyPolicy === true
          //   //  ||result.PrivacyPolicyInfo.IsProductPrivacyEnable === true
          // ) {
          //   setIsPrivacyModal(true);
          // } else {
          navigate("/home");
        }
        // }
        // dispatch(unSetLoadingHandler());
      })
      .catch((err) => {
        console.log("Fetch Error", err);
      })
      .finally(() => {
        dispatch(unSetLoadingHandler());
      });
  };

//loginPage -  changePassword
export const changePasswordHandler =
  (tenantName, userName) => async (dispatch, getState) => {
    await dispatch(isUserExistsHandler(tenantName, userName));
    // console.log("after 1st", getState().userReducer.isUserExists);
    if (!!getState().userReducer.isUserExists) {
      await dispatch(isUserActiveHandler(tenantName, userName));
      // console.log("after 2nd", getState().userReducer.isUserActive);
      if (!!getState().userReducer.isUserActive) {
        await dispatch(isPasswordResetRequiredHandler(tenantName, userName));
        // console.log(
        //   "after 3rd",
        //   getState().userReducer.isPasswordResetRequired
        // );
        if (!!getState().userReducer.isPasswordResetRequired) {
          await dispatch(
            isMailSentForForgotPasswordHandler(tenantName, userName)
          );
          // console.log(
          //   "after 4th",
          //   getState().userReducer.isMailSentForForgotPassword
          // );
          if (!!getState().userReducer.isMailSentForForgotPassword) {
            dispatch(loginActionCreator.changePasswordAction());
            dispatch(
              setErrorHandler(
                SUCCESS,
                `Your new password has been sent to your registered email id`
              )
            );
            // console.log("last one after 4th");
            // console.log(getState());
          }
        } else {
          setErrorHandler(FAIL, `Wait and try after sometime`);
        }
      } else {
        dispatch(
          setErrorHandler(FAIL, `Account not active, try contacting admin`)
        );
      }
    } else {
      dispatch(setErrorHandler(FAIL, `User Not Exists`));
    }
  };

export const getLastLoginTimeHandler = (navigate, isLoad) => (dispatch) => {
  if (isLoad) {
    dispatch(setLoadingHandler());
  }

  fetch(GET_LAST_LOGIN_TIME_API_URL, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 401) {
        console.log("getLastLoginTime CLOSE");
        // localStorage.clear();
        // sessionStorage.clear();
        // window.location.href = "/";
        dispatch(logoutHandler(navigate));
      }
    })
    .then((result) => {
      dispatch(loginActionCreator.getLastLoginTimeAction(result));
    })
    .catch((error) => {
      console.log("error " + error);
    })
    .finally(() => {
      if (isLoad) {
        dispatch(unSetLoadingHandler());
      }
    });
};

const postAADAdminConsentActionHandler =
  (tenantId, clientId, tenantName) => async (dispatch) => {
    let data = {
      TenantId: tenantId,
      ClientId: clientId,
      TenantName: tenantName,
    };
    await fetch(AAD_ADMIN_CONSENT_API_URL, {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders,
    })
      .then((response) => {
        return response;
      })
      .then((result) => {
        if (result !== undefined) {
          console.log(result);
          // dispatch(POSTAADAdminConsentAcceptedDone(json));
        }
      })
      .catch((err) => {
        console.log("Fetch Error", err);
      });
  };
const GoToSAMLloginPage = (samlurl) => {
  let newsamlurl =
    samlurl +
    localStorage.getItem("tenantSlugName") +
    "?username=" +
    localStorage.getItem("userName") +
    "&web=" +
    document.location.href;

  window.location.href = newsamlurl;
};
export const resetpasswordHandler =
  (currentPassword, confirmpassword, checkCurrentPassword) =>
    async (dispatch) => {
      dispatch(setLoadingHandler());

      let data = {
        OldPassword: localStorage.getItem("userPassword"),
        NewPassword: confirmpassword,
      };
      if (checkCurrentPassword) {
        if (currentPassword != data.OldPassword) {
          dispatch(
            setErrorHandler(
              FAIL,
              <FormattedMessage id="password.invalidCurrentPassword" />
            )
          );
          dispatch(unSetLoadingHandler());
          return;
        }
      }
      return await fetch(PASSWORD_RESET_API_URL, {
        method: "PUT",
        headers: JSON.parse(localStorage.getItem("headers")),
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status !== 200) {
            dispatch(
              setErrorHandler(
                FAIL,
                "Use 8 or more characters with a mix of letters (upper and lower), numbers & symbols"
              )
            );
            return;
          } else if (response.status === 200) {
            console.log("Password reset successfully");
          }
          return response.json();
        })
        .then((result) => {
          if (result !== null) {
            switch (result.Code) {
              case 1001:
                dispatch(setErrorHandler(FAIL, result.Description));
                break;
              case 1002:
                dispatch(
                  setErrorHandler(
                    FAIL,

                    <FormattedMessage id="password.oldNewPasswordnotSame" />
                  )
                );
                break;
              case 1006:
                dispatch(userActionCreator.setIsPasswordResetDone(true));
                dispatch(
                  setErrorHandler(
                    SUCCESS,
                    <FormattedMessage id="password.userPasswordUpdatedsucessfully" />
                  )
                );
                localStorage.setItem("userPassword", confirmpassword);
                return true;
              case 1010:
                dispatch(
                  setErrorHandler(
                    FAIL,
                    <FormattedMessage id="myaccount.lastFivePasswordShouldNotBeSame" />
                  )
                );
                break;
              default:
                dispatch(
                  setErrorHandler(
                    FAIL,
                    <FormattedMessage id="password.useMoreCharacters" />
                  )
                );
                break;
            }
          }
        })
        .catch((error) => {
          // dispatch(unSetLoadingHandler());
          console.log("Fetch error " + error);
        })
        .finally(() => {
          dispatch(unSetLoadingHandler());
        });
    };

export const PostProfile = () => async (dispatch, getState) => {
  // var user = authContext.getCachedUser();
  // const msalInstance = new msal.PublicClientApplication(msalConfig);
  // const graphUserInfo = await getGraphUserInfo();
  // const user =
  //   msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
  const token = localStorage.getItem("token");
  const userData = decodeToken(token);
  if (userData) {
    // const userFirstLastNameSplit = user.name.split(" ");
    const firstname = userData?.given_name;
    // || userFirstLastNameSplit[0];
    const lastName = userData?.family_name;
    //  || userFirstLastNameSplit[1];
    const email = userData?.upn;
    // || user.username;
    const username = userData?.unique_name;
    // || user.username;

    const data = {
      AssociatedRoles: [],
      Category: [],
      City: "",
      EmailId: email,
      FirstName: firstname,
      Gender: "Male",
      LastName: lastName,
      LoginId: username,
      MiddleName: "",
      PhoneNo: "",
      ProfileImageUrl: "",
      Store: [],
    };
    return await fetch(SHOTCLASS_API_URL + "/Users?iscustomlogin=" + false, {
      method: "POST",
      body: JSON.stringify(data),
      headers: JSON.parse(localStorage.getItem("headers")),
    })
      .then(async (response) => {
        const responseData = await (response.status === 401
          ? response.text()
          : response.json());
        return { data: responseData, status: response.status };
      })
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((err) => {
        console.log("Fetch Error", err);
      });
  } else {
    return null;
  }
};
