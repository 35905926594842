import ToastError from "./components/ToastError/ToastError";
import RootRoute from "./views/Routes/RootRoute";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { setThemeStylesHandler } from "./store/actions/actionHandlers/homeActionHandler";
import { useEffect, useState } from "react";
import { setLTIThemeStyle } from "./utils/setLTIThemeStyle";
import PageLoader from "./components/CommonComponents/PageLoader/PageLoader";
function App() {
  const dispatch = useDispatch();
  const homeState = useSelector((state) => state.homeReducer);
  const { themeStyles } = homeState;
  const errorState = useSelector((state) => state.errorReducer);
  const { loading } = errorState;
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    const themeData = JSON.parse(localStorage.getItem("themeData"));
    const tenantName = localStorage.getItem("tenantSlugName");
    if (!!themeData && !!themeStyles === false) {
      dispatch(setThemeStylesHandler());
    }
    if (tenantName !== undefined) {
      dispatch(setLTIThemeStyle());
    }
  }, [dispatch, themeStyles, setLTIThemeStyle]);

  useEffect(() => {
    const ele = document.getElementById("page-loader");
    if (ele || !pageLoaded) {
      ele.outerHTML = "";
      setPageLoaded(true);
    }
  }, [pageLoaded]);


  useEffect(() => {
    const tenantSlugName = localStorage.getItem("tenantSlugName");
    let link = document.querySelector('link[rel="icon"]');
    if (link && tenantSlugName?.toLowerCase() === "search" || link && tenantSlugName?.toLowerCase() === "qaaadsep26" || link && tenantSlugName?.toLowerCase() === "ltimindtree" || link && tenantSlugName?.toLowerCase() === "cybersec") {
      link.setAttribute('href', 'lighthouse_favicon_lti.png');
      document.title = "Lighthouse"
    } else {
      link.setAttribute('href', 'favicon.ico');
      document.title = "Shotclasses"
    }

  }, [themeStyles]);
  return pageLoaded ? (
    <div
      className="App"
    // style={
    //   loading
    //     ? {
    //         height: "100vh",
    //         overflow: "hidden",
    //       }
    //     : {}
    // }
    >
      <RootRoute />
      <ToastError />
      {loading && <PageLoader />}
    </div>
  ) : null;
}

export default App;
