import { async } from "q";
import {
  GET_ALL_LANGUAGES_API_URL,
  GET_ASSIGNED_CLASSES_API_URL,
  GET_ASSIGNED_SERIES_API_URL,
  GET_COMPLETED_CLASSES_API_URL,
  GET_COMPLETED_SERIES_API_URL,
  GET_NOTIFICATIONS_API_URL,
  GET_TRENDING_CLASSES_API_URL,
  GET_TRENDING_SERIES_API_URL,
  GET_LEADERBOARDDATA_API_URL,
  SHOTCLASS_API_URL,
} from "../../../utils/devApiContants";
import * as homeActionCreator from "../actionCreators/homeActionCreator";
import { setLoadingHandler, unSetLoadingHandler } from "./errorActionHandler";
import { logoutHandler } from "./loginActionHandler";

const convertInSecs = (time) => {
  if (time === null) {
    time = "00:00:00";
  }
  let a = time.split(":");
  let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  return seconds;
};
const getWidthInPercentage = (durPlayed, totalDur) => {
  if (durPlayed === 0 && totalDur === 0) return 0;

  let perWidth = (durPlayed / totalDur) * 100;
  if (perWidth > 100) return 100;
  else return perWidth;
};

export const setThemeStylesHandler = () => (dispatch) => {
  const themeData = JSON.parse(localStorage.getItem("themeData"));

  if (themeData !== undefined && themeData?.IsCustomSetting === true) {
    dispatch(homeActionCreator.setThemeStylesAction(themeData));
    document.documentElement.style.setProperty(
      "--color-BGColor",
      themeData?.CustomData?.BGColor
    );
    document.documentElement.style.setProperty(
      "--color-PaneColor",
      themeData?.CustomData?.PaneColor
    );
    document.documentElement.style.setProperty(
      "--color-PrimaryColor1",
      themeData?.CustomData?.PrimaryColor1
    );
    document.documentElement.style.setProperty(
      "--color-PrimaryColor2",
      themeData?.CustomData?.PrimaryColor2
    );
    document.documentElement.style.setProperty(
      "--color-PrimaryColor3",
      themeData?.CustomData?.PrimaryColor3
    );
    document.documentElement.style.setProperty(
      "--color-PrimaryColor4",
      themeData?.CustomData?.PrimaryColor4
    );
    document.documentElement.style.setProperty(
      "--color-SecondaryColor",
      themeData?.CustomData?.SecondaryColor
    );
    document.documentElement.style.setProperty(
      "--color-TabDisable",
      themeData?.CustomData?.TabDisable
    );
    document.documentElement.style.setProperty(
      "--color-TabOnSelect",
      themeData?.CustomData?.TabOnSelect
    );
    document.documentElement.style.setProperty(
      "--color-TextColor",
      themeData?.CustomData?.TextColor
    );
  }
};

// export const setLTIThemeStyle = () => async () => {
//   const tenantName = localStorage.getItem("tenantSlugName");
//   if (tenantName !== undefined && tenantName == "Search") {
//     document.getElementsByTagName(
//       "body"
//     )[0].style.fontFamily = `"Aller", sans-serif`;
//   } else {
//     document.getElementsByTagName("body")[0].style.fontFamily = "";
//   }
// };

export const getAllLanguagesHandler = () => async (dispatch) => {
  // dispatch(setLoadingHandler());

  await fetch(GET_ALL_LANGUAGES_API_URL, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 401) {
        console.log("getTrainingTypes CLOSE");
      }
    })
    .then((result) => {
      // let filterNonArabic = result?.filter(
      //   (lang) => lang?.Locale.toLowerCase() !== "ar-sa"
      // );
      dispatch(homeActionCreator.getAllLanguagesAction(result));
      // dispatch(unSetLoadingHandler());
    })
    .catch((error) => {
      console.log("error " + error);
    });
};

export const setSelectedLanguageHandler = (data) => (dispatch) => {
  dispatch(homeActionCreator.setSelectedLanguageAction(data));
};

export const getNotificationsHandler = (navigate) => async (dispatch) => {
  // dispatch(setLoadingHandler());

  await fetch(GET_NOTIFICATIONS_API_URL, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 401) {
        dispatch(logoutHandler(navigate));
      }
    })
    .then((result) => {
      dispatch(homeActionCreator.getNotificationsAction(result));
      // dispatch(unSetLoadingHandler());
    })
    .catch((error) => {
      console.log("errror " + error);
    });
};

export const getLeaderboardHandler = (navigate) => async (dispatch) => {
  await fetch(GET_LEADERBOARDDATA_API_URL, {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 401) {
        dispatch(logoutHandler(navigate));
      }
    })
    .then((result) => {
      dispatch(homeActionCreator.getLeaderboardAction(result));
    })
    .catch((error) => {
      console.log("error " + error);
    });
};

export const getAssignedClassesHandler =
  (pageNum, pageSize, locale, navigate) => async (dispatch) => {
    // dispatch(setLoadingHandler());

    // dispatch(homeActionCreator.setLoadingAssignedClassesAction());
    // dispatch(homeActionCreator.setLoadingInProgressClassesAction());
    // dispatch(homeActionCreator.setLoadingNotInProgressClassesAction());

    await fetch(
      GET_ASSIGNED_CLASSES_API_URL +
        pageNum +
        "&pageSize=" +
        pageSize +
        "&Locale=" +
        locale,
      {
        method: "GET",
        headers: JSON.parse(localStorage.getItem("headers")),
      }
    )
      .then((response) => {
        let tenanatObj = JSON.parse(localStorage.getItem("tenantData"));

        if (
          response.status === 401 &&
          tenanatObj?.SecurityProviderName !== "aad"
        ) {
          // localStorage.clear();
          // sessionStorage.clear();
          // navigate("/");
          dispatch(logoutHandler(navigate));
        } else {
          return response.json();
        }
      })
      .then(async (result) => {
        if (pageSize >= result?.TotalRows) {
          // if (locale === "en-GB") {
          dispatch(homeActionCreator.getAssignedClassesAction(result?.Content));
          dispatch(
            homeActionCreator.getAssignedClassesCountAction(result?.TotalRows)
          );
          // } else {
          // const updatedForNonEnglish = result?.Content.filter(
          //   (shotclass) => shotclass?.Locale === locale
          // );
          // dispatch(
          //   homeActionCreator.getAssignedClassesAction(updatedForNonEnglish)
          // );
          // dispatch(
          //   homeActionCreator.getAssignedClassesCountAction(
          //     updatedForNonEnglish?.length
          //   )
          // );
          // }

          // await dispatch(
          //   getCompletedClassesHandler(
          //     1,
          //     result?.TotalCompletedCount,
          //     locale,
          //     navigate
          //   )
          // );
          // await dispatch(getTrendingClassesHandler(locale, navigate));
        } else {
          dispatch(
            getAssignedClassesHandler(1, result?.TotalRows, locale, navigate)
          );
        }
        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("error " + error);
      });
    // .finally(() => {
    //   dispatch(unSetLoadingHandler());
    // });
  };

export const getCompletedClassesHandler =
  (pageNum, pageSize, locale, navigate) => async (dispatch) => {
    // dispatch(setLoadingHandler());

    // dispatch(homeActionCreator.setLoadingCompletedClassesAction());

    await fetch(
      GET_COMPLETED_CLASSES_API_URL +
        pageNum +
        "&pageSize=" +
        pageSize +
        "&Locale=" +
        locale,
      {
        method: "GET",
        headers: JSON.parse(localStorage.getItem("headers")),
      }
    )
      .then((response) => {
        let tenanatObj = JSON.parse(localStorage.getItem("tenantData"));
        if (
          response.status === 401 &&
          tenanatObj?.SecurityProviderName !== "aad"
        ) {
          // localStorage.clear();
          // sessionStorage.clear();
          // navigate("/");
          dispatch(logoutHandler(navigate));
        } else {
          return response.json();
        }
      })
      .then((result) => {
        if (pageSize >= result?.TotalRows) {
          const completedClasses = result?.Content?.map((data) => {
            if (data.Mediatype === 1) {
              let videoDuration = convertInSecs(data.VideoDuration);
              let videoDurationPlayed = convertInSecs(data.VideoDurationPlayed);
              let width = getWidthInPercentage(
                videoDurationPlayed,
                videoDuration
              );
              return {
                ...data,
                width,
                videoDurationInNumber: videoDuration,
                videoDurationPlayedInNumber: videoDurationPlayed,
              };
            } else {
              return data;
            }
          });

          // if (locale === "en-GB") {
          dispatch(
            homeActionCreator.getCompletedClassesAction(completedClasses)
          );
          dispatch(
            homeActionCreator.getCompletedClassesCountAction(result?.TotalRows)
          );
          // } else {
          // const updatedForNonEnglish = completedClasses.filter(
          //   (shotclass) => shotclass?.Locale === locale
          // );
          // dispatch(
          //   homeActionCreator.getCompletedClassesAction(updatedForNonEnglish)
          // );
          // dispatch(
          //   homeActionCreator.getCompletedClassesCountAction(
          //     updatedForNonEnglish?.length
          //   )
          // );
          // }
        } else {
          dispatch(
            getCompletedClassesHandler(1, result?.TotalRows, locale, navigate)
          );
        }
        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("error " + error);
      });
    // .finally(() => {
    //   dispatch(unSetLoadingHandler());
    // });
  };

export const getTrendingClassesHandler =
  (locale, navigate) => async (dispatch) => {
    // dispatch(setLoadingHandler());

    // dispatch(homeActionCreator.setLoadingTrendingClassesAction());

    await fetch(GET_TRENDING_CLASSES_API_URL + locale, {
      method: "GET",
      headers: JSON.parse(localStorage.getItem("headers")),
    })
      .then((response) => {
        let tenanatObj = JSON.parse(localStorage.getItem("tenantData"));
        if (
          response.status === 401 &&
          tenanatObj?.SecurityProviderName !== "aad"
        ) {
          // localStorage.clear();
          // sessionStorage.clear();
          // navigate("/");
          dispatch(logoutHandler(navigate));
        } else {
          return response.json();
        }
      })
      .then((result) => {
        // if (locale === "en-GB") {
        dispatch(homeActionCreator.getTrendingClassesAction(result?.Content));
        dispatch(
          homeActionCreator.getTrendingClassesCountAction(result?.TotalRows)
        );
        // } else {
        // const updatedForNonEnglish = result?.Content.filter(
        //   (shotclass) => shotclass?.Locale === locale
        // );
        // dispatch(
        //   homeActionCreator.getTrendingClassesAction(updatedForNonEnglish)
        // );
        // dispatch(
        //   homeActionCreator.getTrendingClassesCountAction(
        //     updatedForNonEnglish?.length
        //   )
        // );
        // }

        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("error " + error);
      });
    // .finally(() => {
    //   dispatch(unSetLoadingHandler());
    // });
  };

export const getInProgressClassesHandler = (assignedClasses) => (dispatch) => {
  // dispatch(setLoadingHandler());
  const notInProgressClasses = [];
  const inProgressClasses = assignedClasses?.map((assignedClass) => {
    if (assignedClass.Mediatype === 1) {
      let videoDuration = convertInSecs(assignedClass.VideoDuration);
      let videoDurationPlayed = convertInSecs(
        assignedClass.VideoDurationPlayed
      );
      let width = getWidthInPercentage(videoDurationPlayed, videoDuration);
      if (assignedClass.UserClassStatus === "InProgress") {
        return {
          ...assignedClass,
          width,
          videoDurationInNumber: videoDuration,
          videoDurationPlayedInNumber: videoDurationPlayed,
        };
      } else if (width > 0) {
        return {
          ...assignedClass,
          width,
          videoDurationInNumber: videoDuration,
          videoDurationPlayedInNumber: videoDurationPlayed,
        };
      } else {
        notInProgressClasses.push(assignedClass);
        return null;
      }
    } else if (
      assignedClass.UserClassStatus !== "InProgress" &&
      assignedClass.Mediatype !== 1
    ) {
      notInProgressClasses.push(assignedClass);
      return null;
    } else if (assignedClass.UserClassStatus === "InProgress") {
      return {
        ...assignedClass,
        width: 0,
        videoDurationInNumber: 0,
        videoDurationPlayedInNumber: 0,
      };
    }
    notInProgressClasses.push(assignedClass);
    return null;
  });
  const notNullInProgress = inProgressClasses?.filter(
    (inProgressClass) => inProgressClass !== null
  );

  let processedClasses = new Promise((resolve, reject) => {
    try {
      dispatch(
        homeActionCreator.getNotInProgressClassesAction(notInProgressClasses)
      );
      dispatch(
        homeActionCreator.getNotInProgressClassesCountAction(
          notInProgressClasses?.length
        )
      );
      dispatch(homeActionCreator.getInProgressClassesAction(notNullInProgress));
      dispatch(
        homeActionCreator.getInProgressClassesCountAction(
          notNullInProgress?.length
        )
      );
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
  return processedClasses;

  // dispatch(unSetLoadingHandler());
};
export const getInProgressSeriesHandler = (assignedSeries) => (dispatch) => {
  // dispatch(setLoadingHandler());

  const notInProgressSeries = [];
  const inProgressSeries = [];
  assignedSeries.map((series) => {
    let width = getWidthInPercentage(
      series?.ShotClassCompletedCount,
      series?.ShotClassesCount
    );
    if (series?.ShotClassCompletedCount > 0) {
      inProgressSeries.push({ ...series, width });
    } else {
      notInProgressSeries.push({ ...series, width });
    }
    return null;
  });
  let processedSeries = new Promise((resolve, reject) => {
    try {
      dispatch(homeActionCreator.getInProgressSeriesAction(inProgressSeries));
      dispatch(
        homeActionCreator.getInProgressSeriesCountAction(
          inProgressSeries?.length
        )
      );
      dispatch(
        homeActionCreator.getNotInProgressSeriesAction(notInProgressSeries)
      );
      dispatch(
        homeActionCreator.getNotInProgressSeriesCountAction(
          notInProgressSeries?.length
        )
      );
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
  return processedSeries;

  // dispatch(unSetLoadingHandler());
};

export const getAssignedSeriesHandler =
  (pageNum, pageSize, locale, navigate) => async (dispatch) => {
    // dispatch(setLoadingHandler());

    // dispatch(homeActionCreator.setLoadingAssignedSeriesAction());
    // dispatch(homeActionCreator.setLoadingInProgressSeriesAction());
    // dispatch(homeActionCreator.setLoadingNotInProgressSeriesAction());

    await fetch(
      GET_ASSIGNED_SERIES_API_URL + pageNum + "&pageSize=" + pageSize,
      {
        method: "GET",
        headers: JSON.parse(localStorage.getItem("headers")),
      }
    )
      .then((response) => {
        let tenanatObj = JSON.parse(localStorage.getItem("tenantData"));
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 401 &&
          tenanatObj?.SecurityProviderName !== "aad"
        ) {
          // localStorage.clear();
          // sessionStorage.clear();
          // navigate("/");
          dispatch(logoutHandler(navigate));
        }
      })
      .then((result) => {
        if (pageSize >= result?.TotalRows) {
          // if (locale === "en-GB") {
          dispatch(homeActionCreator.getAssignedSeriesAction(result?.Content));
          dispatch(
            homeActionCreator.getAssignedSeriesCountAction(result?.TotalRows)
          );
          // } else {
          // dispatch(homeActionCreator.getAssignedSeriesAction([]));
          // dispatch(homeActionCreator.getAssignedSeriesCountAction(0));
          // }
        } else {
          dispatch(
            getAssignedSeriesHandler(1, result?.TotalRows, locale, navigate)
          );
        }
        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("error " + error);
      });
    // .finally(() => {
    //   dispatch(unSetLoadingHandler());
    // });
  };

export const getCompletedSeriesHandler =
  (pageNum, pageSize, locale, navigate) => async (dispatch) => {
    // dispatch(setLoadingHandler());

    // dispatch(homeActionCreator.setLoadingCompletedSeriesAction());

    await fetch(
      GET_COMPLETED_SERIES_API_URL + pageNum + "&pageSize=" + pageSize,
      {
        method: "GET",
        headers: JSON.parse(localStorage.getItem("headers")),
      }
    )
      .then((response) => {
        let tenanatObj = JSON.parse(localStorage.getItem("tenantData"));
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 401 &&
          tenanatObj?.SecurityProviderName !== "aad"
        ) {
          // localStorage.clear();
          // sessionStorage.clear();
          // navigate("/");
          dispatch(logoutHandler(navigate));
        }
      })
      .then((result) => {
        if (pageSize >= result?.TotalRows) {
          // if (locale === "en-GB") {
          dispatch(homeActionCreator.getCompletedSeriesAction(result?.Content));
          dispatch(
            homeActionCreator.getCompletedSeriesCountAction(result?.TotalRows)
          );
          // } else {
          //   dispatch(homeActionCreator.getCompletedSeriesAction([]));
          //   dispatch(homeActionCreator.getCompletedSeriesCountAction(0));
          // }
        } else {
          dispatch(
            getCompletedSeriesHandler(1, result?.TotalRows, locale, navigate)
          );
        }
        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("error " + error);
      });
    // .finally(() => {
    //   dispatch(unSetLoadingHandler());
    // });
  };
export const getTrendingSeriesHandler =
  (locale, navigate) => async (dispatch) => {
    // dispatch(setLoadingHandler());

    // dispatch(homeActionCreator.setLoadingTrendingSeriesAction());

    await fetch(GET_TRENDING_SERIES_API_URL, {
      method: "GET",
      headers: JSON.parse(localStorage.getItem("headers")),
    })
      .then((response) => {
        let tenanatObj = JSON.parse(localStorage.getItem("tenantData"));
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 401 &&
          tenanatObj?.SecurityProviderName !== "aad"
        ) {
          // localStorage.clear();
          // sessionStorage.clear();
          // navigate("/");
          dispatch(logoutHandler(navigate));
        }
      })
      .then((result) => {
        // if (locale === "en-GB") {
        dispatch(homeActionCreator.getTrendingSeriesAction(result?.Content));
        dispatch(
          homeActionCreator.getTrendingSeriesCountAction(result?.TotalRows)
        );
        // } else {
        //   dispatch(homeActionCreator.getTrendingSeriesAction([]));
        //   dispatch(homeActionCreator.getTrendingSeriesCountAction(0));
        // }
        // dispatch(unSetLoadingHandler());
      })
      .catch((error) => {
        // dispatch(unSetLoadingHandler());
        console.log("error " + error);
      });
    // .finally(() => {
    //   dispatch(unSetLoadingHandler());
    // });
  };

export const getTrainingTypes = (navigate) => async (dispatch) => {
  // dispatch(setLoadingHandler());

  await fetch(SHOTCLASS_API_URL + "/TrainingTypes", {
    method: "GET",
    headers: JSON.parse(localStorage.getItem("headers")),
  })
    .then((response) => {
      let tenanatObj = JSON.parse(localStorage.getItem("tenantData"));

      if (response.status === 200) {
        response.json().then((json) => {
          dispatch(homeActionCreator.getTrainingTypesDone(json));
          return json.Content;
        });
      } else if (
        response.status === 401 &&
        tenanatObj.SecurityProviderName !== "aad"
      ) {
        dispatch(logoutHandler(navigate));
      }
    })
    .catch((error) => {
      console.log("error " + error);
    });
  // .finally(() => {
  //   dispatch(unSetLoadingHandler());
  // });
};
