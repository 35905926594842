import React, { useEffect, useState } from "react";
import classes from "./PollResults.module.scss";
import HomeHeaderContainer from "../../../../containers/HomeContainers/HomeHeaderContainer/HomeHeaderContainer";
import { getPollHistory } from "../../../../store/actions/actionHandlers/connectActionHandler";
import { useSelector, useDispatch } from "react-redux";
import noImageFound from "../../../../assets/images/noimage-found.jpg";
import MissedVoteIcon from "../../../../components/CustomSvgIcons/MissedVoteIcon";
import NoConversationFound from "../../../../components/CommonComponents/NoConversationFound/NoConversationFound";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import noConversationFound from "../../../../assets/images/undraw_personal_opinions_re_qw29.gif";
import { Doughnut } from "react-chartjs-2";
import { FormattedMessage } from "react-intl";
// import TooltipMui from "@mui/material/Tooltip";
import ReportProgressContainer from "../../../../containers/MyAccountContainers/ReportProgressContainer/ReportProgressContainer";
import {
  setLoadingHandler,
  unSetLoadingHandler,
} from "../../../../store/actions/actionHandlers/errorActionHandler";
import { useLocation } from "react-router";
import MissedVoteTooltip from "../../../../components/VirtualOrLiveQuizComponents/Tooltip/Tooltip";
//import PollToolTip from "../PollToolTip/PollToolTip";
ChartJS.register(ArcElement, Tooltip, Legend);

const PollResults = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const pollIdFromNotification = query.get("pollId");
  console.log("pollIdFromNotification", pollIdFromNotification);
  const connectState = useSelector((state) => state.connectReducer);
  const [selectedQueryId, setSelectedQueryId] = useState();
  const [selectedQueryText, setSelectedQueryText] = useState();
  const [selectedResult, setSelectedResult] = useState();
  const { pollhistory } = connectState;
  const [donutData, setdonutData] = useState([]);
  const chartDimmension = {
    width: "350",
    height: "400",
    margin: "45",
    donutInnerArc: "0.65",
    donutOuterArc: "0.8",
    outerArcInner: "0.9",
    outerArcOuter: "0.9",
    polylinesPosition: "0.95",
    labelPosition: "0.99",
  };
  const chartColors = ["#B759F5", "#E59456", "#6DC5F3", "#EBC157"];

  // useEffect(() => {
  //   dispatch(getPollHistory(true));
  // }, [dispatch]);
  let countOfThreads = pollhistory?.Count;
  const onClickConversations = (queryId, queryText, selectedPollItem) => {
    setSelectedQueryId(queryId);
    setSelectedQueryText(queryText);
    setSelectedResult(selectedPollItem);
  };
  // let chartColors = ["#1490BE", "#A6CEE3", "#DAA520", "#D55C00", "#000000"];
  useEffect(() => {
    if (pollhistory?.Data?.length > 0) {
      onClickConversations(
        pollhistory.Data[0].DistributionId,
        pollhistory.Data[0].Question,
        pollhistory.Data[0]
      );
    }
  }, []);

  useEffect(() => {
    setdonutData([]);
    pollhistory?.Data?.forEach((poll) => {
      if (poll?.DistributionId === selectedQueryId) {
        poll.Options.forEach((data, index) => {
          if (data.Percentage > 0) {
            setdonutData((previousState) => [
              ...previousState,
              {
                name: data.Name,
                value: Math.round(data.Percentage),
                color: chartColors[index],
              },
            ]);
          }
        });
      }
    });
  }, [selectedQueryId, pollhistory?.Data, dispatch]);

  useEffect(() => {
    console.log("donutData", donutData);
  }, [donutData]);

  useEffect(() => {
    if (pollIdFromNotification) {
      const poll = pollhistory?.Data?.filter((item) => {
        return parseInt(item.PollId) === parseInt(pollIdFromNotification);
      });
      console.log("pollUmesh", poll, pollIdFromNotification);
      if (poll?.length > 0) {
        onClickConversations(poll[0].DistributionId, poll[0].Question, poll[0]);
        const element = document.getElementById(
          `poll_${pollIdFromNotification}`
        );
        console.log("eleemnt", element);
        if (element)
          element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [pollIdFromNotification, pollhistory]);

  return (
    <>
      <div className={classes.pollResult}>
        <div className={classes.chatBotRow}>
          <div className={classes.chatLeftListArea}>
            <div className={classes.listActionHeader}>
              <h2 className={classes.heading}>
                <FormattedMessage id="poll.pollResults" />
              </h2>
              {!pollIdFromNotification && (
                <div className={classes.tabList_box}>
                  <ul className={classes.tabList}>
                    <li
                      className={
                        props.activeTab === "Active_Polls" && classes.tab_active
                      }
                      onClick={() => props.setActiveTab("Active_Polls")}
                    >
                      <FormattedMessage id="poll.activePolls" />
                    </li>
                    <li
                      className={
                        props.activeTab === "Results" && classes.tab_active
                      }
                      onClick={() => props.setActiveTab("Results")}
                    >
                      <FormattedMessage id="poll.results" />
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className={`${classes.chatListArea} scrollbar_5px`}>
              <div className={classes.chatList}>
                {countOfThreads ? (
                  pollhistory.Data.sort(
                    (a, b) => new Date(b.EndedOn) - new Date(a.EndedOn)
                  ).map((el) => {
                    return (
                      <div className={classes.chatListItemContainer}>
                        <div
                          id={`poll_${el?.PollId}`}
                          className={`${classes.chatListItem} ${
                            selectedQueryId === el.DistributionId
                              ? classes.active
                              : ""
                          }`}
                          key={el.PollId}
                          onClick={(e) =>
                            onClickConversations(
                              el.DistributionId,
                              el.Question,
                              el
                            )
                          }
                        >
                          {el.PollImageUrl && (
                            <div className={classes.item_avatar}>
                              <img
                                className={classes.item_avatarImage}
                                src={el.PollImageUrl}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = noImageFound;
                                }}
                              />
                            </div>
                          )}
                          <div className={classes.chatContent}>
                            <div
                              className={classes.chatItemTitle}
                              title={el.Question}
                            >
                              {el.Question}
                            </div>
                            <div className={classes.chatItemFooter}>
                              <span className={classes.author}>
                                <FormattedMessage id="poll.total" /> :
                                {el.PaticipatedUsers}&nbsp;
                                <FormattedMessage id="Poll.votes" />
                              </span>
                            </div>
                          </div>
                          {el.IsMissed && (
                            // <TooltipMui
                            //   title={<FormattedMessage id="poll.youMissedToVote" />}
                            //   arrow
                            //   placement="right"
                            // >
                            // <MissedVoteTooltip
                            //   content={
                            //     <FormattedMessage id="poll.youMissedToVote" />
                            //   }
                            //   direction="left"
                            // >
                            <div className={classes.tooltip}>
                              <span className={classes.missed_icon}>
                                <MissedVoteIcon />
                              </span>
                              <span className={classes.tooltiptext}>
                                <FormattedMessage id="poll.youMissedToVote" />
                              </span>
                            </div>
                            //  </MissedVoteTooltip>
                            //  </TooltipMui>
                          )}
                        </div>

                        {selectedQueryId === el.DistributionId && (
                          <div className={classes.chatRightTypeAreaMobile}>
                            <div className={classes.chatBotHeader}>
                              <div className={classes.heading_box}></div>
                              {!pollIdFromNotification && (
                                <div className={classes.tabList_box}>
                                  <ul className={classes.tabList}>
                                    <li
                                      className={
                                        props.activeTab === "Active_Polls" &&
                                        classes.tab_active
                                      }
                                      onClick={() =>
                                        props.setActiveTab("Active_Polls")
                                      }
                                    >
                                      <FormattedMessage id="poll.activePolls" />
                                    </li>
                                    <li
                                      className={
                                        props.activeTab === "Results" &&
                                        classes.tab_active
                                      }
                                      onClick={() =>
                                        props.setActiveTab("Results")
                                      }
                                    >
                                      <FormattedMessage id="poll.results" />
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                            {countOfThreads ? (
                              <div className={classes.pollResultDetail}>
                                <div className={classes.pollResultInner}>
                                  {selectedQueryId && (
                                    <>
                                      <div className={classes.title_row}>
                                        <h2>{selectedResult.Question}</h2>
                                      </div>
                                      <div className={classes.chart_detail_row}>
                                        <div className={classes.chart_wrapper}>
                                          <div className={classes.chart_box}>
                                            {/* <Doughnut
                                              data={{
                                                labels:
                                                  selectedResult.Options.map(
                                                    (el) => el.Name
                                                  ),
                                                datasets:
                                                  selectedResult.PaticipatedUsers >
                                                  0
                                                    ? [
                                                        {
                                                          label: "# of Votes",
                                                          data: selectedResult.Options.map(
                                                            (el) => el.Count
                                                          ),
                                                          backgroundColor:
                                                            chartColors,
                                                        },
                                                      ]
                                                    : [
                                                        {
                                                          label: "# of Votes",
                                                          data: [100],
                                                          backgroundColor: [
                                                            "#bbbbbb",
                                                          ],
                                                        },
                                                      ],
                                              }}
                                              options={{
                                                elements: {
                                                  arc: {
                                                    borderWidth: 0,
                                                  },
                                                },
                                                cutout: "80%",
                                                plugins: {
                                                  legend: { display: false },
                                                  tooltip: {
                                                    enabled:
                                                      selectedResult.PaticipatedUsers >
                                                      0
                                                        ? true
                                                        : false,
                                                  },
                                                },
                                              }}
                                            /> */}
                                            {donutData.length > 0 && (
                                              <ReportProgressContainer
                                                data={donutData}
                                                colors={chartColors}
                                                showNameLabel={false}
                                                chartDimmension={
                                                  chartDimmension
                                                }
                                              />
                                            )}
                                            <div className={classes.vote_count}>
                                              <span className={classes.count}>
                                                {selectedResult.PaticipatedUsers >
                                                  0 &&
                                                selectedResult.PaticipatedUsers <
                                                  10
                                                  ? "0" +
                                                    selectedResult.PaticipatedUsers
                                                  : selectedResult.PaticipatedUsers}
                                              </span>{" "}
                                              <FormattedMessage id="Poll.votes" />
                                            </div>
                                          </div>
                                        </div>
                                        <div className={classes.result_info}>
                                          <h3>
                                            {selectedResult.PaticipatedUsers}{" "}
                                            <FormattedMessage id="poll.peopleVoted" />
                                            {selectedResult.IsMissed && (
                                              <>
                                                <span
                                                  className={
                                                    classes.missed_icon
                                                  }
                                                >
                                                  <MissedVoteIcon />
                                                  <span
                                                    className={
                                                      classes.polltooltiptextNew
                                                    }
                                                  >
                                                    <FormattedMessage id="poll.youMissedToVote" />
                                                  </span>
                                                </span>
                                              </>
                                            )}
                                          </h3>
                                          <div className={classes.legend_list}>
                                            {selectedResult.Options.map(
                                              (el, index) => {
                                                return (
                                                  <div
                                                    className={
                                                      classes.legend_item
                                                    }
                                                    key={el.OptionNo}
                                                  >
                                                    <div
                                                      className={
                                                        classes.youVoted
                                                      }
                                                    >
                                                      {el.IsAnswer && (
                                                        <span
                                                          className={
                                                            classes.polltooltiptext
                                                          }
                                                        >
                                                          <FormattedMessage id="poll.youVoted" />
                                                        </span>
                                                      )}
                                                      <span
                                                        className={
                                                          classes.legend_color
                                                        }
                                                        style={{
                                                          background:
                                                            chartColors[index],
                                                        }}
                                                      ></span>
                                                    </div>
                                                    {el.IsAnswer ? (
                                                      <div
                                                        className={
                                                          classes.legend_txt
                                                        }
                                                      >
                                                        {el.Name}
                                                        <span
                                                          className={
                                                            classes.polltooltiptext
                                                          }
                                                        >
                                                          <FormattedMessage id="poll.youVoted" />
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      <span
                                                        className={
                                                          classes.legend_txt
                                                        }
                                                      >
                                                        {el.Name}
                                                      </span>
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                          <p>
                                            <FormattedMessage id="poll.pollEndedOn" />{" "}
                                            {selectedResult.EndedOn}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {!selectedQueryId && (
                                    <NoConversationFound
                                      title={"Select a Poll to View Result"}
                                      imageUrl={noConversationFound}
                                    />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className={classes.pollResultDetail}>
                                <div className={classes.pollResultInner}>
                                  <NoConversationFound
                                    title={
                                      <FormattedMessage id="poll.noResultFound" />
                                    }
                                    imageUrl={noConversationFound}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.noThread}>
                    <div className={classes.noResult}><FormattedMessage id="poll.noPollResults" /></div>
                    <div className={classes.noResultImg}>
                      <NoConversationFound
                        title={<FormattedMessage id="poll.noResultFound" />}
                        imageUrl={noConversationFound}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.chatLeftFooter}>
              {/* <div className={classes.lastVisited}>
                <FormattedMessage id="poll.lastVisitedOn" /> :{" "}
                <span>12/08/2021</span>
              </div> */}
              <div className={classes.totalQueries}>
                <FormattedMessage id="poll.totalResults" /> :{" "}
                <span>{countOfThreads}</span>
              </div>
            </div>
          </div>
          <div className={classes.chatRightTypeArea}>
            <div className={classes.chatBotHeader}>
              <div className={classes.heading_box}></div>
              {!pollIdFromNotification && (
                <div className={classes.tabList_box}>
                  <ul className={classes.tabList}>
                    <li
                      className={
                        props.activeTab === "Active_Polls" && classes.tab_active
                      }
                      onClick={() => props.setActiveTab("Active_Polls")}
                    >
                      <FormattedMessage id="poll.activePolls" />
                    </li>
                    <li
                      className={
                        props.activeTab === "Results" && classes.tab_active
                      }
                      onClick={() => props.setActiveTab("Results")}
                    >
                      <FormattedMessage id="poll.results" />
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {countOfThreads ? (
              <div className={classes.pollResultDetail}>
                <div className={classes.pollResultInner}>
                  {selectedQueryId && (
                    <>
                      <div className={classes.title_row}>
                        <h2>{selectedResult.Question}</h2>
                      </div>
                      <div className={classes.chart_detail_row}>
                        <div className={classes.chart_wrapper}>
                          <div className={classes.chart_box}>
                            {/* <Doughnut
                              data={{
                                labels: selectedResult.Options.map(
                                  (el) => el.Name
                                ),
                                datasets:
                                  selectedResult.PaticipatedUsers > 0
                                    ? [
                                        {
                                          label: "# of Votes",
                                          data: selectedResult.Options.map(
                                            (el) => el.Count
                                          ),
                                          backgroundColor: chartColors,
                                        },
                                      ]
                                    : [
                                        {
                                          label: "# of Votes",
                                          data: [100],
                                          backgroundColor: ["#bbbbbb"],
                                        },
                                      ],
                              }}
                              options={{
                                elements: {
                                  arc: {
                                    borderWidth: 0,
                                  },
                                },
                                cutout: "80%",
                                plugins: {
                                  legend: { display: false },
                                  tooltip: {
                                    enabled:
                                      selectedResult.PaticipatedUsers > 0
                                        ? true
                                        : false,
                                  },
                                },
                              }}
                            /> */}
                            {donutData.length > 0 && (
                              <ReportProgressContainer
                                data={donutData}
                                colors={chartColors}
                                showNameLabel={false}
                                chartDimmension={chartDimmension}
                              />
                            )}
                            <div className={classes.vote_count}>
                              <span className={classes.count}>
                                {selectedResult.PaticipatedUsers > 0 &&
                                selectedResult.PaticipatedUsers < 10
                                  ? "0" + selectedResult.PaticipatedUsers
                                  : selectedResult.PaticipatedUsers}
                              </span>{" "}
                              <FormattedMessage id="Poll.votes" />
                            </div>
                          </div>
                        </div>
                        <div className={classes.result_info}>
                          <h3>
                            {selectedResult.PaticipatedUsers}{" "}
                            <FormattedMessage id="poll.peopleVoted" />
                            {selectedResult.IsMissed && (
                              <>
                                <span className={classes.missed_icon}>
                                  <MissedVoteIcon />
                                  <span className={classes.polltooltiptextNew}>
                                    <FormattedMessage id="poll.youMissedToVote" />
                                  </span>
                                </span>
                              </>
                            )}
                          </h3>
                          <div className={classes.legend_list}>
                            {selectedResult.Options.map((el, index) => {
                              return (
                                <div
                                  className={classes.legend_item}
                                  key={el.OptionNo}
                                >
                                  <div className={classes.youVoted}>
                                    {el.IsAnswer && (
                                      <span className={classes.polltooltiptext}>
                                        <FormattedMessage id="poll.youVoted" />
                                      </span>
                                    )}
                                    <span
                                      className={classes.legend_color}
                                      style={{ background: chartColors[index] }}
                                    ></span>
                                  </div>
                                  {el.IsAnswer ? (
                                    <div className={classes.legend_txt}>
                                      {el.Name}
                                      <span className={classes.polltooltiptext}>
                                        <FormattedMessage id="poll.youVoted" />
                                      </span>
                                    </div>
                                  ) : (
                                    <span className={classes.legend_txt}>
                                      {el.Name}
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                          <p>
                            <FormattedMessage id="poll.pollEndedOn" />{" "}
                            {selectedResult.EndedOn}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  {!selectedQueryId && (
                    <NoConversationFound
                      title={"Select a Poll to View Result"}
                      imageUrl={noConversationFound}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.pollResultDetail}>
                <div className={classes.pollResultInner}>
                  <NoConversationFound
                    title={<FormattedMessage id="poll.noResultFound" />}
                    imageUrl={noConversationFound}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PollResults;
