import React from "react";

const ArrowNextIcon = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.291"
      height="12.332"
      viewBox="0 0 19.291 12.332"
    >
      <g id="arrow_nxt" transform="translate(0 0)">
        <path
          id="Path_341"
          data-name="Path 341"
          d="M2202.948,3205.042a.9.9,0,0,1,.1.1l.048.069.044.079a1.524,1.524,0,0,1,.065.147l.027.086a1.667,1.667,0,0,1,0,.9l-.027.085a1.529,1.529,0,0,1-.065.147l-.044.079-.048.069a.9.9,0,0,1-.1.1l-5.6,5.09c-.325.3-.743.12-.932-.394a1.469,1.469,0,0,1,.254-1.469l3.391-3.083h-15.383c-.377,0-.682-.483-.682-1.075s.3-1.076.682-1.076h15.383l-3.391-3.083a1.469,1.469,0,0,1-.254-1.469c.188-.514.606-.692.932-.394Z"
          transform="translate(-2184 -3199.808)"
          fill="#404040"
        />
      </g>
    </svg>
  );
};
export default ArrowNextIcon;
