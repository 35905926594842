import { setSelectedLanguageHandler } from "../store/actions/actionHandlers/homeActionHandler";

const addLangToLocalStorage = (languageTerm, language, dispatch) => {
    const replacedLanguageTerm = languageTerm
        .toString()
        .replaceAll(/&/g, "&")
        .replaceAll(/</g, "<")
        .replaceAll(/>/g, ">")
        .replaceAll(/"/g, '"')
        .replaceAll(/'/g, "'");
    localStorage.setItem("languageTerm", replacedLanguageTerm);
    const replacedLanguage = language
        .toString()
        .replaceAll(/&/g, "&")
        .replaceAll(/</g, "<")
        .replaceAll(/>/g, ">")
        .replaceAll(/"/g, '"')
        .replaceAll(/'/g, "'");
    localStorage.setItem("language", replacedLanguage);
    dispatch(setSelectedLanguageHandler(languageTerm?.split("-")[0]));
    const savedLanguage = localStorage.getItem("languageTerm");
    return savedLanguage;
};

export default addLangToLocalStorage;
