import React, { useState } from "react";
import { useSelector } from "react-redux";
import profilePicture from "../../../assets/images/homeIcons/userCircleIcon.svg";
import { NavLink } from "react-router-dom";
import { logoutHandler } from "../../../store/actions/actionHandlers/loginActionHandler";
import { useDispatch } from "react-redux";
import AccountUserIcon from "../../CustomSvgIcons/AccountUserIcon";
import LogoutIcon from "../../CustomSvgIcons/LogoutIcon";
import NotificationBellIcon from "../../CustomSvgIcons/NotificationBellIcon";
import { useLocation, useNavigate, useParams } from "react-router";
import classes from "./HomeHeaderProfile.module.scss";
import { FormattedMessage } from "react-intl";
// import { userScoreObjCreation } from "../../../utils/commonQuizFunction";
// import { postVQAssessScore } from "../../../store/actions/actionHandlers/quizActionHandler";
import QuizModal from "../../VirtualOrLiveQuizComponents/QuizModal/QuizModal";
import {
  endQuizTimer,
  setLiveQuizNormalExitAction,
  startQuizTimer,
} from "../../../store/actions/actionCreators/quizActionCreator";
import { postVQAssessScore } from "../../../store/actions/actionHandlers/quizActionHandler";
import { convertToMillisec } from "../../../utils/commonQuizFunction";
import { updateVideoPlayedDuration } from "../../../store/actions/actionHandlers/classActionHandler";
import {
  setLoadingHandler,
  unSetLoadingHandler,
} from "../../../store/actions/actionHandlers/errorActionHandler";

const HomeHeaderProfile = ({ reference, handleClickProfilePopUp }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();

  const homeState = useSelector((state) => state.homeReducer);
  const { notifications, themeStyles } = homeState;
  const userState = useSelector((state) => state.userReducer);
  const { profile } = userState;
  const loginState = useSelector((state) => state.loginReducer);
  const { lastLoginTime } = loginState;
  const quizState = useSelector((state) => state.quizReducer);
  const { quizData, virtualQuizData } = quizState;
  const classState = useSelector((state) => state.classReducer);
  const { classDetails, videoDurationForLogout, currentView } = classState;

  const [hoverBellIcon, setHoverBellIcon] = useState(false);
  const [hoverAccountUserIcon, setHoverAccountUserIcon] = useState(false);
  const [hoverLogoutIcon, setHoverLogoutIcon] = useState(false);
  const [isExit, setIsExit] = useState(false);

  const userScoreObjCreation = (IsSubmitted, IsUserAssessmentSuccess) => {
    console.log("inside----------");
    const totalQDuration = convertToMillisec();
    const { ShotClassId, VirtualOrLiveQuizEventId } = quizData;
    let UserQuestionResponse = [];
    virtualQuizData.AssessmentSet.QuestionList.forEach((ques) => {
      let optionId, IsRightAnswer;
      ques.ChoiceList.forEach((option) => {
        if (
          option.Isselected === true ||
          option.IsselectedByUserInResonse === true
        ) {
          optionId = option.ID;
          if (option.IsAnswer === true) {
            IsRightAnswer = true;
          } else {
            IsRightAnswer = false;
          }
        }
      });
      UserQuestionResponse.push({
        QuestionId: ques.ID,
        ChoiceId: optionId,
        IsRightAnswer: IsRightAnswer,
      });
    });
    return {
      ClassId: parseInt(ShotClassId),
      ClassName: virtualQuizData.Name,
      CourseCompletionDuration: totalQDuration,
      IsQuizSubmitForLiveOrVirtualEvent: IsSubmitted,
      IsUserAssessmentSuccess: IsUserAssessmentSuccess,
      Score: 0,
      TotalCACount: 0,
      UserQuestionResponse: UserQuestionResponse,
      VirtualOrLiveQuizEventId: VirtualOrLiveQuizEventId,
    };
  };

  const onclickedExit = () => {
    if (isExit) {
      dispatch(setLiveQuizNormalExitAction(false));
    } else {
      dispatch(setLiveQuizNormalExitAction(true));
    }
    setIsExit(!isExit);
    dispatch(startQuizTimer());
  };
  const onConfirmExit = () => {
    if (
      location.pathname === "/quiz/virtualquiz" ||
      location.pathname.includes("virtualquiz")
    ) {
      const userScoreObj = userScoreObjCreation(false, false);
      dispatch(postVQAssessScore(userScoreObj, userScoreObj.ClassId));
    }
    dispatch(logoutHandler(navigate, true));
  };

  let dateObj = new Date(lastLoginTime + "Z");
  let lastLoginTimeString = dateObj.toString().replace(/GMT.*/g, "");
  let securityProvider = localStorage.getItem("securityProviderName");
  return (
    <>
      <div ref={reference} className={classes.homeHeaderProfile}>
        <div className={classes.homeHeaderProfile__imageAndName}>
          <img
            src={
              profile?.ProfileImageUrl === "Profil" ||
              profile?.ProfileImageUrl === "" ||
              profile?.ProfileImageUrl === null
                ? profilePicture
                : profile?.ProfileImageUrl
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = profilePicture;
            }}
            className={classes.homeHeaderProfile__image}
            alt="profile"
          />
          <div className={classes.homeHeaderProfile__fullName}>
            {profile?.FirstName + " " + profile?.LastName}
          </div>
        </div>
        <div className={classes.homeHeaderProfile__links}>
          <NavLink
            className={classes.homeHeaderProfile__link}
            to="/account/notifications"
            onClick={() => handleClickProfilePopUp()}
            onMouseEnter={() => setHoverBellIcon(true)}
            onMouseLeave={() => setHoverBellIcon(false)}
          >
            <div className={classes.homeHeaderProfile__link__notification}>
              {hoverBellIcon ? (
                <NotificationBellIcon
                  fill={themeStyles?.CustomData?.PrimaryColor1}
                />
              ) : (
                <NotificationBellIcon />
              )}
              <div
                className={classes.homeHeaderProfile__link__notificationCount}
              >
                {notifications?.TotalNotifications > 99
                  ? "99+"
                  : notifications?.TotalNotifications}
              </div>
            </div>
            <div>
              <FormattedMessage id="notifications.notifications" />
            </div>
          </NavLink>
          <NavLink
            className={classes.homeHeaderProfile__link}
            onClick={() => handleClickProfilePopUp()}
            to="/account/myaccount"
            onMouseEnter={() => setHoverAccountUserIcon(true)}
            onMouseLeave={() => setHoverAccountUserIcon(false)}
          >
            {hoverAccountUserIcon ? (
              <AccountUserIcon fill={themeStyles?.CustomData?.PrimaryColor1} />
            ) : (
              <AccountUserIcon />
            )}
            <div>
              <FormattedMessage id="home.account" />
            </div>
          </NavLink>
          <div
            className={classes.homeHeaderProfile__link}
            onClick={() => {
              if (
                (location.pathname.includes("/shotclass/" + param?.id) ||
                  location.pathname.includes("/classDetails/" + param?.id) ||
                  location.pathname.includes("/classDetail/" + param?.id)) &&
                classDetails?.Mediatype === 1 &&
                currentView === 0
              ) {
                dispatch(setLoadingHandler());
                dispatch(
                  updateVideoPlayedDuration(
                    classDetails?.ID,
                    videoDurationForLogout
                  )
                ).then(() => {
                  dispatch(logoutHandler(navigate, true));
                  dispatch(unSetLoadingHandler());
                });
              } else if (
                location.pathname === "/quiz/livequiz" ||
                location.pathname.includes("livequiz") ||
                ((location.pathname === "/quiz/virtualquiz" ||
                  location.pathname.includes("virtualquiz")) &&
                  quizData.UserRole === "participant" &&
                  quizData.IsVirtualOrLiveQuizSubmitted === false &&
                  quizData.IsExpired === false &&
                  localStorage.getItem("quizSubmit") === "false")
              ) {
                dispatch(setLiveQuizNormalExitAction(true));
                setIsExit(!isExit);
                dispatch(endQuizTimer());
              } else {
                dispatch(logoutHandler(navigate, true));
              }
              // if (localStorage.getItem("quizSubmit") === "false") {
              //   const userScoreObj = userScoreObjCreation(
              //     false,
              //     false,
              //     quizData,
              //     virtualQuizData
              //   );
              //   dispatch(postVQAssessScore(userScoreObj, userScoreObj.ClassId));
              // }
              // dispatch(logoutHandler(navigate));
            }}
            to="/"
            onMouseEnter={() => setHoverLogoutIcon(true)}
            onMouseLeave={() => setHoverLogoutIcon(false)}
          >
            {hoverLogoutIcon ? (
              <LogoutIcon fill={themeStyles?.CustomData?.PrimaryColor1} />
            ) : (
              <LogoutIcon />
            )}
            <div>
              <FormattedMessage id="home.logout" />
            </div>
          </div>
          {isExit && (
            <QuizModal
              // isShowCrossIcon
              closeModal={onConfirmExit}
              heading=""
              bodytext={`Are you sure you want to leave this 
                          ${
                            location.pathname === "/quiz/virtualquiz" ||
                            location.pathname.includes("virtualquiz")
                              ? "VirtualQuiz Event?"
                              : "LiveQuiz Event?"
                          }`}
              okButtonText="No"
              IsCancelButtonRequired={true}
              cancelBtnText="Yes"
              submitClick={onclickedExit}
            />
          )}
        </div>
        {securityProvider && securityProvider === "custom" && (
          <div className={classes.homeHeaderProfile__link__lastVisited}>
            <FormattedMessage id="home.lastVisited" />: {lastLoginTimeString}
          </div>
        )}
      </div>
      <div className={classes.profileOpaqueLayer}></div>
    </>
  );
};

export default HomeHeaderProfile;
