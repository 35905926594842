const actionTypes = {
  API_REQ_STARTED: "API_REQ_STARTED",
  loginTypes: {
    PRE_LOGIN: "PRE_LOGIN",
    GET_TENANT_DETAILS: "GET_TENANT_DETAILS",
    LOGIN: "LOGIN",
    LAST_LOGIN_TIME: "LAST_LOGIN_TIME",
    LOGOUT: "LOGOUT",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
  },
  userTypes: {
    IS_USER_EXISTS: "IS_USER_EXISTS",
    IS_USER_ACTIVE: "IS_USER_ACTIVE",
    IS_MAIL_SENT_FORGOT_PASSWORD: "IS_MAIL_SENT_FORGOT_PASSWORD",
    IS_PASSWORD_RESET_REQUIRED: "IS_PASSWORD_RESET_REQUIRED",
    GET_USER_PROFILE: "GET_USER_PROFILE",
    GET_USER_PRIVACY_POLICY: "GET_USER_PRIVACY_POLICY",
    POST_USER_PRIVACY_POLICY: "POST_USER_PRIVACY_POLICY",
    SET_INITIAL_PRIVACY_POLICY_STATE: "SET_INITIAL_PRIVACY_POLICY_STATE",
    IS_PASSWORD_RESET_DONE: "IS_PASSWORD_RESET_DONE",
    PUT_EDIT_PROFILE: "PUT_EDIT_PROFILE",
  },
  errorTypes: {
    SET_ERROR: "SET_ERROR",
    UNSET_ERROR: "UNSET_ERROR",
    API_ERROR: "API_ERROR",
    SET_LOADING: "SET_LOADING",
    UNSET_LOADING: "UNSET_LOADING",
  },
  homeTypes: {
    GET_ALL_LANGUAGES: "GET_ALL_LANGUAGES",
    SET_LANGUAGE: "SET_LANGUAGE",
    GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
    SET_THEME_STYLES: "SET_THEME_STYLES",
    SET_HOME_BANNER_ITERATION: "SET_HOME_BANNER_ITERATION",
    RESET_TIMER: "RESET_TIMER",
    IS_REDIRECTED_TO_TOPICS: "IS_REDIRECTED_TO_TOPICS",

    GET_LEADERBOARD: "GET_LEADERBOARD",

    GET_ASSIGNED_CLASSES: "GET_ASSIGNED_CLASSES",
    SET_LOADING_ASSIGNED_CLASSES: "SET_LOADING_ASSIGNED_CLASSES",
    GET_ASSIGNED_CLASSES_COUNT: "GET_ASSIGNED_CLASSES_COUNT",

    GET_COMPLETED_CLASSES: "GET_COMPLETED_CLASSES",
    SET_LOADING_COMPLETED_CLASSES: "SET_LOADING_COMPLETED_CLASSES",
    GET_COMPLETED_CLASSES_COUNT: "GET_COMPLETED_CLASSES_COUNT",

    GET_TRENDING_CLASSES: "GET_TRENDING_CLASSES",
    SET_LOADING_TRENDING_CLASSES: "SET_LOADING_TRENDING_CLASSES",
    GET_TRENDING_CLASSES_COUNT: "GET_TRENDING_CLASSES_COUNT",

    GET_INPROGRESS_CLASSES: "GET_INPROGRESS_CLASSES",
    SET_LOADING_INPROGRESS_CLASSES: "SET_LOADING_INPROGRESS_CLASSES",
    GET_INPROGRESS_CLASSES_COUNT: "GET_INPROGRESS_CLASSES_COUNT",

    GET_NOTINPROGRESS_CLASSES: "GET_NOTINPROGRESS_CLASSES",
    SET_LOADING_NOTINPROGRESS_CLASSES: "SET_LOADING_NOTINPROGRESS_CLASSES",
    GET_NOTINPROGRESS_CLASSES_COUNT: "GET_NOTINPROGRESS_CLASSES_COUNT",

    GET_ASSIGNED_SERIES: "GET_ASSIGNED_SERIES",
    SET_LOADING_ASSIGNED_SERIES: "SET_LOADING_ASSIGNED_SERIES",
    GET_ASSIGNED_SERIES_COUNT: "GET_ASSIGNED_SERIES_COUNT",

    GET_COMPLETED_SERIES: "GET_COMPLETED_SERIES",
    SET_LOADING_COMPLETED_SERIES: "SET_LOADING_COMPLETED_SERIES",
    GET_COMPLETED_SERIES_COUNT: "GET_COMPLETED_SERIES_COUNT",

    GET_TRENDING_SERIES: "GET_TRENDING_SERIES",
    SET_LOADING_TRENDING_SERIES: "SET_LOADING_TRENDING_SERIES",
    GET_TRENDING_SERIES_COUNT: "GET_TRENDING_SERIES_COUNT",

    GET_INPROGRESS_SERIES: "GET_INPROGRESS_SERIES",
    SET_LOADING_INPROGRESS_SERIES: "SET_LOADING_INPROGRESS_SERIES",
    GET_INPROGRESS_SERIES_COUNT: "GET_INPROGRESS_SERIES_COUNT",

    GET_NOTINPROGRESS_SERIES: "GET_NOTINPROGRESS_SERIES",
    SET_LOADING_NOTINPROGRESS_SERIES: "SET_LOADING_NOTINPROGRESS_SERIES",
    GET_NOTINPROGRESS_SERIES_COUNT: "GET_NOTINPROGRESS_SERIES_COUNT",

    HOME_GET_TRAININGTYPE: "HOME_GET_TRAININGTYPE",

    SET_HOME_FILTER: "SET_HOME_FILTER",
    SET_HOME_FILTER_SELECTED: "SET_HOME_FILTER_SELECTED",

    SET_INITIAL_CLASSES_SERIES_STATE: "SET_INITIAL_CLASSES_SERIES_STATE",
  },
  favoriteTypes: {
    IS_FAV_AVAILABLE: "IS_FAV_AVAILABLE",

    GET_OTHER_FAV_CLASSES: "GET_OTHER_FAV_CLASSES",
    SET_LOADING_OTHER_FAV_CLASSES: "SET_LOADING_OTHER_FAV_CLASSES",
    GET_OTHER_FAV_CLASSES_COUNT: "GET_OTHER_FAV_CLASSES_COUNT",

    GET_OTHER_FAV_SERIES: "GET_OTHER_FAV_SERIES",
    SET_LOADING_OTHER_FAV_SERIES: "SET_LOADING_OTHER_FAV_SERIES",
    GET_OTHER_FAV_SERIES_COUNT: "GET_OTHER_FAV_SERIES_COUNT",

    SET_INITIAL_FAVORITE_CLASSES_SERIES_STATE:
      "SET_INITIAL_FAVORITE_CLASSES_SERIES_STATE",
  },
  classTypes: {
    GET_CLASS_DETAILS: "GET_CLASS_DETAILS",
    SET_CURRENT_CLASS_VIEW: "SET_CURRENT_CLASS_VIEW",
    GET_CLASS_TAB: "GET_CLASS_TAB",
    GET_CLASS_ASSET_ID: "GET_CLASS_ASSET_ID",
    GET_CLASS_QUERIES: "GET_CLASS_QUERIES",
    GET_SCORM_VERSION: "GET_SCORM_VERSION",
    GET_SCORM_BOOKMARK: "GET_SCORM_BOOKMARK",
    ADD_REMOVE_FAVORITE_CLASS: "ADD_REMOVE_FAVORITE_CLASS",
    UPDATE_VIDEO_DURATION_FOR_LOGOUT: "UPDATE_VIDEO_DURATION_FOR_LOGOUT",
    POST_ASSESSMENT_SCORE: "POST_ASSESSMENT_SCORE",
    GET_NEXT_CLASS: "GET_NEXT_CLASS",
    GET_NEXT_CLASS_IN_SERIES: "GET_NEXT_CLASS_IN_SERIES",
    GET_RELATED_CLASSES: "GET_RELATED_CLASSES",
    POST_SURVEY: "POST_SURVEY",
  },
  seriesTypes: {
    GET_CLASSES_BY_SERIESID: "GET_CLASSES_BY_SERIESID",
    ADD_REMOVE_FAVORITE_SERIES: "ADD_REMOVE_FAVORITE_SERIES",
  },
  topicsTypes: {
    GET_ALL_PUBLISHED_TOPICS: "GET_ALL_PUBLISHED_TOPICS",
    SET_LOADING_PUBLISHED_TOPICS: "SET_LOADING_PUBLISHED_TOPICS",
    GET_ALL_PUBLISHED_TOPICS_COUNT: "GET_ALL_PUBLISHED_TOPICS_COUNT",

    GET_SUB_TOPICS: "GET_SUB_TOPICS",
    GET_TOPIC_DETAILS: "GET_TOPIC_DETAILS",
    SET_LOADING_TOPICS_DETAILS: "SET_LOADING_TOPICS_DETAILS",

    SET_INITIAL_TOPICS_STATE: "SET_INITIAL_TOPICS_STATE",
  },
  connectTypes: {
    GET_GENERAL_QUERIES: "GET_GENERAL_QUERIES",
    CONNECT_FORUM_TOPICS: "CONNECT_FORUM_TOPICS",
    CONNECT_FORUM_TOPICSFOLLOWUNFOLLOW: "CONNECT_FORUM_TOPICSFOLLOWUNFOLLOW",
    CONNECT_FORUM_TOPICSDETAILS: "CONNECT_FORUM_TOPICSDETAILS",
    CONNECT_FORUM_ADDTHREADS: "CONNECT_FORUM_ADDTHREADS",
    CONNECT_FORUM_ADDTHREADSCOMMENTS: "CONNECT_FORUM_ADDTHREADSCOMMENTS",
    CONNECT_FORUM_DELETETHREAD: "CONNECT_FORUM_DELETETHREAD",
    CONNECT_POLL_GETPOLLS: "CONNECT_POLL_GETPOLLS",
    CONNECT_POLL_GETRESPONSE: "CONNECT_POLL_GETRESPONSE",
    CONNECT_POLL_POSTUSERPOLLRESPONSE: "CONNECT_POLL_POSTUSERPOLLRESPONSE",
    CONNECT_POLL_ISPOLLAVAILABLE: "CONNECT_POLL_ISPOLLAVAILABLE",
    CONNECT_POLL_GETPOLLHISTORY: "CONNECT_POLL_GETPOLLHISTORY",
  },
  commonTypes: {
    GET_RELATED_RESULT: "GET_RELATED_RESULT",
    TOGGLE_ADVANCE_SEARCH: "TOGGLE_ADVANCE_SEARCH",
    TOGGLE_ADVANCE_SEARCH_LOADING: "TOGGLE_ADVANCE_SEARCH_LOADING",
    GLOBAL_SEARCH_TEXT: "GLOBAL_SEARCH_TEXT",
    TOGGLE_SEARCHBAR: "TOGGLE_SEARCHBAR",
    DROPDOWN_TEXT_BOOLEAN: "DROPDOWN_TEXT_BOOLEAN",
    UPDATE_GLOBAL_CHECKBOX: "UPDATE_GLOBAL_CHECKBOX",
    UPDATE_RESULT_LANGUAGE: "UPDATE_RESULT_LANGUAGE",
  },
  globalSearchTypes: {
    GLOBAL_SEARCH: "GLOBAL_SEARCH",
    REMOVE_GLOBAL_RESULT: "REMOVE_GLOBAL_RESULT",
    UPDATE_DID_YOU_MEAN_SUGGESTIONS: "UPDATE_DID_YOU_MEAN_SUGGESTIONS",
    UPDATE_SEARCH_TEXT: "UPDATE_SEARCH_TEXT",
  },
  contributeTypes: {
    POST_CONTRIBUTE_CLASSES: "POST_CONTRIBUTE_CLASSES",
    POST_CONTRIBUTE_FILE_CLASSES: "POST_CONTRIBUTE_FILE_CLASSES",
    GET_CONTRIBUTE_CLASSES_SUBMITTED: "GET_CONTRIBUTE_CLASSES_SUBMITTED",
    GET_CONTRIBUTE_CLASSES_COUNT: "GET_CONTRIBUTE_CLASSES_COUNT",
    GET_CONTRIBUTE_YOUTUBE_SEARCH: "GET_CONTRIBUTE_YOUTUBE_SEARCH",
    GET_CONTRIBUTE_YOUTUBE_COUNT: "GET_CONTRIBUTE_YOUTUBE_COUNT",
    GET_CONTRIBUTE_CLASS_DETAILS: "GET_CONTRIBUTE_CLASS_DETAILS",
    GET_CONTRIBUTE_ASSET_ID: "GET_CONTRIBUTE_ASSET_ID",
    UPLOAD_CHUNK: "UPLOAD_CHUNK",
  },
  quizTypes: {
    VALIDATE_PASSCODE: "VALIDATE_PASSCODE",
    RESET_QUIZ_TO_PASSCODE_ACCESS_PAGE: "RESET_QUIZ_TO_PASSCODE_ACCESS_PAGE",
    virtualQuiz: {
      FETCH_QUIZ_DETAILS: "FETCH_QUIZ_DETAILS",
      POSTVQASSESSCORE: "POSTVQASSESSCORE",
      FETCH_QUIZ_LEADERBOARD: "FETCH_QUIZ_LEADERBOARD",
      FETCH_QUIZ_QNA: "FETCH_QUIZ_QNA",
      FETCH_QUIZ_CORRECT_ANSWER_REPORT: "FETCH_QUIZ_CORRECT_ANSWER_REPORT",
      UNATTEMPTED_QUESTION_NUMBER: "UNATTEMPTED_QUESTION_NUMBER",
      timer: {
        VQUIZ_TIMER_STARTED: "VQUIZ_TIMER_STARTED",
        VQUIZ_TIMER_ENDED: "VQUIZ_TIMER_ENDED",
        VQUIZ_TIMER_ENDEDCOUNT: "VQUIZ_TIMER_ENDEDCOUNT",
        VQUIZ_TIMER_STARTEDCOUNT: "VQUIZ_TIMER_STARTEDCOUNT",
        SET_TIMER_DIALOG: "SET_TIMER_DIALOG",
        SET_SESSION_DIALOG: "SET_SESSION_DIALOG",
      },
    },
    liveQuiz: {
      SET_SIGNALR_NAGOTIATE: "SET_SIGNALR_NAGOTIATE",
      FETCH_BEGIN_END_LIVE_QUIZ_QUESTION: "FETCH_BEGIN_END_LIVE_QUIZ_QUESTION",
      FETCH_USER_CHOICE_COUNT_LIVE_QUIZ: "FETCH_USER_CHOICE_COUNT_LIVE_QUIZ",
      SET_LIVE_QUIZ_SCREEN_VIEW: "SET_LIVE_QUIZ_SCREEN_VIEW",
      GET_LIVE_QUIZ_USER_LEADERBOARD: "GET_LIVE_QUIZ_USER_LEADERBOARD",
      POST_LIVE_QUIZ_ASSESSMENT_SCORE: "POST_LIVE_QUIZ_ASSESSMENT_SCORE",
      SET_LIVE_QUIZ_NORMAL_EXIT: "SET_LIVE_QUIZ_NORMAL_EXIT",
    },
  },
  creditTypes: {
    GET_CREDIT_DETAILS: "GET_CREDIT_DETAILS",
    SET_CREDIT_STATE_TYPE: "SET_CREDIT_STATE_TYPE",
    SET_CREDIT_FILTER_TYPE: "SET_CREDIT_FILTER_TYPE",
  },
  notificationTypes: {
    GET_BELL_NOTIFICATIONS: "GET_BELL_NOTIFICATIONS",
    GET_ALL_BELL_NOTIFICATIONS: "GET_ALL_BELL_NOTIFICATIONS",
    GET_NOTIFICATION_SETTINGS: "GET_NOTIFICATION_SETTINGS",
    POST_NOTIFICATION_SETTINGS: "POST_NOTIFICATION_SETTINGS",
  },
  contactUsTypes: {
    POST_CONTACT_US: "POST_CONTACT_US",
    GET_CONTACT_US: "GET_CONTACT_US",
  },
  reports: {
    REPORT_GET_CONSOLIDATEDREPORT: "REPORT_GET_CONSOLIDATEDREPORT",
    REPORT_GET_SKILLEDCLASSES: "REPORT_GET_SKILLEDCLASSES",
    REPORT_GET_MYSKILLS: "REPORT_GET_MYSKILLS",
    REPORT_GET_MYCERTIFICATES: "REPORT_GET_MYCERTIFICATES",
    REPORT_GET_USERBADGES: "REPORT_GET_USERBADGES",
  },
};
export default actionTypes;
