import actionTypes from "../actionTypes";

export const preLoginAction = (data) => ({
  type: actionTypes.loginTypes.PRE_LOGIN,
  payload: data,
});
export const getTenantDetailsAction = (data) => ({
  type: actionTypes.loginTypes.GET_TENANT_DETAILS,
  payload: data,
});
export const loginAction = (data) => ({
  type: actionTypes.loginTypes.LOGIN,
  payload: data,
});
export const changePasswordAction = () => ({
  type: actionTypes.loginTypes.CHANGE_PASSWORD,
});
export const logOutAction = () => ({
  type: actionTypes.loginTypes.LOGOUT,
});

export const getLastLoginTimeAction = (data) => ({
  type: actionTypes.loginTypes.LAST_LOGIN_TIME,
  payload: data,
});
